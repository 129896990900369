<template>
  <div class="page-class page-setting-list">
    <div class="page-title text-center">
      <h2>{{ title }}</h2>
    </div>
    <table-box :columns="columns" :rows="summaryBudget" :baseUrl="baseUrl" :loading="loading"> </table-box>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import _ from 'lodash';
import TableBox from '@/components/TableBox.vue';

export default {
  name: 'BudgetSummary',
  components: {
    TableBox
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: this.metaDescription
        }
      ]
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('pettyCash', ['baseUrl', 'loading', 'budgetList', 'summaryBudget']),
    metaDescription() {
      return 'Table Box';
    }
  },
  data() {
    return {
      title: 'Budget Summary',
      columns: [
        {
          type: 'string',
          headerText: '#',
          class: { 'text-center': true },
          textKey: 'rowNum',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          class: { 'text-center': true },
          textKey: '_id',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Monthly Budget Limit',
          class: { 'text-center': true },
          textKey: 'totalBudget',
          width: '15%'
        },
        {
          type: 'string',
          headerText: ' Balance Budget',
          class: { 'text-center': true },
          textKey: 'totalBudgetBalance',
          width: '15%'
        },
        {
          type: 'string',
          headerText: 'Cash in Hand',
          class: { 'text-center': true },
          textKey: 'totalPattyCashBalance',
          width: '18%'
        },

        {
          type: 'string',
          headerText: 'Budget consumed',
          class: { 'text-center': true },
          textKey: 'totalUsedBudget',
          width: '18%'
        },
        {
          type: 'string',
          headerText: 'Petty Cash consumed',
          class: { 'text-center': true },
          textKey: 'totalUsedPattyCash',
          width: '19%'
        }
      ]
    };
  },
  mounted() {
    this.getSummaryForBudget({});
    if (_.isEmpty(this.$route.query)) {
      this.listBudget({
        query: {
          ...this.$route.query,
          page_size: 1
        }
      });
    } else {
      this.listBudget({
        query: this.$route.query,
        page_size: 1
      });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.listBudget({
      query: to.query
    });
    getSummaryForBudget;
    next();
  },

  methods: {
    ...mapActions('pettyCash', ['listBudget', 'getSummaryForBudget']),

    async viewBudget() {
      this.getSummaryForBudget({});
    },
    columnStyle(column) {
      return {
        width: column.width
      };
    }
  }
};
</script>
