<script src="//unpkg.com/v-viewer/dist/v-viewer.js"></script>
<template>
  <div class="page-class">
    <!-- Search filter and Month filter -->
    <b-row v-if="showToolbar" class="table-top-wrapper mt-2 mb-2 mx-0">
      <b-col sm class="px-0">
        <b-form inline @submit.prevent>
          <b-input-group data-v-step="0" class="box-shadow2 ml-2 mb-2">
            <b-form-input
              :class="{
                'bg-light text-dark': !isDarkMode,
                'bg-dark text-light placeholder-light': isDarkMode
              }"
              v-model="searchTerm"
              @keydown.native="search"
              placeholder="Search..."
            ></b-form-input>
            <template #append v-if="searchItems">
              <b-dropdown
                :menu-class="isDarkMode ? 'bg-dark box-shadow2' : 'box-shadow2'"
                :text="selectedSearchVariable"
                :variant="isDarkMode ? 'primary' : 'success'"
              >
                <b-dropdown-item
                  :link-class="isDarkMode ? 'text-light bg-dark' : ''"
                  v-for="item in searchItems"
                  :key="item"
                  @click="selectedSearchVariable = item"
                  >{{ item }}</b-dropdown-item
                >
              </b-dropdown>
            </template>
          </b-input-group>
          <b-form-select
            v-if="!isSmAsmRsm && !ifAccountant"
            v-model="companySelected"
            :options="companyOptions"
            :class="{
              'box-shadow2 ml-2 mb-2 bg-light text-dark': !isDarkMode,
              'box-shadow2 ml-2 mb-2 bg-dark text-light': isDarkMode
            }"
          ></b-form-select>
          <b-form-select
            v-model="filterSelected"
            :options="filterOptions"
            :class="{
              'box-shadow2 ml-2 mb-2 bg-light text-dark': !isDarkMode,
              'box-shadow2 ml-2 mb-2 bg-dark text-light': isDarkMode
            }"
          ></b-form-select>
        </b-form>
      </b-col>
      <b-col sm class="px-0">
        <b-form inline class="float-right">
          <b-input-group class="box-shadow2 ml-2 mb-2">
            <b-input-group-append>
              <b-form-datepicker
                button-only
                id="start-datepicker"
                placeholder="Start Date"
                :min="minDate"
                :max="maxDate"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                v-model="startDateValue"
              ></b-form-datepicker>
            </b-input-group-append>
            <b-form-input
              id="example-input"
              v-model="startDateValue"
              type="text"
              placeholder="Start Date"
              autocomplete="off"
              :class="{
                '': !isDarkMode,
                'bg-dark text-light placeholder-light': isDarkMode
              }"
              disabled
            ></b-form-input>
          </b-input-group>
          <b-input-group class="box-shadow2 ml-2 mb-2">
            <b-input-group-append>
              <b-form-datepicker
                button-only
                id="end-datepicker"
                placeholder="End Date"
                :min="minDate"
                :max="maxDate"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                v-model="endDateValue"
              ></b-form-datepicker>
            </b-input-group-append>
            <b-form-input
              id="example-input"
              v-model="endDateValue"
              type="text"
              placeholder="End Date"
              autocomplete="off"
              :class="{
                '': !isDarkMode,
                'bg-dark text-light placeholder-light': isDarkMode
              }"
              disabled
            ></b-form-input>
          </b-input-group>
          <b-button
            :class="{
              'box-shadow2 btn btn-success ml-2 mb-2': !isDarkMode,
              'btn btn-primary text-light ml-2 mb-2': isDarkMode
            }"
            @click="applyDate"
            >Apply</b-button
          >
          <b-button
            :variant="isDarkMode ? 'outline-light' : 'outline-secondary'"
            class="box-shadow2 ml-2 mb-2"
            @click="clearDate"
            >Clear</b-button
          >
        </b-form>
      </b-col>
    </b-row>
    <!-- Button Block -->
    <b-row class="table-bottom-wrapper">
      <b-col sm="12" lg="4">
        <b-button
          v-if="showAdd && ifAccountant"
          size="sm"
          :class="{
            'box-shadow2 btn btn-success ml-2 mb-2 ': !isDarkMode,
            'btn btn-primary text-light ml-2 mb-2 ': isDarkMode
          }"
          @click="clickAdd"
        >
          <font-awesome-icon :icon="['fas', 'plus']" class="mr-1" />{{ addText }}
        </b-button>
        <b-button
          v-if="showDownload"
          size="sm"
          :class="{
            'box-shadow2 btn btn-success ml-2 mb-2 ': !isDarkMode,
            'btn btn-primary text-light ml-2 mb-2 ': isDarkMode
          }"
          @click="clickDownload"
        >
          <font-awesome-icon :icon="['fas', 'download']" class="mr-1" />{{ downloadText }}
        </b-button>

        <b-button
          v-if="ifAdmin"
          size="sm"
          :class="{
            'box-shadow2 btn btn-success ml-2 mb-2': !isDarkMode,
            'btn btn-primary text-light ml-2 mb-2': isDarkMode
          }"
          @click="clickDownloadSAP"
        >
          <font-awesome-icon :icon="['fas', 'download']" class="mr-1" />Download SAP Report
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <!-- Budget and PettyCash View for Accountant -->
        <div
          v-if="ifAccountant"
          :class="{
            'custom-shadow mx-auto p-3 my-5 rounded': !isDarkMode,
            ' mx-auto p-3 my-5 bg-dark rounded text-light': isDarkMode
          }"
        >
          <b-row class="d-flex my-1 justify-content-between align-items-center">
            <b-col class="">
              <h5>Monthly Budget limit</h5>
            </b-col>
          </b-row>

          <b-row class="flex-column custom-gap my-3 flex-lg-row">
            <b-col class="border-right">
              <div v-if="editMonthlyLimit === false" class="text-center">
                <span><font-awesome-icon :icon="['fas', 'rupee-sign']" class="mr-1" style="font-size: 32px" /></span>
                <span class="display-3">{{ this.formatNumber(monthlyLimit) }}</span>
              </div>
              <div v-else>
                <div class="input-group w-75 mx-auto">
                  <div class="">
                    <span class="input-group-text">&#8377;</span>
                    <!-- ₹ symbol -->
                  </div>
                  <b-form-input
                    type="number"
                    @keydown.native="search"
                    class="form-control box-shadow2"
                    v-model="monthlyLimit"
                    placeholder="Amount"
                  />
                </div>
              </div>
            </b-col>
            <b-col class="border-right text-lg-center">
              <div>
                <span style="font-size: 25px; font-weight: 200">Budget Consumed</span>
              </div>
              <div>
                <span class="" style="font-size: 30px; font-weight: normal"
                  ><font-awesome-icon :icon="['fas', 'rupee-sign']" class="mr-1" style="font-size: 20px" />{{
                    this.formatNumber(balanceUsed)
                  }}</span
                >
              </div>
            </b-col>
            <b-col class="text-lg-center">
              <div>
                <span style="font-size: 25px; font-weight: 200">Balance Budget</span>
              </div>
              <div>
                <span class="" style="font-size: 30px; font-weight: normal"
                  ><font-awesome-icon :icon="['fas', 'rupee-sign']" class="mr-1" style="font-size: 20px" />{{
                    this.formatNumber(remainingBalance)
                  }}</span
                >
              </div>
            </b-col>
          </b-row>
          <b-row class="m-5">
            <b-col class="text-lg-center border-right">
              <div>
                <span style="font-size: 25px; font-weight: 200">Cash In Hand</span>
              </div>
              <div>
                <span class="" style="font-size: 30px; font-weight: normal"
                  ><font-awesome-icon :icon="['fas', 'rupee-sign']" class="mr-1" style="font-size: 20px" />{{
                    this.formatNumber(this.totalPattyCashBalance)
                  }}</span
                >
              </div>
            </b-col>
            <b-col class="text-lg-center">
              <div>
                <span style="font-size: 25px; font-weight: 200">Petty Cash Consumed</span>
              </div>
              <div>
                <span class="" style="font-size: 30px; font-weight: normal"
                  ><font-awesome-icon :icon="['fas', 'rupee-sign']" class="mr-1" style="font-size: 20px" />{{
                    this.formatNumber(this.totalUsedPattyCash)
                  }}</span
                >
              </div>
            </b-col>
          </b-row>
        </div>

        <!-- List Part -->
        <b-row
          :class="{
            '': !isDarkMode,
            ' bg-dark rounded text-light': isDarkMode
          }"
        >
          <b-col>
            <b-row>
              <b-col>
                <h4 class="page-title text-center"
                  >Expense
                  {{ ifAdmin ? 'Request' : '' }}
                  List</h4
                >
              </b-col>
            </b-row>

            <b-row class="d-flex justify-content-between my-2 align-items-center">
              <b-col v-if="ifAdmin" class="d-flex justify-content-end">
                <input :checked="allSelected" @click="selectAll" class="checkbox_width" type="checkbox" id="scales" />
                <label for="scales" class="px-2">Select all </label>
                <b-button
                  :class="{
                    'box-shadow2 btn btn-success mx-2': !isDarkMode,
                    'btn btn-primary text-light mx-2': isDarkMode
                  }"
                  v-if="!isMultiSelect"
                  @click="handleMultiEdit()"
                >
                  Edit
                </b-button>
                <b-button
                  class="mx-2 box-shadow2"
                  :variant="isDarkMode ? 'outline-danger' : 'danger'"
                  v-if="isMultiSelect"
                  @click="handleMultiCancel()"
                >
                  Cancel
                </b-button>
                <b-button
                  :class="{
                    'box-shadow2 btn btn-success mx-2': !isDarkMode,
                    'btn btn-primary text-light mx-2': isDarkMode
                  }"
                  :disabled="loading"
                  v-if="isMultiSelect"
                  @click="handleMultiSave()"
                >
                  Save
                </b-button>
                <b-button
                  :class="{
                    'box-shadow2 btn btn-success mx-2': !isDarkMode,
                    'btn btn-primary text-light mx-2': isDarkMode
                  }"
                  :disabled="loading || isMultiSelect"
                  @click="clickMakeAllApprove()"
                >
                  Approve
                </b-button>
              </b-col>
            </b-row>
            <div
              :class="{
                'box-shadow2 my-3': !isDarkMode,
                ' my-3': isDarkMode
              }"
            >
              <div
                :class="{
                  'table-row table-row-header text-center ': !isDarkMode,
                  'table-row-dark text-center border-top dark-div': isDarkMode
                }"
              >
                <div
                  v-for="column in columnsForExpense"
                  :key="column.id"
                  :class="{ 'table-column bg-light': !isDarkMode, 'table-column-dark': isDarkMode }"
                  :style="columnStyle(column)"
                >
                  {{ column.headerText }}
                </div>
              </div>
              <b-overlay v-if="loading" show :variant="isDarkMode ? 'outline-light' : 'outline-secondary'">
                <div
                  :class="{ 'table-row table-row-empty': !isDarkMode, 'table-row-dark table-row-empty': isDarkMode }"
                >
                  <div
                    :class="{
                      'table-column table-column-full text-center': !isDarkMode,
                      'table-column-dark table-column-full text-center': isDarkMode
                    }"
                  >
                  </div>
                </div>
              </b-overlay>
              <div
                :class="{
                  'table-row text-center ': !isDarkMode,
                  'table-row-dark text-center': isDarkMode
                }"
                v-for="row in rowsForExpense"
                :key="row.id"
              >
                <div
                  v-for="column in columnsForExpense"
                  :key="column.id"
                  :class="[
                    {
                      ...column.class,
                      'table-column ': !isDarkMode,
                      'table-column-dark': isDarkMode,
                      'table_isApproved ': row.status === 'APPROVED',
                      'table_isRejected ': row.status === 'REJECTED',
                      'table_isAutoCancelled ': row.status === 'CANCELLED',
                      'table_isPending ': ifAdmin && row.status === 'PENDING',
                      table_isApproved_dark: isDarkMode && row.status === 'APPROVED',
                      table_isRejected_dark: isDarkMode && row.status === 'REJECTED',
                      table_isAutoCancelled_dark: isDarkMode && row.status === 'CANCELLED',
                      table_isPending_dark: isDarkMode && row.status === 'PENDING'
                    }
                  ]"
                  :style="columnStyle(column)"
                >
                  <slot v-if="column.slotKey" :name="column.slotKey" v-bind:row="row"></slot>
                  <div v-if="column.htmlKey" v-html="row[column.htmlKey]"></div>
                  <div v-if="column.urlKey"
                    ><a :href="row[column.urlKey]" target="_blank">{{ row[column.urlKey] }}</a>
                  </div>
                  <span
                    v-if="
                      column.textKey &&
                      column.textKey != 'amount' &&
                      column.textKey !== 'text' &&
                      column.textKey !== 'glName' &&
                      column.textKey !== 'expenseGlCode' &&
                      column.textKey !== 'costCenter' &&
                      column.textKey != 'bulkReciptImage'
                    "
                    class="span-text"
                    >{{ row[column.textKey] }}</span
                  >
                  <span v-if="column.textKey === 'amount'" class="span-text">&#x20B9; {{ row[column.textKey] }}</span>
                  <span v-if="column.textKey === 'glName' && !isEditableArray.includes(row)" class="span-text">
                    {{ row[column.textKey] }}</span
                  >
                  <span v-if="column.textKey === 'expenseGlCode' && !isEditableArray.includes(row)" class="span-text">
                    {{ row[column.textKey] }}</span
                  >
                  <span v-if="column.textKey === 'costCenter' && !isEditableArray.includes(row)" class="span-text">
                    {{ row[column.textKey] }}</span
                  >
                  <span v-if="column.textKey === 'select'">
                    <input
                      class="box-shadow2"
                      type="checkbox"
                      @click="select(row)"
                      :checked="selectedExpenses.includes(row)"
                      :disabled="isExpired(row)"
                    />
                    <!--  -->
                  </span>
                  <template v-if="column.textKey === 'glName' && isEditableArray.includes(row)">
                    <multiselect
                      :class="{ 'box-shadow2': !isDarkMode, darkMode: isDarkMode }"
                      :key="row.id"
                      id="input-expense"
                      :options="getCategoryOptions(row.companyName)"
                      :allow-empty="false"
                      @input="handleGlName(row)"
                      v-model="row.glName"
                    >
                    </multiselect>
                  </template>
                  <template v-if="column.textKey === 'expenseGlCode' && isEditableArray.includes(row)">
                    <multiselect
                      :class="{ 'box-shadow2': !isDarkMode, darkMode: isDarkMode }"
                      :key="row.id"
                      id="input-glName"
                      :options="getExpenseOptions(row.companyName)"
                      :allow-empty="false"
                      @input="handleExpenseGlCode(row)"
                      v-model="row.expenseGlCode"
                    >
                    </multiselect>
                  </template>
                  <template v-if="column.textKey === 'text' && isEditableArray.includes(row)">
                    <b-form-textarea
                      :key="row.id"
                      rows="7"
                      type="text"
                      style="z-index: 10; background: white"
                      v-model="row.text"
                      :class="{
                        'box-shadow2 bg-light text-dark': !isDarkMode,
                        'bg-dark text-light placeholder-light': isDarkMode
                      }"
                    />
                  </template>
                  <template v-if="column.textKey === 'costCenter' && isEditableArray.includes(row)">
                    <b-form-input
                      :class="{
                        'box-shadow2 bg-light text-dark': !isDarkMode,
                        'bg-dark text-light placeholder-light': isDarkMode
                      }"
                      type="text"
                      :key="row.id"
                      v-model="row.costCenter"
                    />
                  </template>
                  <span
                    v-if="column.textKey === 'text' && !isEditableArray.includes(row)"
                    class="span-text box-shadow2"
                    style="line-height: 20px"
                  >
                    <p v-b-tooltip.hover :title="row[column.textKey]">
                      {{ handleText(row[column.textKey]) }}
                    </p>
                  </span>

                  <div
                    v-viewer
                    v-if="column.type === 'image' && typeof row[column.textKey] !== 'string' && row[column.textKey]"
                  >
                    <div>
                      <template>
                        <div v-for="image in row[column.textKey]" :key="image" class="imageView">
                          <img
                            :src="image"
                            class="dynamic-image box-shadow2"
                            thumbnail
                            fluid
                            alt="No Image Available"
                          />
                        </div>
                      </template>
                    </div>
                  </div>
                  <link href="//unpkg.com/viewerjs/dist/viewer.css" rel="stylesheet" />

                  <div v-if="column.type === 'functions'" class="text-center">
                    <b-button-group class="box-shadow2" vertical>
                      <b-button
                        data-v-step="5"
                        v-if="column.functions.edit && isEditableArray.includes(row)"
                        size="sm"
                        :variant="isDarkMode ? 'outline-light' : 'outline-secondary'"
                        @click="handleCancel(row)"
                      >
                        <font-awesome-icon :icon="['fas', 'edit']" class="mr-1" />Cancel
                      </b-button>
                      <b-button
                        data-v-step="5"
                        v-if="column.functions.edit && isEditableArray.includes(row) && selectedExpenses.length <= 1"
                        size="sm"
                        :disabled="loading"
                        :variant="isDarkMode ? 'outline-light' : 'outline-secondary'"
                        @click="handleSave(row)"
                      >
                        <font-awesome-icon :icon="['fas', 'edit']" class="mr-1" />Save
                      </b-button>
                      <b-button
                        v-if="
                          column.functions.edit &&
                          ifAdmin &&
                          row.status !== 'REJECTED' &&
                          row.status !== 'APPROVED' &&
                          row.status !== 'CANCELLED' &&
                          !isEditableArray.includes(row)
                        "
                        size="sm"
                        :disabled="loading || checkDisabled(row)"
                        :variant="isDarkMode ? 'outline-light' : 'outline-secondary'"
                        @click="clickEditExpense(row)"
                      >
                        <font-awesome-icon :icon="['fas', 'edit']" class="mr-1" /> Edit
                      </b-button>
                      <b-button
                        @click="handleOpen(row)"
                        :variant="isDarkMode ? 'outline-light' : 'outline-secondary'"
                        :disabled="loading"
                        v-if="
                          !isEditableArray.includes(row) &&
                          ifAdmin &&
                          row.status !== 'REJECTED' &&
                          row.status !== 'APPROVED' &&
                          row.status !== 'CANCELLED'
                        "
                      >
                        Open
                      </b-button>
                      <b-button
                        v-if="
                          column.functions.approve &&
                          (ifAdmin || isSmAsmRsm) &&
                          row.status !== 'REJECTED' &&
                          row.status !== 'CANCELLED' &&
                          !isEditableArray.includes(row)
                        "
                        size="sm"
                        :disabled="loading || checkDisabled(row)"
                        variant="outline-success"
                        :class="{
                          '': !isDarkMode,
                          'text-white': isDarkMode
                        }"
                        @click="clickApproveExpense(row)"
                      >
                        <font-awesome-icon :icon="['fas', 'check']" class="mr-1" />
                        {{ handleApproveBtn(row) }}
                      </b-button>
                      <b-button
                        v-if="
                          column.functions.approve &&
                          ((ifAdmin && row.status === 'PENDING') || (isSmAsmRsm && row.status !== 'PENDING')) &&
                          row.status !== 'APPROVED' &&
                          row.status !== 'CANCELLED' &&
                          !isEditableArray.includes(row)
                        "
                        size="sm"
                        :disabled="loading || checkDisabled(row)"
                        variant="outline-danger"
                        :class="{
                          '': !isDarkMode,
                          'text-white': isDarkMode
                        }"
                        @click="clickDenyExpense(row)"
                      >
                        <font-awesome-icon :icon="['fas', 'trash']" class="mr-1" />
                        {{ row.status === 'REJECTED' ? 'REJECTED' : 'Deny' }}
                      </b-button>
                      <div>
                        <b-button
                          data-v-step="6"
                          v-if="loading || !isEditableArray.includes(row)"
                          size="sm"
                          variant="outline-info"
                          :class="{
                            '': !isDarkMode,
                            'text-white': isDarkMode
                          }"
                          @click="clickCheckStatus(row)"
                        >
                          <font-awesome-icon :icon="['fas', 'check']" class="mr-1" />
                          Check Status
                        </b-button>
                      </div>
                    </b-button-group>
                  </div>
                </div>
              </div>
              <div v-if="!loading && rowsForExpense.length === 0" class="table-row table-row-empty">
                <div class="table-column table-column-full text-center">{{ emptyText }}</div>
              </div>
            </div>
            <div class="my-4 d-flex align-items-center">
              <div class="d-flex align-items-center">
                <div
                  :class="{
                    'colorBox table_isApproved box-shadow2': !isDarkMode,
                    'colorBox table_isApproved_dark': isDarkMode
                  }"
                >
                </div>
                <div class="text-center font-weight-bold mx-1">Approved Requests</div>
              </div>
              <div v-if="ifAdmin" class="mx-3 d-flex align-items-center">
                <div
                  :class="{
                    'colorBox table_isPending box-shadow2': !isDarkMode,
                    'colorBox table_isPending_dark ': isDarkMode
                  }"
                >
                </div>
                <div class="text-center font-weight-bold mx-1">Pending Requests</div>
              </div>
              <div class="d-flex align-items-center">
                <div
                  :class="{
                    'colorBox table_isRejected box-shadow2': !isDarkMode,
                    'colorBox table_isRejected_dark': isDarkMode
                  }"
                >
                </div>
                <div class="text-center font-weight-bold mx-1">Rejected Requests</div>
              </div>
            </div>
          </b-col>
        </b-row>

        <!-- Pagination -->
        <b-row class="mt-2">
          <b-col class="px-2 text-right" v-if="showPagination">
            <b-pagination-nav
              data-v-step="7"
              v-if="rowsForExpense.length"
              v-model="currentPage"
              :link-gen="linkGen"
              :number-of-pages="totalNumberOfPage"
              use-router
              no-page-detect
              align="right"
              :class="{ '': !isDarkMode, 'dark-nav': isDarkMode }"
            ></b-pagination-nav>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div class="page-center">
      <div class="page_custom_full"> </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import VueViewer from 'v-viewer';
import moment from 'moment';
import Vue from 'vue';
import Multiselect from 'vue-multiselect';
import { Months, formatNumber } from '../../helper/pettyCashHelper';

Vue.use(VueViewer);
export default {
  name: 'PettyCashExpenseList',
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: this.metaDescription
        }
      ]
    };
  },
  props: {
    columnsForExpense: Array,
    rowsForExpense: Array,
    pagination: Object,
    rowsForTransaction: Array,
    showAdd: Boolean,
    showDownload: Boolean,
    downloadText: String,
    addText: String,
    loading: Boolean,
    emptyText: String,
    showPagination: Boolean,
    showToolbar: Boolean,
    searchItems: Array,
    filterOptions: Array,
    companyOptions: Array
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('darkMode', ['isDarkMode']),
    ...mapState('pettyCash', ['summary', 'budgetType']),
    ...mapGetters('pettyCash', ['ifAdmin', 'ifAccountant', 'isSmAsmRsm']),
    ...mapState('cstSubmission', ['store']),
    metaDescription() {
      return 'Petty cash box';
    },
    totalNumberOfPage() {
      if (this.pagination) {
        if (this.pagination.total_rows && this.pagination.page_size) {
          return Math.ceil(this.pagination.total_rows / this.pagination.page_size);
        }
      }
      return 0;
    },
    currentPage() {
      if (this.pagination?.page) {
        return this.pagination?.page;
      }
      return 1;
    }
  },
  watch: {
    selectedExpenses(newValue) {
      if (newValue.length <= 0) {
        this.isMultiSelect = false;
        this.allSelected = false;
      }
    },
    budgetType(newValue) {
      if (newValue !== undefined) {
        newValue.forEach(budget => {
          this.categoryOptions.push({ glName: budget.glName, company: budget.company });
          this.expenseOptions.push({ expenseGlCode: budget.expenseGlCode, company: budget.company });
        });
      }
    },
    selectedCategory(newValue) {
      if (newValue !== undefined || newValue !== '') {
        this.expenseGlCode = this.budgetType.filter(item => item.glName === newValue)[0]?.expenseGlCode;
      }
    },
    expenseGlCode(newValue) {
      if (newValue !== undefined || newValue !== '') {
        this.selectedCategory = this.budgetType.filter(item => item.expenseGlCode === newValue)[0]?.glName;
      }
    },
    filterSelected(newValue, oldValue) {
      if (newValue !== oldValue && this.$route.query.filter !== newValue) {
        if (!newValue) {
          const query = { ...this.$route.query };
          delete query.filter;
          this.$router.push({ query });
        } else {
          this.$router.push({ query: { ...this.$route.query, filter: newValue } });
        }
      }
    },
    companySelected(newValue, oldValue) {
      if (newValue !== oldValue && this.$route.query.company !== newValue) {
        if (!newValue) {
          const query = { ...this.$route.query };
          delete query.company;
          this.$router.push({ query });
        } else {
          this.$router.push({ query: { ...this.$route.query, company: newValue } });
        }
      }
    },
    searchTerm(newValue) {
      if (newValue === '' && Object.prototype.hasOwnProperty.call(this.$route.query, 'search')) {
        const query = { ...this.$route.query };
        delete query.search;
        this.$router.push({ query });
      }
    },
    summary(newValue) {
      if (newValue !== undefined) {
        this.monthlyLimit = newValue.totalBudget;
        this.balanceUsed = newValue.totalUsedBudget;
        this.remainingBalance = newValue.totalBudgetBalance;
        this.totalPattyCashBalance = newValue.totalPattyCashBalance;
        this.totalUsedPattyCash = newValue.totalUsedPattyCash;
      }
    }
  },
  data() {
    return {
      isMultiSelect: false,
      totalUsedPattyCash: 0,
      totalPattyCashBalance: 0,
      expenseOptions: [],
      categoryOptions: [],
      selectedCategory: null,
      updateText: '',
      expenseGlCode: null,
      costCenter: '',
      filterSelected: null,
      companySelected: null,
      startDateValue: '',
      endDateValue: '',
      searchTerm: null,
      minDate: moment('2020-01-01').format('YYYY-MM-DD'),
      maxDate: moment().format('YYYY-MM-DD'),
      selectedSearchVariable: this.searchItems && this.searchItems[0],
      modalVisible: false,
      editMonthlyLimit: false,
      monthlyLimit: 0,
      balanceUsed: 0,
      remainingBalance: 0,
      companyOptions: [
        { value: null, text: ' Select Role' },
        { value: 'SRL', text: 'SSM' },
        { value: 'ASM', text: 'ASM' },
        { value: 'RSM', text: 'RSM' }
      ],
      selectedYear: new Date().getFullYear(), // Initial selection: current year
      years: [],
      months: Months,
      selectedMonth: moment().format('MMMM').toUpperCase(),
      isEditable: null,
      isEditableArray: [],
      selectedExpenses: [],
      allSelected: false
    };
  },
  components: {
    Multiselect
  },
  mounted() {
    this.getStoreByUser();
    this.listBudgetType();
    const currentYear = new Date().getFullYear();
    for (let year = currentYear - 2; year <= currentYear + 2; year++) {
      this.years.push(year);
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'filter')) {
      this.filterSelected = this.$route.query.filter;
    } else {
      this.filterSelected = null;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'company')) {
      this.companySelected = this.$route.query.company;
    } else {
      this.companySelected = null;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'search')) {
      this.searchTerm = this.$route.query.search;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'searchkey')) {
      this.selectedSearchVariable = this.$route.query.searchkey;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
      this.startDateValue = this.$route.query.startdate;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'enddate')) {
      this.endDateValue = this.$route.query.enddate;
    }
  },
  methods: {
    ...mapActions('cstSubmission', ['getStoreByUser']),
    ...mapActions('pettyCash', [
      'bulkBudgetUpload',
      'listBudgetType',
      'updateGlNameFromStore',
      'updateExpenseGlCodeFromStore',
      'updateCostCenterFormStore'
    ]),
    formatNumber,
    getCategoryOptions(companyName) {
      return companyName === 'NBL'
        ? this.categoryOptions.filter(option => option.company === 'NBL').map(item => item.glName)
        : this.categoryOptions
            .filter(option => option.company === 'SRL' || option.company === 'CESC')
            .map(item => item.glName);
    },
    getExpenseOptions(companyName) {
      return companyName === 'NBL'
        ? this.expenseOptions.filter(option => option.company === 'NBL').map(item => item.expenseGlCode)
        : this.expenseOptions
            .filter(option => option.company === 'SRL' || option.company === 'CESC')
            .map(item => item.expenseGlCode);
    },
    isEndOfMonth() {
      const today = moment(new Date()).format('YYYY-MM-DD');
      const lastDayOfMonth = moment().endOf('month').format('YYYY-MM-DD');
      var now = moment().month();
      let date26thOfMonth = moment().date(26).format('YYYY-MM-DD');

      if (
        moment(today).isBetween(date26thOfMonth, lastDayOfMonth) ||
        moment(today).isSame(date26thOfMonth) ||
        moment(today).isSame(lastDayOfMonth)
      ) {
        return true;
      }
      return false;
    },
    clickDownloadSAP() {
      this.$emit('downloadSAP');
    },
    handleGlName(row) {
      if (row.id) {
        const expenseGlCode = this.budgetType.filter(item => item.glName === row.glName)[0]?.expenseGlCode;
        this.updateExpenseGlCodeFromStore({ row, expenseGlCode });
      }
    },
    handleExpenseGlCode(row) {
      if (row.id) {
        const glName = this.budgetType.filter(item => item.expenseGlCode === row.expenseGlCode)[0]?.glName;
        this.updateGlNameFromStore({ row, glName });
      }
    },
    handleMultiCancel() {
      this.isEditableArray = [];
      this.selectedExpenses = [];
      this.isMultiSelect = false;
    },
    handleCancel(row) {
      this.isEditableArray = this.isEditableArray.filter(item => item.id !== row.id);
      this.selectedExpenses = this.selectedExpenses.filter(item => item.id !== row.id);
    },
    handleMultiEdit() {
      if (this.selectedExpenses.length > 0) {
        this.isEditableArray = this.selectedExpenses;
        this.isMultiSelect = true;
      }
    },
    clickEditExpense(row) {
      if (row.id) {
        this.isEditableArray.push(row);
        this.selectedExpenses.push(row);

        this.isMultiSelect = true;
      } else {
        this.isEditable = null;
      }
      //
    },
    clickMakeAllApprove() {
      if (this.selectedExpenses.length > 0) {
        const ids = this.selectedExpenses.map(item => item.id);
        this.$emit('approveAll', { ids: ids });
      }
      this.selectedExpenses = [];
      this.allSelected = false;
    },
    handleMultiSave() {
      if (this.selectedExpenses.length > 0) {
        const expenseList = this.selectedExpenses.map(item => {
          return {
            id: item.id,
            glName: item.glName,
            expenseGlCode: item.expenseGlCode,
            costCenter: item.costCenter,
            text: item.text
          };
        });
        this.$emit('editSomeRequests', { expenseList: this.selectedExpenses });
      }
      this.selectedExpenses = [];
      this.allSelected = false;
    },
    select(row) {
      this.allSelected = false;
      if (this.selectedExpenses.includes(row)) {
        this.selectedExpenses = this.selectedExpenses.filter(i => i !== row);
      } else {
        this.selectedExpenses = [...this.selectedExpenses, row];
      }
    },
    selectAll() {
      if (this.allSelected) {
        this.selectedExpenses = [];
        this.allSelected = false;
      } else {
        this.selectedExpenses = this.rowsForExpense.filter(row => row.status === 'PENDING').map(i => i); // row.status === 'PENDING'
        this.allSelected = true;
      }
    },
    isExpired(row) {
      if (row.status !== 'PENDING') {
        // row.status !== 'PENDING'
        return true;
      }
      return false;
    },
    handleSave(row) {
      const expenseRequest = {
        id: row.id,
        amount: row.amount,
        bulkReciptImage: row.bulkReciptImage,
        storeId: row.storeId,
        glName: row.glName,
        recipient: row.recipient,
        text: row.text,
        pattyCashStoreGl: row.pattyCashStoreGl,
        postingDate: row.postingDate,
        billDate: moment(row.billDate, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        referenceNumber: row.referenceNumber,
        createdBy: row.createdBy,
        isApproved: row.isApproved,
        isApprovedByHO: row.isApprovedByHO,
        status: row.status,
        companyCode: row.companyCode,
        companyName: row.companyName,
        approvedBy: row.approvedBy,
        costCenter: row.costCenter,
        isEdited: true,
        expenseGlCode: row.expenseGlCode,
        glName: row.glName,
        text: row.text
      };
      this.$emit('patchExpense', { expense: expenseRequest });
      this.isMultiSelect = false;
    },
    handleText(text) {
      if (text !== '' && text.length > 40) {
        return text.slice(0, 40) + '...';
      }
      return text;
    },
    // updateSelectedMonth() {
    //   const inputDate = moment(`${this.selectedMonth} ${this.selectedYear} ${moment().format('YYYY')}`, 'MMMM YYYY');
    //   this.startDateValue = inputDate.startOf('month').format('YYYY-MM-DD');
    //   this.endDateValue = inputDate.endOf('month').format('YYYY-MM-DD');
    // },
    clickCheckStatus(row) {
      Vue.swal({
        title: 'Expense Request Status',
        html: this.handleModalHtml(row),
        showCancelButton: false,
        confirmButtonText: 'Ok',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark',
          confirmButton: 'btn btn-primary',
          popup: 'col-md-3 animate__animated animate__fadeInDown',
          input: this.isDarkMode ? 'text-light' : 'text-dark'
        }
      });
    },
    handleModalHtml(row) {
      const requestAmount = Number(row.amount);
      let approvedRole = '';
      if (this.store[0]?.company === 'SRL') {
        if (requestAmount !== null) {
          if (requestAmount > 0 && requestAmount <= 2000) {
            approvedRole = 'Store Sales Manager';
          } else if (requestAmount >= 2001 && requestAmount <= 3000) {
            approvedRole = 'ASM';
          } else {
            approvedRole = 'RSM';
          }
        }
      } else if (this.store[0]?.company === 'NBL') {
        if (requestAmount !== null) {
          if (requestAmount > 0 && requestAmount <= 2000) {
            approvedRole = 'Store Sales Manager';
          } else if (requestAmount >= 2001 && requestAmount <= 5000) {
            approvedRole = 'ASM';
          } else {
            approvedRole = 'RSM';
          }
        }
      }

      if (row.status === 'REJECTED' && row.isApproved === false) {
        return `<div><h4>Rejected by : ${row.approvedBy?.name}</h4><div><span><b>Reason : </b></span>${row.statusRemarks}</div></div>`;
      }
      if (row.status === 'REJECTED' && row.isApprovedByHO === false) {
        return `<div><h4>Rejected by : Administration</h4><div><span><b>Reason : </b></span>${row.statusRemarks}</div></div>`;
      }
      if (row.status === 'CANCELLED') {
        return `<div><h4>Request has been auto canceled </h4></div>`;
      }
      return `<div><div style="margin:10px;"> ${
        !_.isEmpty(row.approvedBy) && row.approvedBy?.name !== ''
          ? `Approved by ${approvedRole} :`
          : `Waiting for ${approvedRole} approval`
      } <span><b>${!_.isEmpty(row.approvedBy) && row.approvedBy?.name !== '' ? row.approvedBy?.name : ''}</b></span>
       <div style="margin:10px;">${
         row.status === 'APPROVED' ? 'Approved by Admin :' : 'Waiting for Admin approval'
       } <span><b>${row.approvedByHO !== '' ? row.approvedByHO : ''}</b></span> </div> </div>
      </div>`;
    },

    handleApproveBtn(row) {
      if (this.ifAdmin && row.isApproved === true && row.isApprovedByHO === false) {
        return 'Approve';
      } else if (this.ifAdmin && row.isApproved === true && row.isApprovedByHO === false) {
        return 'Approved';
      } else if (this.isSmAsmRsm && row.isApproved === false) {
        return 'Approve';
      } else if (this.isSmAsmRsm && row.isApproved === true) {
        return 'Approved';
      }
      if (row.isApprovedByHO === true) {
        return 'Approved';
      }
    },
    handleOpen(row) {
      this.$emit('editExpense', { row });
    },
    clickApproveExpense(row) {
      this.$emit('approveExpense', { row });
    },
    async clickDenyExpense(row) {
      await Vue.swal({
        title: 'Action Required',
        text: 'Please provide a Reason for Rejection',
        input: 'textarea',
        inputAttributes: {
          rows: 6,
          cols: 10
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Reject Request',
        denyButtonColor: '#3a4',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        }
      }).then(result => {
        if (result.isDenied) {
          return null;
        }
        if (result.value) {
          if (result.value === '') {
            return null;
          }
          const rejectionReason = result.value;
          this.$emit('denyExpense', { row: { ...row, statusRejection: rejectionReason } });
        }
      });
    },

    addExpenseClick() {
      this.$emit('expense', {});
    },
    checkDisabled(row) {
      if (this.ifAdmin && row.isApprovedByHO === true) {
        return true;
      } else if (this.isSmAsmRsm && row.isApproved === true) {
        return true;
      } else if (row.status === 'APPROVED' || row.status === 'REJECTED') {
        return true;
      }
      return false;
    },
    linkGen(pageNum) {
      return { path: this.baseUrl, query: { ...this.$route.query, page: pageNum } };
    },
    search(event) {
      if (event.key === 'Enter') {
        if (this.searchTerm) {
          if (this.$route.query.searchTerm !== this.searchTerm) {
            if (this.selectedSearchVariable) {
              this.$router.push({
                query: { ...this.$route.query, search: this.searchTerm, searchkey: this.selectedSearchVariable }
              });
            } else {
              this.$router.push({ query: { ...this.$route.query, search: this.searchTerm } });
            }
          }
        } else {
          const query = { ...this.$route.query };
          delete query.filter;
          this.$router.push({ query });
        }
      }
    },
    applyDate() {
      if (this.ifAdmin) {
        if (this.startDateValue) {
          this.$router.push({
            query: {
              ...this.$route.query,
              startdate: moment(this.startDateValue).format('YYYY-MM-DD')
            }
          });
        }
        if (this.endDateValue) {
          this.$router.push({
            query: {
              ...this.$route.query,
              enddate: moment(this.endDateValue).format('YYYY-MM-DD')
            }
          });
        }
      } else {
        if (this.selectedMonth) {
          this.$router.push({
            query: {
              ...this.$route.query,
              startdate: moment(this.startDateValue).format('YYYY-MM-DD'),
              enddate: moment(this.endDateValue).format('YYYY-MM-DD')
            }
          });
        }
      }
    },
    clearDate() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        const query = { ...this.$route.query };
        delete query.startdate;
        delete query.enddate;
        this.$router.push({ query });
      }
      this.startDateValue = null;
      this.endDateValue = null;
    },
    onClick() {
      this.editMonthlyLimit = !this.editMonthlyLimit;
    },
    onCancel() {
      this.editMonthlyLimit = false;
    },
    columnStyle(column) {
      return { width: column.width };
    },
    clickAdd() {
      if (this.isEndOfMonth()) {
        Vue.swal({
          title: 'Request Denied',
          text: 'Expense Booking Window is Closed till end of the month. You can now book expense on 1st of next month',
          icon: 'error',
          background: this.isDarkMode ? '#464d54' : '#ffffff',
          customClass: {
            title: this.isDarkMode ? 'text-light' : 'text-dark',
            content: this.isDarkMode ? 'text-light' : 'text-dark'
          }
        });
        return null;
      } else {
        this.$emit('add');
      }
    },
    clickDownload() {
      this.$emit('download');
    }
  }
};
</script>

<style src="../../assets/css/Table.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.colorBox {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid rgb(122, 122, 122);
}

/* .table_isPending {
  background: #f8f7b6 !important;
} */
.table_isRejected {
  background: #f9bcbc !important;
}

.table_isApproved {
  background: #c6f9bc !important;
}

.table_isAutoCancelled {
  background: #f9bcbc !important;
}

.table_isPending_dark {
  background: #706239 !important;
}

.table_isRejected_dark {
  background: #7a403e !important;
}

.table_isApproved_dark {
  background: #44693d !important;
}

.table_isAutoCancelled_dark {
  background: #854d54 !important;
}
</style>
