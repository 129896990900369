<template>
  <div class="page-class page-center">
    <div class="page-staff-form">
      <h1 class="page-title text-center">{{ title }}</h1>
      <petty-cash-budget-form-box
        @postBudget="onPostBudget"
        @patchBudget="onPatchBudget"
        :formType="formType"
      ></petty-cash-budget-form-box>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import router from '@/router';
import PettyCashBudgetFormBox from '@/components/PettyCash/PettyCashBudgetFormBox.vue';

export default {
  name: 'PettyCashBudgetForm',
  components: {
    PettyCashBudgetFormBox
  },
  async mounted() {
    this.listBudgetType();

    if (this.$route.name === 'petty-cash-budget-new') {
      this.formType = 'new';
      this.id = null;
    } else {
      this.formType = 'update';
      this.id = this.$route.params.id;

      await this.getOne({
        id: this.id,
        router
      });
    }
  },
  data() {
    return {
      id: null,
      formType: ''
    };
  },
  computed: {
    title() {
      return this.formType === 'new' ? 'Add New Budget' : 'Update Budget';
    }
  },
  watch: {},
  methods: {
    ...mapActions('pettyCash', ['postBudget', 'getOne', 'patchOne', 'listBudgetType']),

    onPostBudget({ budget }) {
      this.postBudget({ budget, router, redirectUrl: '/petty-cash/budget' });
    },
    onPatchBudget({ budget }) {
      const id = this.id;
      this.patchOne({ id, budget, router, redirectUrl: '/petty-cash' });
    }
  }
};
</script>
