<template>
  <div class="page-class page-setting-list">
    <div class="page-title text-center">
      <h2>{{ title }}</h2>
    </div>
    <petty-cash-budget-increase-box
      :columns="columns"
      :rows="increaseBudgetRequestList"
      :showToolbar="true"
      :pagination="pagination"
      :showPagination="true"
      :searchItems="searchItems"
      emptyText="No Data Available"
      :filterOptions="filterOptions"
      @approved="onApproved"
      @denied="onDeny"
      @increase="onIncreaseBudget"
    ></petty-cash-budget-increase-box>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import router from '@/router';
import { BudgetIncreaseView } from '../../../helper/pettyCashHelper';
import PettyCashBudgetIncreaseBox from '../../../components/PettyCash/PettyCashBudgetIncreaseBox.vue';

export default {
  components: { PettyCashBudgetIncreaseBox },
  name: 'BudgetIncrease',
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: this.metaDescription
        }
      ]
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('pettyCash', ['loading', 'baseUrl', 'pagination', 'budgetList', 'increaseBudgetRequestList']),
    ...mapGetters('pettyCash', ['ifRSM', 'ifAdmin', 'ifAccountant']),
    metaDescription() {
      return 'Budget Increase Box';
    }
  },
  watch: {
    budgetList(newValue) {
      if (newValue.length > 0) {
        this.pattyCashStoreGl = newValue[0].pattyCashStoreGl;
        this.storeId = newValue[0].storeId;
      }
    }
  },
  data() {
    return {
      title: 'Budget Increase - Request',
      searchItems: ['Store Id'],
      isRasedRequestModalOpen: true,
      budgetIncreaseAmount: '',
      pattyCashStoreGl: '',
      storeId: '',
      filterOptions: [
        { value: null, text: 'Select Filter' },
        { value: 'Pending', text: 'PENDING' },
        { value: 'Approved', text: 'APPROVED' },
        { value: 'Rejected', text: 'REJECTED' }
      ],
      columns: BudgetIncreaseView
    };
  },
  mounted() {
    this.listBudget({ query: {} });
    if (_.isEmpty(this.$route.query)) {
      this.listBudgetIncreaseRequest({
        query: {
          ...this.$route.query
        }
      });
    } else {
      this.listBudgetIncreaseRequest({ query: this.$route.query });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.listBudgetIncreaseRequest({ query: to.query });
    next();
  },
  methods: {
    ...mapActions('pettyCash', [
      'listBudgetIncreaseRequest',
      'listBudget',
      'patchBudgetIncrease',
      'postBudgetIncreaseRequest'
    ]),
    ...mapActions('pettyCash', ['bulkBudgetUpload']),
    onIncreaseBudget({ increasedAmount, reasonForIncrement, processDoneBy }) {
      this.isRasedRequestModalOpen = false;
      const request = {
        pattyCashStoreGl: this.pattyCashStoreGl,
        storeId: this.storeId,
        amount: Number(increasedAmount),
        reasonText: reasonForIncrement,
        status: 'PENDING',
        processDoneBy: processDoneBy
      };

      this.postBudgetIncreaseRequest({ request, router, redirectUrl: '/petty-cash' });
    },
    onApproved({ row }) {
      let approvalSystem = {};
      if (this.ifAdmin) {
        approvalSystem = {
          isApproved: true,
          status: 'APPROVED',
          isApprovedByRSM: row.isApprovedByRSM
        };
      }
      if (this.ifRSM) {
        approvalSystem = {
          isApproved: false,
          isApprovedByRSM: true,
          status: 'PENDING'
        };
      }
      const request = {
        amount: row.amount,
        pattyCashStoreGl: row.pattyCashStoreGl,
        storeId: row.storeId,
        reasonText: row.reasonText,
        processDoneBy: 'Web'
      };
      const id = row.id;
      const tempRequest = { ...request, ...approvalSystem };
      this.patchBudgetIncrease({ id, request: tempRequest, router, redirectUrl: '/petty-cash' });
    },
    onDeny(row) {
      let denySystem = {};
      if (this.ifAdmin) {
        denySystem = {
          isApproved: false,
          status: 'REJECTED',
          isApprovedByRSM: row.isApprovedByRSM,
          reasonText: row.reasonText
        };
      }
      if (this.ifRSM) {
        denySystem = {
          isApprovedByRSM: false,
          status: 'REJECTED',
          reasonText: row.reasonText
        };
      }

      const request = {
        amount: row.amount,
        pattyCashStoreGl: row.pattyCashStoreGl,
        storeId: row.storeId,
        statusRemarks: row.statusRemarks,
        processDoneBy: 'Web'
      };
      const tempRequest = { ...request, ...denySystem };

      const id = row.id;
      this.patchBudgetIncrease({ id, request: tempRequest, router, redirectUrl: '/petty-cash' });
    },
    columnStyle(column) {
      return { width: column.width };
    }
  }
};
</script>
