import moment from 'moment';
export const AdminBudgetView = [
  {
    type: 'row_num',
    headerText: 'Sr. No',
    class: { ' text-center': true },
    textKey: 'index',
    width: '4%'
  },
  {
    type: 'row_num',
    headerText: 'Select',
    class: { 'text-center': true },
    textKey: 'select',
    width: '5%'
  },
  {
    type: 'row_num',
    headerText: 'Company',
    class: { ' text-center': true },
    textKey: 'companyName',
    width: '6%'
  },
  {
    type: 'string',
    headerText: 'Store Type',
    class: { ' text-center': true },
    textKey: 'storeType',
    width: '7%'
  },
  {
    type: 'string',
    headerText: 'Store Id',
    class: { 'text-center': true },
    textKey: 'storeId',
    width: '8%'
  },
  {
    type: 'string',
    headerText: 'Petty Cash Store Gl',
    class: { 'text-center': true },
    textKey: 'pattyCashStoreGl',
    width: '8%'
  },
  {
    type: 'string',
    headerText: 'Cost Center',
    class: { 'text-center': true },
    textKey: 'costCenter',
    width: '10%'
  },
  {
    type: 'string',
    headerText: 'Amount',
    class: { 'text-center': true },
    textKey: 'budgetAmount',
    width: '9%'
  },
  {
    type: 'string',
    headerText: 'Month',
    class: { 'text-center': true },
    textKey: 'month',
    width: '9%'
  },
  {
    type: 'string',
    headerText: 'Created At',
    class: { 'text-center': true },
    textKey: 'createdAt',
    width: '9%'
  },
  {
    type: 'string',
    headerText: 'Created By',
    class: { 'text-center': true },
    textKey: 'createdBy',
    width: '8%'
  },
  {
    type: 'string',
    headerText: 'Is Live',
    class: { 'text-center': true },
    textKey: 'isMakeLive',
    width: '7%'
  },
  {
    type: 'functions',
    headerText: 'Functions',
    class: { 'text-center': true },
    width: '10%',
    functions: {
      edit: true,
      mis: true
    }
  }
];

export const NonAdminBudgetView = [
  {
    type: 'row_num',
    headerText: 'Sr. No',
    class: { ' text-center': true },
    textKey: 'index',
    width: '8%'
  },
  {
    type: 'string',
    headerText: 'Company',
    class: { 'text-center': true },
    textKey: 'companyName',
    width: '10%'
  },
  {
    type: 'string',
    headerText: 'Store Type',
    class: { ' text-center': true },
    textKey: 'storeType',
    width: '13%'
  },
  {
    type: 'string',
    headerText: 'Petty Cash Store Gl',
    class: { 'text-center': true },
    textKey: 'pattyCashStoreGl',
    width: '12%'
  },
  {
    type: 'string',
    headerText: 'Store Id',
    class: { 'text-center': true },
    textKey: 'storeId',
    width: '12%'
  },
  {
    type: 'string',
    headerText: 'Month',
    class: { 'text-center': true },
    textKey: 'month',
    width: '20%'
  },
  {
    type: 'string',
    headerText: 'Amount',
    class: { 'text-center': true },
    textKey: 'budgetAmount',
    width: '25%'
  }
];

export const Months = [
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER'
];

export const BudgetIncreaseView = [
  {
    type: 'row_num',
    headerText: 'Sr. No',
    class: { ' text-center': true },
    textKey: 'index',
    width: '7%'
  },

  {
    type: 'string',
    headerText: 'Store Id',
    class: { 'text-center': true },
    textKey: 'storeId',
    width: '9%'
  },
  {
    type: 'string',
    headerText: 'Amount',
    class: { 'text-center': true },
    textKey: 'amount',
    width: '8%'
  },
  {
    type: 'string',
    headerText: 'Requested By',
    class: { 'text-center': true },
    textKey: 'createdBy',
    width: '14%'
  },
  {
    type: 'string',
    headerText: 'Created At',
    class: { 'text-center': true },
    textKey: 'createdAt',
    width: '15%'
  },
  {
    type: 'string',
    headerText: 'Reason',
    class: { 'text-center': true },
    textKey: 'reasonText',
    width: '19%'
  },
  {
    type: 'string',
    headerText: 'Status',
    class: { 'text-center': true },
    textKey: 'status',
    width: '10%'
  },
  {
    type: 'functions',
    headerText: 'Functions',
    class: { 'text-center': true },
    width: '18%',
    functions: {
      edit: true,
      mis: true
    }
  }
];

export const NonAdminCashAccountView = [
  {
    type: 'row_num',
    headerText: 'Sr. No',
    class: { ' text-center': true },
    textKey: 'index',
    width: '8%'
  },
  {
    type: 'row_num',
    headerText: 'Company',
    class: { ' text-center': true },
    textKey: 'companyName',
    width: '12%'
  },
  {
    type: 'row_num',
    headerText: 'Store Id',
    class: { 'text-center': true },
    textKey: 'storeId',
    width: '13%'
  },
  {
    type: 'row_num',
    headerText: 'Petty Cash Store GL',
    class: { 'text-center': true },
    textKey: 'pattyCashStoreGl',
    width: '17%'
  },
  {
    type: 'row_num',
    headerText: `${moment().format('MMMM')} Opening Balance`,
    class: { 'text-center': true },
    textKey: 'monthlyOpeningBalance',
    width: '18%'
  },
  {
    type: 'row_num',
    headerText: 'Current Balance',
    class: { 'text-center': true },
    textKey: 'currentBalance',
    width: '17%'
  },
  {
    type: 'row_num',
    headerText: 'Last update',
    class: { 'text-center': true },
    textKey: 'updatedAt',
    width: '15%'
  }
];

export const AdminCashAccountView = [
  {
    type: 'row_num',
    headerText: 'Sr. No',
    class: { ' text-center': true },
    textKey: 'index',
    width: '7%'
  },
  {
    type: 'row_num',
    headerText: 'Company',
    class: { ' text-center': true },
    textKey: 'companyName',
    width: '10%'
  },
  {
    type: 'row_num',
    headerText: 'Store Id',
    class: { 'text-center': true },
    textKey: 'storeId',
    width: '13%'
  },
  {
    type: 'row_num',
    headerText: 'Petty Cash Store GL',
    class: { 'text-center': true },
    textKey: 'pattyCashStoreGl',
    width: '13%'
  },
  {
    type: 'row_num',
    headerText: `${moment().format('MMMM')} Opening Balance`,
    class: { 'text-center': true },
    textKey: 'monthlyOpeningBalance',
    width: '17%'
  },
  {
    type: 'row_num',
    headerText: 'Current Balance',
    class: { 'text-center': true },
    textKey: 'currentBalance',
    width: '10%'
  },
  {
    type: 'row_num',
    headerText: 'Last update',
    class: { 'text-center': true },
    textKey: 'updatedAt',
    width: '15%'
  },

  {
    type: 'functions',
    headerText: 'Functions',
    class: { 'text-center': true },
    width: '15%',
    functions: {
      edit: true,
      mis: true
    }
  }
];

export const NonAdminTransactionView = [
  {
    type: 'row_num',
    headerText: 'Sr. No',
    class: { ' text-center': true },
    textKey: 'index',
    width: '5%'
  },
  {
    type: 'row_num',
    headerText: 'Store Id',
    class: { 'text-center': true },
    textKey: 'storeId',
    width: '10%'
  },
  {
    type: 'row_num',
    headerText: 'Type',
    class: { 'text-center': true },
    textKey: 'transactionType',
    width: '10%'
  },
  {
    type: 'row_num',
    headerText: 'Petty Cash Store GL',
    class: { 'text-center': true },
    textKey: 'pattyCashStoreGl',
    width: '15%'
  },
  {
    type: 'row_num',
    headerText: 'Old Balance',
    class: { 'text-center': true },
    textKey: 'oldBalance',
    width: '13%'
  },
  {
    type: 'row_num',
    headerText: 'Amount',
    class: { 'text-center': true },
    textKey: 'amount',
    width: '13%'
  },
  {
    type: 'row_num',
    headerText: 'Balance left',
    class: { 'text-center': true },
    textKey: 'balanceLeft',
    width: '14%'
  },
  {
    type: 'row_num',
    headerText: 'Last update',
    class: { 'text-center': true },
    textKey: 'updatedAt',
    width: '20%'
  }
];

export const formatNumber = number => {
  if (isNaN(number) || number === null || number === undefined) {
    return '';
  }
  const numberString = number.toString();

  const parts = numberString.split('.');
  let wholeNumber = parts[0];
  const decimalPart = parts.length > 1 ? '.' + parts[1] : '';

  let formattedNumber = '';
  let groupLength = 3;
  for (let i = wholeNumber.length - 1; i >= 0; i--) {
    formattedNumber = wholeNumber[i] + formattedNumber;
    groupLength--;
    if (groupLength === 0 && i !== 0) {
      formattedNumber = ',' + formattedNumber;
      groupLength = 2;
    }
  }
  return formattedNumber + decimalPart;
};

export const NonAdminExpenseView = [
  {
    type: 'row_num',
    headerText: 'Sr. No',
    class: { ' text-center': true },
    textKey: 'index',
    width: '3%'
  },
  {
    type: 'string',
    headerText: 'Store Id',
    class: { 'text-center': true },
    textKey: 'storeId',
    width: '5%'
  },

  {
    type: 'row_num',
    headerText: 'GL Name',
    class: { ' text-center': true },
    textKey: 'glName',
    width: '13%'
  },
  {
    type: 'string',
    headerText: 'Expense Gl',
    class: { 'text-center': true },
    textKey: 'expenseGlCode',
    width: '13%'
  },
  {
    type: 'string',
    headerText: 'Cost Center',
    class: { 'text-center': true },
    textKey: 'costCenter',
    width: '9%'
  },
  {
    type: 'row_num',
    headerText: 'Bill Date',
    class: { ' text-center': true },
    textKey: 'billDate',
    width: '9%'
  },
  {
    type: 'row_num',
    headerText: 'Document Date',
    class: { ' text-center': true },
    textKey: 'createdAt',
    width: '11%'
  },
  {
    type: 'string',
    headerText: 'Amount',
    class: { 'text-center': true },
    textKey: 'amount',
    width: '7%'
  },

  {
    type: 'row_num',
    headerText: 'Text',
    class: { ' text-center': true },
    textKey: 'text',
    width: '11%'
  },
  {
    type: 'image',
    headerText: 'Bill Photo',
    class: { ' text-center': true },
    textKey: 'bulkReciptImage',
    width: '8%'
  },
  {
    type: 'functions',
    headerText: 'Functions',
    class: { 'text-center': true },
    width: '11%',
    functions: {
      edit: true,
      approve: true
    }
  }
];
export const AdminExpenseView = [
  {
    type: 'row_num',
    headerText: 'Sr. No',
    class: { ' text-center': true },
    textKey: 'index',
    width: '3%'
  },
  {
    type: 'row_num',
    headerText: 'Select',
    class: { 'text-center': true },
    textKey: 'select',
    width: '5%'
  },
  {
    type: 'string',
    headerText: 'Store Id',
    class: { 'text-center': true },
    textKey: 'storeId',
    width: '5%'
  },

  {
    type: 'row_num',
    headerText: 'GL Name',
    class: { ' text-center': true },
    textKey: 'glName',
    width: '13%'
  },
  {
    type: 'string',
    headerText: 'Expense Gl',
    class: { 'text-center': true },
    textKey: 'expenseGlCode',
    width: '13%'
  },
  {
    type: 'string',
    headerText: 'Cost Center',
    class: { 'text-center': true },
    textKey: 'costCenter',
    width: '9%'
  },
  {
    type: 'row_num',
    headerText: 'Bill Date',
    class: { ' text-center': true },
    textKey: 'billDate',
    width: '7%'
  },
  {
    type: 'row_num',
    headerText: 'Document Date',
    class: { ' text-center': true },
    textKey: 'createdAt',
    width: '10%'
  },
  {
    type: 'string',
    headerText: 'Amount',
    class: { 'text-center': true },
    textKey: 'amount',
    width: '7%'
  },

  {
    type: 'row_num',
    headerText: 'Text',
    class: { ' text-center': true },
    textKey: 'text',
    width: '11%'
  },
  {
    type: 'image',
    headerText: 'Bill Photo',
    class: { ' text-center': true },
    textKey: 'bulkReciptImage',
    width: '8%'
  },
  {
    type: 'functions',
    headerText: 'Functions',
    class: { 'text-center': true },
    width: '9%',
    functions: {
      edit: true,
      approve: true
    }
  }
];

export const TransactionTableView = [
  {
    type: 'row_num',
    headerText: 'Sr. No',
    class: { ' text-center': true },
    textKey: 'index',
    width: '5%'
  },
  {
    type: 'row_num',
    headerText: 'Store Id',
    class: { 'text-center': true },
    textKey: 'storeId',
    width: '10%'
  },
  {
    type: 'row_num',
    headerText: 'Type',
    class: { 'text-center': true },
    textKey: 'transactionType',
    width: '10%'
  },
  {
    type: 'row_num',
    headerText: 'Petty Cash Store GL',
    class: { 'text-center': true },
    textKey: 'pattyCashStoreGl',
    width: '15%'
  },
  {
    type: 'row_num',
    headerText: 'Old Balance',
    class: { 'text-center': true },
    textKey: 'oldBalance',
    width: '13%'
  },
  {
    type: 'row_num',
    headerText: 'Amount',
    class: { 'text-center': true },
    textKey: 'amount',
    width: '13%'
  },
  {
    type: 'row_num',
    headerText: 'Current Balance',
    class: { 'text-center': true },
    textKey: 'balanceLeft',
    width: '14%'
  },
  {
    type: 'row_num',
    headerText: 'Last update',
    class: { 'text-center': true },
    textKey: 'updatedAt',
    width: '20%'
  }
];
