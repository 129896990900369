<template>
  <div class="component-tablebox">
    <!-- toolbar -->

    <b-row v-if="showToolbar" class="table-top-wrapper mb-2 mx-0">
      <b-col sm class="px-0">
        <b-form inline @submit.prevent>
          <b-input-group data-v-step="0" class="box-shadow2 ml-2 mb-2">
            <b-form-input
              v-model="searchTerm"
              @keydown.native="search"
              :class="{
                'bg-light text-dark': !isDarkMode,
                'bg-dark text-light placeholder-light': isDarkMode
              }"
              placeholder="Search..."
            ></b-form-input>
            <template #append v-if="searchItems">
              <b-dropdown
                :menu-class="isDarkMode ? 'bg-dark box-shadow2' : 'box-shadow2'"
                :variant="isDarkMode ? 'primary' : 'success'"
                :text="selectedSearchVariable"
              >
                <b-dropdown-item
                  :link-class="isDarkMode ? 'text-light bg-dark' : ''"
                  v-for="item in searchItems"
                  :key="item"
                  @click="selectedSearchVariable = item"
                  >{{ item }}</b-dropdown-item
                >
              </b-dropdown>
            </template>
          </b-input-group>
          <b-form-select
            data-v-step="1"
            v-model="filterSelected"
            :options="filterOptions"
            :class="{
              'box-shadow2 ml-2 mb-2 bg-light text-dark': !isDarkMode,
              'box-shadow2 ml-2 mb-2 bg-dark text-light': isDarkMode
            }"
          ></b-form-select>

          <!-- <b-form-select
            data-v-step="1"
            v-model="filtereventSelected"
            :options="filtereventOptions"
            class="box-shadow2 ml-2 w-35 mb-2"
          ></b-form-select> -->
        </b-form>
      </b-col>
      <b-col sm class="px-0">
        <b-form data-v-step="2" inline class="float-right">
          <b-input-group class="box-shadow2 ml-2 mb-2">
            <b-input-group-append>
              <b-form-datepicker
                button-only
                id="start-datepicker"
                placeholder="Start Date"
                :min="minDate"
                :max="maxDate"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                v-model="startDateValue"
              ></b-form-datepicker>
            </b-input-group-append>
            <b-form-input
              id="example-input"
              v-model="startDateValue"
              type="text"
              placeholder="Start Date"
              autocomplete="off"
              :class="{
                '': !isDarkMode,
                'bg-dark text-light placeholder-light': isDarkMode
              }"
              disabled
            ></b-form-input>
          </b-input-group>
          <b-input-group class="box-shadow2 ml-2 mb-2">
            <b-input-group-append>
              <b-form-datepicker
                button-only
                id="end-datepicker"
                placeholder="End Date"
                :min="minDate"
                :max="maxDate"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                v-model="endDateValue"
              ></b-form-datepicker>
            </b-input-group-append>
            <b-form-input
              id="example-input"
              v-model="endDateValue"
              type="text"
              placeholder="End Date"
              autocomplete="off"
              :class="{
                '': !isDarkMode,
                'bg-dark text-light placeholder-light': isDarkMode
              }"
              disabled
            ></b-form-input>
          </b-input-group>
          <b-button
            :class="{
              'box-shadow2 btn btn-success ml-2 mb-2': !isDarkMode,
              'btn btn-primary text-light ml-2 mb-2': isDarkMode
            }"
            @click="applyDate"
            >Apply</b-button
          >
          <b-button
            :variant="isDarkMode ? 'outline-light' : 'outline-secondary'"
            class="box-shadow2 ml-2 mb-2"
            @click="clearDate"
            >Clear</b-button
          >
        </b-form>
      </b-col>
    </b-row>

    <!-- table button -->
    <b-row class="table-bottom-wrapper mb-2 mx-0">
      <b-col :cols="12" class="px-0">
        <b-button
          data-v-step="3"
          v-if="showAdd"
          size="sm"
          :class="{
            'box-shadow2 btn btn-success ml-2 mb-2 mr-2': !isDarkMode,
            'btn btn-primary text-light ml-2 mb-2 mr-2': isDarkMode
          }"
          @click="clickAdd"
        >
          <font-awesome-icon :icon="['fas', 'plus']" class="mr-1" />{{ addText }}
        </b-button>
        <b-button
          data-v-step="3"
          v-if="showAddReview"
          size="sm"
          :class="{
            'box-shadow2 btn btn-success mb-2 mr-2': !isDarkMode,
            'btn btn-primary text-light mb-2 mr-2': isDarkMode
          }"
          @click="clickAddReview"
        >
          <font-awesome-icon :icon="['fas', 'plus']" class="mr-1" />{{ addTextReview }}
        </b-button>
        <b-button
          data-v-step="4"
          v-if="showDownload"
          size="sm"
          :class="{
            'box-shadow2 btn btn-success ml-2 mb-2 mr-2': !isDarkMode,
            'btn btn-primary text-light ml-2 mb-2 mr-2': isDarkMode
          }"
          @click="clickDownload"
        >
          <font-awesome-icon :icon="['fas', 'download']" class="mr-1" />{{ downloadText }}
        </b-button>
        <b-button
          data-v-step="4"
          v-if="showActiveEvents"
          size="sm"
          :class="{
            'box-shadow2 btn btn-success mb-2 mr-2': !isDarkMode,
            'btn btn-primary text-light mb-2 mr-2': isDarkMode
          }"
          @click="showActiveEvent"
        >
          <font-awesome-icon :icon="['fas', 'download']" class="box-shadow2 mr-1" />{{ activeEventsText }}
        </b-button>
      </b-col>
    </b-row>
    <div class="table-top-wrapper">
      <div v-if="totalCount" class="row-total-count">
        <span>Total Count:</span>
        {{ totalCount }}
      </div>
    </div>

    <div
      :class="{
        'box-shadow2': !isDarkMode,
        '': isDarkMode
      }"
    >
      <div
        :class="{
          'table-row table-row-header': !isDarkMode,
          'table-row-dark dark-div border-top': isDarkMode
        }"
      >
        <div
          v-for="(column, index) in columns"
          :key="column.id"
          :class="{
            ...column.class,
            'table-column': !isDarkMode,
            'table-column-dark': isDarkMode,
            'table-column-first': index === 0,
            'table-column-last-odd': columns.length === index + 1 && (columns.length - 1) % 2 === 1
          }"
          :style="columnStyle(column)"
        >
          {{ column.headerText }}
        </div>
      </div>
      <div
        :class="{
          'table-row ': !isDarkMode,
          'table-row-dark': isDarkMode
        }"
        v-for="row in rows"
        :key="row.id"
      >
        <div
          v-for="(column, index) in columns"
          :key="column.id"
          class="table-column"
          :class="{
            ...column.class,
            'table-column ': !isDarkMode,
            'table-column-dark': isDarkMode,
            'table-column-first': index === 0,
            'table-column-last-odd': columns.length === index + 1 && (columns.length - 1) % 2 === 1
          }"
          :style="columnStyle(column)"
        >
          <slot v-if="column.slotKey" :name="column.slotKey" v-bind:row="row"></slot>
          <div v-if="column.htmlKey" v-html="row[column.htmlKey]"></div>
          <div v-if="column.urlKey"
            ><a :href="row[column.urlKey]" target="_blank">{{ row[column.urlKey] }}</a></div
          >
          <span
            v-if="column.textKey && column.textKey != 'activeTillTime' && column.textKey != 'storeids'"
            class="span-text"
            >{{ row[column.textKey] }}</span
          >
          <span v-if="column.textKey === 'activeTillTime'" class="span-text">{{ showTime(row[column.textKey]) }}</span>
          <span v-if="column.textKey === 'storeids'" class="span-text">{{ showString(row[column.textKey]) }}</span>
          <div v-if="column.type === 'functions'" class="text-center">
            <b-button-group class="box-shadow2" vertical>
              <b-button
                data-v-step="5"
                v-if="column.functions.edit && ifAdmin()"
                size="sm"
                :variant="isDarkMode ? 'outline-light' : 'outline-secondary'"
                @click="clickEdit(row)"
              >
                <font-awesome-icon :icon="['fas', 'edit']" class="mr-1" />View/Edit
              </b-button>
              <b-button
                data-v-step="6"
                v-if="column.functions.mis"
                size="sm"
                :class="{
                  '': !isDarkMode,
                  'text-white': isDarkMode
                }"
                variant="outline-primary"
                @click="clickDownloadMis(row)"
              >
                <font-awesome-icon :icon="['fas', 'chart-pie']" class="mr-1" />Download MIS
              </b-button>
              <b-button
                data-v-step="6"
                v-if="column.functions.misQuestion"
                size="sm"
                :class="{
                  '': !isDarkMode,
                  'text-white': isDarkMode
                }"
                variant="outline-warning"
                @click="clickDownloadMisQuestion(row)"
              >
                <font-awesome-icon :icon="['fas', 'chart-pie']" class="mr-1" />Download MIS Question
              </b-button>
              <b-button
                data-v-step="6"
                v-if="column.functions.checklistData"
                size="sm"
                :class="{
                  '': !isDarkMode,
                  'text-white': isDarkMode
                }"
                variant="outline-info"
                @click="clickDownloadChecklistData(row)"
              >
                <font-awesome-icon :icon="['fas', 'chart-pie']" class="mr-1" />Download Checkpoints
              </b-button>
              <b-button v-if="column.functions.delete" size="sm" variant="outline-warning" @click="clickDelete(row)">
                <font-awesome-icon :icon="['fas', 'trash-alt']" class="mr-1" />Delete
              </b-button>
              <b-button
                v-if="column.functions.fill"
                :disabled="isDisabled(row)"
                size="sm"
                :variant="isDarkMode ? 'outline-light' : 'success'"
                @click="fillChecklist(row)"
              >
                <font-awesome-icon :icon="['fas', 'edit']" class="mr-1" />Fill Checklist
              </b-button>
            </b-button-group>
          </div>
        </div>
      </div>
      <div
        v-if="!loading && rows.length === 0"
        :class="{ 'table-row table-row-empty': !isDarkMode, 'table-row-dark table-row-empty': isDarkMode }"
      >
        <div
          :class="{
            'table-column table-column-full text-center': !isDarkMode,
            'table-column-dark table-column-full text-center': isDarkMode
          }"
          >{{ emptyText }}</div
        >
      </div>
      <b-overlay v-if="loading" show :variant="isDarkMode ? 'outline-light' : 'outline-secondary'">
        <div :class="{ 'table-row table-row-empty': !isDarkMode, 'table-row-dark table-row-empty': isDarkMode }">
          <div
            :class="{
              'table-column table-column-full text-center': !isDarkMode,
              'table-column-dark table-column-full text-center': isDarkMode
            }"
          >
          </div>
        </div>
      </b-overlay>
    </div>

    <b-row class="table-bottom-wrapper mt-2 mx-0">
      <b-col lg="10" sm="12" class="px-0">
        <b-button
          v-if="showAdd"
          size="sm"
          :class="{
            'box-shadow2 btn btn-success ml-2 mb-2 mr-2': !isDarkMode,
            'btn btn-primary text-light ml-2 mb-2 mr-2': isDarkMode
          }"
          @click="clickAdd"
        >
          <font-awesome-icon :icon="['fas', 'plus']" class="mr-1" />{{ addText }}
        </b-button>
        <b-button
          v-if="showAddReview"
          size="sm"
          :class="{
            'box-shadow2 btn btn-success mb-2 mr-2': !isDarkMode,
            'btn btn-primary text-light mb-2 mr-2': isDarkMode
          }"
          @click="clickAddReview"
        >
          <font-awesome-icon :icon="['fas', 'plus']" class="mr-1" />{{ addTextReview }}
        </b-button>
        <b-button
          v-if="showDownload"
          size="sm"
          :class="{
            'box-shadow2 btn btn-success ml-2 mb-2 mr-2': !isDarkMode,
            'btn btn-primary text-light ml-2 mb-2 mr-2': isDarkMode
          }"
          @click="clickDownload"
        >
          <font-awesome-icon :icon="['fas', 'download']" class="mr-1" />{{ downloadText }}
        </b-button>
      </b-col>
      <b-col lg="2" sm="12" v-if="showPagination">
        <b-pagination-nav
          data-v-step="7"
          v-if="rows.length"
          v-model="currentPage"
          :link-gen="linkGen"
          :number-of-pages="totalNumberOfPage"
          use-router
          no-page-detect
          align="right"
          :class="{ '': !isDarkMode, 'dark-nav': isDarkMode }"
        ></b-pagination-nav>
      </b-col>
    </b-row>
    <!-- <v-tour name="myTour" :steps="steps" :callbacks="callbacks"></v-tour> -->
  </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';

export default {
  props: {
    columns: Array,
    rows: Array,
    pagination: Object,
    baseUrl: String,
    emptyText: String,
    showAdd: Boolean,
    showAddReview: Boolean,
    showDownload: Boolean,
    showToolbar: Boolean,
    searchItems: Array,
    filterOptions: Array,
    filtereventOptions: Array,
    steps: Array,
    callbacks: Object,
    downloadText: String,
    addText: String,
    addTextReview: String,
    loading: Boolean,
    showPagination: Boolean,
    showActiveEvents: Boolean,
    activeEventsText: String
  },
  name: 'ChecklistBox',
  data() {
    return {
      filterSelected: null,
      filtereventSelected: null,
      startDateValue: moment().subtract(1, 'month').format('YYYY-MM-DD'),
      endDateValue: moment().format('YYYY-MM-DD'),
      searchTerm: null,
      minDate: moment('2020-01-01').format('YYYY-MM-DD'),
      maxDate: moment().format('YYYY-MM-DD'),
      selectedSearchVariable: this.searchItems && this.searchItems[0]
    };
  },
  mounted() {
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'filter')) {
      this.filterSelected = this.$route.query.filter;
    } else {
      this.filterSelected = null;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'expired')) {
      this.filtereventSelected = this.$route.query.expired;
    } else {
      this.filtereventSelected = null;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'search')) {
      this.searchTerm = this.$route.query.search;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'searchkey')) {
      this.selectedSearchVariable = this.$route.query.searchkey;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
      this.startDateValue = this.$route.query.startdate;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'enddate')) {
      this.endDateValue = this.$route.query.enddate;
    }
  },
  watch: {
    filtereventSelected(newValue, oldValue) {
      if (newValue !== oldValue && this.$route.query.expired !== newValue) {
        const query = { ...this.$route.query, page: 1 };
        if (!newValue) {
          delete query.expired;
          this.$router.push({ query });
        } else {
          this.$router.push({ query: { ...query, expired: newValue } });
        }
      }
    },
    filterSelected(newValue, oldValue) {
      if (newValue !== oldValue && this.$route.query.filter !== newValue) {
        if (!newValue) {
          const query = { ...this.$route.query };
          delete query.filter;
          this.$router.push({ query });
        } else {
          this.$router.push({ query: { ...this.$route.query, filter: newValue } });
        }
      }
    },
    searchTerm(newValue) {
      if (newValue === '' && Object.prototype.hasOwnProperty.call(this.$route.query, 'search')) {
        const query = { ...this.$route.query };
        delete query.search;
        this.$router.push({ query });
      }
    }
  },
  computed: {
    ...mapState('auth', {
      authUser: 'user'
    }),
    ...mapState('darkMode', ['isDarkMode']),
    currentPage() {
      if (this.pagination.page) {
        return this.pagination.page;
      }
      return 1;
    },
    totalNumberOfPage() {
      if (this.pagination) {
        if (this.pagination.total_rows && this.pagination.page_size) {
          return Math.ceil(this.pagination.total_rows / this.pagination.page_size);
        }
      }
      return 0;
    },
    totalCount() {
      if (this.pagination) {
        return this.pagination.total_rows;
      }
      return 0;
    }
  },
  methods: {
    isDisabled(row) {
      const currentTime = moment().format().split('T')[1].split('+')[0];
      const activeTillTime =
        moment(moment(row?.activeTillTime)?.toISOString())?.format()?.split('T')[1]?.split('+')[0] || null;
      if (row.activeTillTime === '') {
        return false;
      }
      if (activeTillTime > currentTime) {
        return false;
      }
      return true;
    },
    ifAdmin() {
      const newUser = this.authUser.roles.map(role => role.name);
      const admin = newUser.filter(role => role.includes('ADMIN'));
      if (admin.length > 0) {
        return true;
      }
      return false;
    },
    showTime(value) {
      if (value != null && value != '') {
        return moment(value).format('hh:mm a');
      }
    },
    showString(str) {
      if (str.length < 49) {
        return str;
      }
      return `${str.slice(0, 49)}...`;
    },
    search(event) {
      if (event.key === 'Enter') {
        if (this.searchTerm) {
          if (this.$route.query.searchTerm !== this.searchTerm) {
            if (this.selectedSearchVariable) {
              this.$router.push({
                query: { ...this.$route.query, search: this.searchTerm, searchkey: this.selectedSearchVariable }
              });
            } else {
              this.$router.push({ query: { ...this.$route.query, search: this.searchTerm } });
            }
          }
        } else {
          const query = { ...this.$route.query };
          delete query.filter;
          this.$router.push({ query });
        }
      }
    },
    applyDate() {
      if (this.startDateValue) {
        this.$router.push({
          query: {
            ...this.$route.query,
            startdate: moment(this.startDateValue).format('YYYY-MM-DD')
          }
        });
      }
      if (this.endDateValue) {
        this.$router.push({
          query: {
            ...this.$route.query,
            enddate: moment(this.endDateValue).format('YYYY-MM-DD')
          }
        });
      }
    },
    clearDate() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        const query = { ...this.$route.query };
        delete query.startdate;
        delete query.enddate;
        this.$router.push({ query });
      }
      this.startDateValue = null;
      this.endDateValue = null;
    },
    columnStyle(column) {
      return { width: column.width };
    },
    linkGen(pageNum) {
      return { path: this.baseUrl, query: { ...this.$route.query, page: pageNum } };
    },
    clickAdd() {
      this.$emit('add', {});
    },
    clickAddReview() {
      this.$emit('addReview', {});
    },
    clickDownload() {
      this.$emit('download', {});
    },
    showActiveEvent() {
      this.$emit('activeEvents', {});
    },
    clickDelete(row) {
      this.$emit('delete', { row });
    },
    fillChecklist(row) {
      this.$emit('fill-checklist', { row });
    },
    clickEdit(row) {
      this.$emit('edit', { row });
    },
    clickDownloadMis(row) {
      this.$emit('download-mis', { row });
    },
    clickDownloadMisQuestion(row) {
      this.$emit('download-mis-question', { row });
    },
    clickDownloadChecklistData(row) {
      this.$emit('download-checklist-data', { row });
    }
  }
};
</script>
