<template>
  <div class="page-class d-flex flex-column">
    <!-- Search and month filter -->
    <b-row v-if="showToolbar" class="table-top-wrapper mb-2 mt-2">
      <b-col sm="12" lg="3">
        <b-input-group data-v-step="0" class="mb-2 ml-2 box-shadow2">
          <b-form-input
            :class="{
              'bg-light text-dark': !isDarkMode,
              'bg-dark text-light placeholder-light': isDarkMode
            }"
            v-model="searchTerm"
            @keydown.native="search"
            placeholder="Search..."
          ></b-form-input>
          <template #append v-if="searchItems">
            <b-dropdown
              :menu-class="isDarkMode ? 'bg-dark box-shadow2' : 'box-shadow2'"
              :text="selectedSearchVariable"
              :variant="isDarkMode ? 'primary' : 'success'"
            >
              <b-dropdown-item
                :link-class="isDarkMode ? 'text-light bg-dark' : ''"
                v-for="item in searchItems"
                :key="item"
                @click="selectedSearchVariable = item"
                >{{ item }}</b-dropdown-item
              >
            </b-dropdown>
          </template>
        </b-input-group>
      </b-col>
      <b-col sm="12" lg="9">
        <b-form data-v-step="2" inline class="float-right">
          <b-row>
            <b-col sm="12" lg="6" md="6">
              <b-form-select
                :class="{
                  'box-shadow2 ml-2 mb-2  bg-light text-dark': !isDarkMode,
                  'box-shadow2 ml-2 mb-2 bg-dark text-light': isDarkMode
                }"
                id="monthPicker"
                :options="months"
                v-model="selectedMonth"
                @change="updateSelectedMonth"
              >
              </b-form-select
            ></b-col>
            <b-col>
              <b-form-select
                :class="{
                  'box-shadow2 ml-2 mb-2  bg-light text-dark': !isDarkMode,
                  'box-shadow2 ml-2 mb-2 bg-dark text-light': isDarkMode
                }"
                id="yearPicker"
                :options="years"
                v-model="selectedYear"
                @change="updateSelectedMonth"
              >
              </b-form-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button
                :class="{
                  'box-shadow2 btn btn-success ml-2 mb-2': !isDarkMode,
                  'btn btn-primary text-light ml-2 mb-2': isDarkMode
                }"
                @click="applyDate"
                >Apply</b-button
              >
              <b-button
                :variant="isDarkMode ? 'outline-light' : 'outline-secondary'"
                class="box-shadow2 ml-2 mb-2"
                @click="clearDate"
                >Clear</b-button
              >
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>

    <!-- Loading  -->
    <div class="page-center">
      <div class="page_custom_full px-20">
        <!-- Button block -->
        <b-row class="table-bottom-wrapper">
          <b-col sm="12" lg="6">
            <b-button
              data-v-step="3"
              v-if="showAdd && ifAdmin"
              size="sm"
              :class="{
                'box-shadow2 btn btn-success mr-2 ml-2 mb-2': !isDarkMode,
                'btn btn-primary text-light mr-2 ml-2 mb-2': isDarkMode
              }"
              @click="clickBudget"
            >
              <font-awesome-icon :icon="['fas', 'plus']" class="mr-1" />{{ budgetText }}
            </b-button>
            <b-button
              data-v-step="4"
              v-if="showDownload && (ifAdmin || ifSpencerAdmin)"
              size="sm"
              :class="{
                'box-shadow2 btn btn-success ml-2 mb-2 mr-2': !isDarkMode,
                'btn btn-primary text-light ml-2 mb-2 mr-2': isDarkMode
              }"
              @click="clickDownload"
            >
              <font-awesome-icon :icon="['fas', 'download']" class="mr-1" />{{ downloadText }}
            </b-button>
            <b-button
              data-v-step="4"
              v-if="increaseBudget && (ifAdmin || ifAccountant || ifRSM || ifSpencerAdmin)"
              size="sm"
              :class="{
                'box-shadow2 btn btn-success ml-2 mb-2 mr-2': !isDarkMode,
                'btn btn-primary text-light ml-2 mb-2 mr-2': isDarkMode
              }"
              @click="handleBudgetIncrease"
            >
              <font-awesome-icon :icon="['fas', 'download']" class="mr-1" />{{ increaseBudgetTest }}
            </b-button>
            <b-button
              v-if="budgetSummary && (ifAdmin || ifSpencerAdmin)"
              size="sm"
              :class="{
                'box-shadow2 btn btn-success ml-2 mb-2 mr-2': !isDarkMode,
                'btn btn-primary text-light ml-2 mb-2 mr-2': isDarkMode
              }"
              @click="handleBudgetSummary"
            >
              <font-awesome-icon :icon="['fas', 'download']" class="mr-1" />{{ budgetSummaryText }}
            </b-button>
          </b-col>
        </b-row>

        <!-- Budget List block -->
        <div
          :class="{
            ' my-4 rounded': !isDarkMode,
            ' my-4 bg-dark rounded text-light': isDarkMode
          }"
        >
          <b-row>
            <b-col>
              <h4 class="page-title text-center">Budget List</h4>
            </b-col>
          </b-row>
          <b-row class="d-flex justify-content-between my-2 align-items-center">
            <b-col v-if="ifAdmin" class="d-flex justify-content-end">
              <b-button
                @click="clickBulkBudgetUpload()"
                :class="{
                  'box-shadow2 btn btn-light mx-4': !isDarkMode,
                  'btn btn-dark border mx-4': isDarkMode
                }"
                ><font-awesome-icon :icon="['fas', 'file-excel']" class="mr-1" style="font-size: 20px" />Upload Budget
              </b-button>
              <div class="mt-2 d-flex">
                <input
                  :checked="allSelected"
                  :disabled="isDisabled"
                  @click="selectAll"
                  class="checkbox_width box-shadow2"
                  type="checkbox"
                  id="scales"
                />
                <label for="scales" class="px-2">Select all </label>
              </div>
              <b-button
                :class="{
                  'box-shadow2 btn btn-success mx-2': !isDarkMode,
                  'btn btn-primary text-light mx-2': isDarkMode
                }"
                :disabled="loading"
                @click="clickMakeAllLive()"
              >
                Make All live
              </b-button>
            </b-col>
          </b-row>
          <div>
            <div
              :class="{
                'box-shadow2 my-3': !isDarkMode,
                ' my-3': isDarkMode
              }"
            >
              <div
                :class="{
                  'table-row table-row-header text-center ': !isDarkMode,
                  'table-row-dark text-center dark-div border-top ': isDarkMode
                }"
              >
                <div
                  v-for="column in columns"
                  :key="column.id"
                  :class="{ 'table-column bg-light': !isDarkMode, 'table-column-dark': isDarkMode }"
                  :style="columnStyle(column)"
                >
                  {{ column.headerText }}
                </div>
              </div>
              <b-overlay v-if="loading" show :variant="isDarkMode ? 'outline-light' : 'outline-secondary'">
                <div
                  :class="{ 'table-row table-row-empty': !isDarkMode, 'table-row-dark table-row-empty': isDarkMode }"
                >
                  <div
                    :class="{
                      'table-column table-column-full text-center': !isDarkMode,
                      'table-column-dark table-column-full text-center': isDarkMode
                    }"
                  >
                  </div>
                </div>
              </b-overlay>
              <div
                :class="{
                  'table-row text-center ': !isDarkMode,
                  'table-row-dark text-center': isDarkMode
                }"
                v-for="row in rows"
                :key="row.id"
              >
                <div
                  v-for="column in columns"
                  :key="column.id"
                  :class="[
                    {
                      ...column.class,
                      'table-column ': !isDarkMode,
                      'table-column-dark table_isLive_dark': isDarkMode,
                      table_isLive: row.isMakeLive === true
                    }
                  ]"
                  :style="columnStyle(column)"
                >
                  <slot v-if="column.slotKey" :name="column.slotKey" v-bind:row="row"></slot>
                  <div v-if="column.htmlKey" v-html="row[column.htmlKey]"></div>
                  <div v-if="column.urlKey"
                    ><a :href="row[column.urlKey]" target="_blank">{{ row[column.urlKey] }}</a></div
                  >
                  <span
                    v-if="column.textKey && column.textKey != 'budgetAmount' && column.textKey !== 'isMakeLive'"
                    class="span-text"
                    >{{ row[column.textKey] }}</span
                  >
                  <span v-if="column.textKey === 'budgetAmount'" class="span-text"
                    >&#x20B9; {{ row[column.textKey] }}</span
                  >
                  <span v-if="column.textKey === 'isMakeLive'" class="span-text">
                    {{ row[column.textKey] === true ? 'Yes' : 'No' }}</span
                  >
                  <div v-if="column.textKey === 'select'">
                    <input
                      class="checkbox2 box-shadow2"
                      type="checkbox"
                      :checked="selectedBudgets.includes(row.id)"
                      :disabled="isExpired(row)"
                      @click="select(row)"
                    />
                  </div>
                  <div v-if="column.type === 'functions'" class="text-center">
                    <b-button-group class="box-shadow2" vertical>
                      <b-button
                        data-v-step="5"
                        v-if="column.functions.edit"
                        :disabled="row.isMakeLive === true ? true : false"
                        size="sm"
                        :variant="isDarkMode ? 'outline-light' : 'outline-secondary'"
                        @click="clickMakeItLive(row)"
                      >
                        <font-awesome-icon :icon="['fas', 'upload']" class="mr-1" />Make it Live
                      </b-button>
                      <b-button
                        data-v-step="5"
                        :disabled="row.isMakeLive === true ? true : false"
                        v-if="column.functions.edit"
                        size="sm"
                        :variant="isDarkMode ? 'outline-light' : 'outline-secondary'"
                        @click="clickDeleteBudget(row)"
                      >
                        <font-awesome-icon :icon="['fas', 'edit']" class="mr-1" />Delete
                      </b-button>
                    </b-button-group>
                  </div>
                </div>
              </div>
              <div
                v-if="!loading && rows.length === 0"
                :class="{ 'table-row table-row-empty': !isDarkMode, 'table-row-dark table-row-empty': isDarkMode }"
              >
                <div
                  :class="{
                    'table-column table-column-full text-center': !isDarkMode,
                    'table-column-dark table-column-full text-center': isDarkMode
                  }"
                  >{{ emptyText }}</div
                >
              </div>
            </div>
            <div class="my-3 d-flex align-items-center">
              <div :class="{ 'colorBox box-shadow2': !isDarkMode, 'colorBoxDark box-shadow2': isDarkMode }"> </div
              ><div class="text-center font-weight-bold mx-1">Live Budgets</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { Months } from '../../helper/pettyCashHelper';
import csv from 'csvtojson';
import router from '@/router';
import _ from 'lodash';
import Vue from 'vue';
import moment from 'moment';
export default {
  name: 'PettyCashBox',
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: this.metaDescription
        }
      ]
    };
  },
  props: {
    columns: Array,
    rows: Array,
    showAdd: Boolean,
    filterOptions: Array,
    showToolbar: Boolean,
    searchItems: Array,
    showDownload: Boolean,
    downloadText: String,
    addText: String,
    budgetText: String,
    loading: Boolean,
    emptyText: String,
    increaseBudget: Boolean,
    increaseBudgetTest: String,
    budgetSummary: Boolean,
    budgetSummaryText: String
  },
  computed: {
    ...mapState('cstSubmission', ['store']),
    ...mapState('darkMode', ['isDarkMode']),
    ...mapGetters('pettyCash', ['ifAdmin', 'ifAccountant', 'ifRSM', 'ifSpencerAdmin']),
    metaDescription() {
      return 'Petty cash box';
    }
  },
  watch: {
    store(newValue) {
      this.storeFromDatabase = newValue.map(item => item.storeid);
    },
    searchTerm(newValue) {
      if (newValue === '' && Object.prototype.hasOwnProperty.call(this.$route.query, 'search')) {
        const query = { ...this.$route.query };
        delete query.search;
        this.$router.push({ query });
      }
    }
  },
  data() {
    return {
      loadingText: '',
      storeFromDatabase: [],
      selectedYear: new Date().getFullYear(), // Initial selection: current year
      years: [],
      months: Months,
      selectedMonth: moment().format('MMMM').toUpperCase(),
      filterSelected: null,
      startDateValue: moment().subtract(1, 'month').format('YYYY-MM-DD'),
      endDateValue: moment().format('YYYY-MM-DD'),
      searchTerm: null,
      selectedSearchVariable: this.searchItems && this.searchItems[0],
      selectedCategory: null,
      selectedBudgets: [],
      allSelected: false,
      isDisabled: false
    };
  },
  mounted() {
    const currentYear = new Date().getFullYear();
    for (let year = currentYear - 2; year <= currentYear + 2; year++) {
      this.years.push(year);
    }
    this.getStoreByUser();
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'search')) {
      this.searchTerm = this.$route.query.search;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'searchkey')) {
      this.selectedSearchVariable = this.$route.query.searchkey;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
      this.startDateValue = this.$route.query.startdate;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'enddate')) {
      this.endDateValue = this.$route.query.enddate;
    }
  },
  methods: {
    ...mapActions('cstSubmission', ['getStoreByUser']),
    ...mapActions('pettyCash', ['bulkBudgetUpload']),
    updateSelectedMonth() {
      const inputDate = moment(`${this.selectedMonth} ${this.selectedYear} ${moment().format('YYYY')}`, 'MMMM YYYY');
      this.startDateValue = inputDate.startOf('month').format('YYYY-MM-DD');
      this.endDateValue = inputDate.endOf('month').format('YYYY-MM-DD');
    },
    select(row) {
      this.allSelected = false;
      if (this.selectedBudgets.includes(row.id)) {
        this.selectedBudgets = this.selectedBudgets.filter(i => i !== row.id);
      } else {
        this.selectedBudgets = [...this.selectedBudgets, row.id];
      }
    },
    selectAll() {
      if (this.allSelected) {
        this.selectedBudgets = [];
        this.allSelected = false;
      } else {
        this.selectedBudgets = this.rows.filter(row => row.isMakeLive === false).map(i => i.id);
        this.allSelected = true;
      }
    },
    isExpired(row) {
      if (row.isMakeLive === true) {
        return true;
      }
      return false;
    },
    clickMakeAllLive() {
      if (this.selectedBudgets.length > 0) {
        this.$emit('allLive', { ids: this.selectedBudgets });
      }
      this.selectedBudgets = [];
    },
    clickMakeItLive(row) {
      this.$emit('edit', { row });
    },
    clickDeleteBudget(row) {
      Vue.swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this.",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        preConfirm: () => {
          this.$emit('deleteBudget', { row });
        },
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        }
      });
    },
    clickEditExpense(row) {
      this.$emit('editExpense', { row });
    },
    clickApproveExpense(row) {
      this.$emit('approveExpense', { row });
    },
    clickDenyExpense(row) {
      this.$emit('denyExpense', { row });
    },
    addBudgetClick() {
      this.$emit('budget', {});
    },
    addExpenseClick() {
      this.$emit('expense', {});
    },
    checkDisabled(row) {
      if (row.status === 'REJECTED' || row.status === 'APPROVED') {
        return true;
      }
      return false;
    },
    createCsvForBudget() {
      const csv = `Company Name, Store Type, Store Id,Petty Cash Store GL, Cost Center, Amount,Month(eg:January) , Year\n`;
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'Budget.csv';
      anchor.click();
    },
    async clickBulkBudgetUpload() {
      await Vue.swal({
        title: 'Upload Budget',
        input: 'file',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        denyButtonText: 'Download Sample',
        denyButtonColor: '#3a4',
        showCancelButton: true,
        showDenyButton: true,
        cancelButtonText: 'Close',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        }
      }).then(result => {
        if (result.isDenied) {
          this.createCsvForBudget();
        }
        if (result.value.name.includes('.csv')) {
          const reader = new FileReader();
          reader.onload = async () => {
            csv({
              noheader: true,
              output: 'csv'
            })
              .fromString(reader.result)
              .then(csvRow => {
                const list = {
                  budgetList: csvRow.slice(1, csvRow.length).map(item => {
                    if (item[2] != '') {
                      const uploadingStore = item[2].toUpperCase();
                      if (!this.storeFromDatabase.includes(uploadingStore)) {
                        Vue.swal({
                          title: 'Please Provide correct Store Id',
                          text: `Store id ${uploadingStore} Not found in the database`,
                          type: 'error',
                          confirmButtonText: 'Ok',
                          background: this.isDarkMode ? '#464d54' : '#ffffff',
                          customClass: {
                            title: this.isDarkMode ? 'text-light' : 'text-dark',
                            content: this.isDarkMode ? 'text-light' : 'text-dark'
                          }
                        });
                        return null;
                      }
                    }
                    if (item[5] != '') {
                      const tempItem = parseInt(Number(item[5].replaceAll(',', '')));
                      if (!Number.isInteger(tempItem)) {
                        Vue.swal({
                          title: "Amount Can't be Non-Integer",
                          text: `Amount must be provided in correct format`,
                          type: 'error',
                          confirmButtonText: 'Ok',
                          background: this.isDarkMode ? '#464d54' : '#ffffff',
                          customClass: {
                            title: this.isDarkMode ? 'text-light' : 'text-dark',
                            content: this.isDarkMode ? 'text-light' : 'text-dark'
                          }
                        });
                        return null;
                      }
                      item[5] = tempItem;
                    }
                    if (item[6] !== '') {
                      const monthUpperCase = item[6].toUpperCase();
                      if (!this.months.includes(monthUpperCase)) {
                        Vue.swal({
                          title: 'Wrong Month Spelling',
                          text: `Please provide a valid month (${item[6]})`,
                          type: 'error',
                          confirmButtonText: 'Ok',
                          background: this.isDarkMode ? '#464d54' : '#ffffff',
                          customClass: {
                            title: this.isDarkMode ? 'text-light' : 'text-dark',
                            content: this.isDarkMode ? 'text-light' : 'text-dark'
                          }
                        });
                        return null;
                      }
                    }
                    if (item[7] === '') {
                      item[7] = moment().format('YYYY');
                    }
                    const inputDate = moment(`${item[6]} ${item[7]} ${moment().format('YYYY')}`, 'MMMM YYYY');
                    item[6] = inputDate.startOf('month').format('YYYY-MM-DD');
                    item[7] = inputDate.endOf('month').format('YYYY-MM-DD');
                    return {
                      companyName: item[0].toUpperCase(),
                      storeType: item[1].toUpperCase(),
                      storeId: item[2].toUpperCase(),
                      pattyCashStoreGl: item[3],
                      costCenter: item[4].toUpperCase(),
                      budgetAmount: item[5],
                      validFrom: item[6],
                      validTo: item[7]
                    };
                  })
                };
                if (list.budgetList.every(item => item !== null)) {
                  this.bulkBudgetUpload({
                    list: list,
                    router: router,
                    redirectUrl: '/petty-cash/budget'
                  });
                } else return null;
              });
          };
          reader.readAsBinaryString(result.value);
        } else {
          Vue.swal({
            title: 'Error',
            icon: 'error',
            text: 'Please provide a valid File',
            background: this.isDarkMode ? '#464d54' : '#ffffff',
            customClass: {
              title: this.isDarkMode ? 'text-light' : 'text-dark',
              content: this.isDarkMode ? 'text-light' : 'text-dark'
            }
          });
          return null;
        }
      });
    },
    columnStyle(column) {
      return { width: column.width };
    },
    clickBudget() {
      this.$emit('budget', {});
    },
    clickDownload() {
      this.$emit('download');
    },
    handleBudgetIncrease() {
      this.$emit('budgetIncrease', {});
    },
    handleBudgetSummary() {
      this.$emit('budgetSummary', {});
    },

    search(event) {
      if (event.key === 'Enter') {
        if (this.searchTerm) {
          if (this.$route.query.searchTerm !== this.searchTerm) {
            if (this.selectedSearchVariable) {
              this.$router.push({
                query: { ...this.$route.query, search: this.searchTerm, searchkey: this.selectedSearchVariable }
              });
            } else {
              this.$router.push({ query: { ...this.$route.query, search: this.searchTerm } });
            }
          }
        } else {
          const query = { ...this.$route.query };
          delete query.filter;
          this.$router.push({ query });
        }
      }
    },
    applyDate() {
      if (this.selectedMonth) {
        this.$router.push({
          query: {
            ...this.$route.query,
            startdate: moment(this.startDateValue).format('YYYY-MM-DD'),
            enddate: moment(this.endDateValue).format('YYYY-MM-DD')
          }
        });
      }
    },
    clearDate() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        const query = { ...this.$route.query };
        delete query.startdate;
        delete query.enddate;
        this.$router.push({ query });
      }
      this.startDateValue = null;
      this.endDateValue = null;
    },
    linkGen(pageNum) {
      return { path: this.baseUrl, query: { ...this.$route.query, page: pageNum } };
    }
  }
};
</script>

<style src="../../assets/css/Table.css"></style>
