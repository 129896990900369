<template>
  <div class="page-class d-flex flex-column">
    <!-- Search filter and dated -->

    <b-row v-if="showToolbar" class="table-top-wrapper mb-2 mt-2 mx-0">
      <b-col sm="12" lg="3">
        <b-input-group data-v-step="0" class="box-shadow2 mb-2 ml-2">
          <b-form-input
            :class="{
              'bg-light text-dark': !isDarkMode,
              'bg-dark text-light placeholder-light': isDarkMode
            }"
            v-model="searchTerm"
            @keydown.native="search"
            placeholder="Search..."
          ></b-form-input>
          <template #append v-if="searchItems">
            <b-dropdown
              :variant="isDarkMode ? 'primary' : 'success'"
              :text="selectedSearchVariable"
              :menu-class="isDarkMode ? 'bg-dark box-shadow2' : 'box-shadow2'"
            >
              <b-dropdown-item
                v-for="item in searchItems"
                :key="item"
                @click="selectedSearchVariable = item"
                :link-class="isDarkMode ? 'text-light bg-dark' : ''"
                >{{ item }}</b-dropdown-item
              >
            </b-dropdown>
          </template>
        </b-input-group>
      </b-col>
      <b-col sm="12" lg="3">
        <b-form-select
          data-v-step="1"
          v-model="filterSelected"
          :options="filterOptions"
          :class="{
            'box-shadow2 ml-2 mb-2  bg-light text-dark': !isDarkMode,
            'box-shadow2 ml-2 mb-2 bg-dark text-light': isDarkMode
          }"
        ></b-form-select>
      </b-col>
      <b-col sm="12" lg="6">
        <b-form data-v-step="2" inline class="float-right">
          <b-row>
            <b-col>
              <b-form-select
                :class="{
                  'box-shadow2 bg-light mb-2 ml-2 text-dark': !isDarkMode,
                  'box-shadow2 bg-dark mb-2 ml-2 text-light': isDarkMode
                }"
                id="monthPicker"
                :options="months"
                v-model="selectedMonth"
                @change="updateSelectedMonth"
              >
              </b-form-select>
              <b-form-select
                :class="{
                  'box-shadow2 mb-2 ml-2 bg-light text-dark': !isDarkMode,
                  'box-shadow2 mb-2 ml-2 bg-dark text-light': isDarkMode
                }"
                id="yearPicker"
                :options="years"
                v-model="selectedYear"
                @change="updateSelectedMonth"
              >
              </b-form-select>
              <b-button
                :class="{
                  'box-shadow2 ml-2 btn btn-success mb-2': !isDarkMode,
                  'btn btn-primary ml-2 text-light mb-2': isDarkMode
                }"
                @click="applyDate"
                >Apply</b-button
              >
              <b-button
                :variant="isDarkMode ? 'outline-light' : 'outline-secondary'"
                class="box-shadow2 ml-2 mb-2"
                @click="clearDate"
                >Clear</b-button
              >
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>

    <!-- Budget Increase modal -->
    <div class="">
      <div class="">
        <div class="">
          <b-button
            @click="isRasedRequestModalOpen = true"
            :class="{
              'mt-4 box-shadow2 btn btn-success': !isDarkMode,
              'box-shadow4 mt-4 btn btn-primary text-light': isDarkMode
            }"
            v-if="isRasedRequestModalOpen === false && ifAccountant"
            >Add Request</b-button
          >
          <div v-if="isRasedRequestModalOpen === true" class="budget-increase">
            <div>
              <b-form-group id="group-product" label-for="input-product">
                <b-row>
                  <b-col :cols="8">
                    <div class="mb-1">
                      <template class="">
                        Request Budget Increase Amount
                        <span class="text-danger">*</span>
                      </template>
                    </div>
                    <b-form-group id="input-budgetIncreaseAmount">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">&#8377;</span>
                        </div>
                        <b-form-input
                          type="number"
                          id="input-budgetIncreaseAmount"
                          v-model="budgetIncreaseAmount"
                          min="0"
                          :class="{
                            'box-shadow2 form-control bg-light text-dark': !isDarkMode,
                            'bg-dark text-light placeholder-light': isDarkMode
                          }"
                          placeholder="Enter Amount"
                          :state="$v.budgetIncreaseAmount.$dirty ? !$v.budgetIncreaseAmount.$error : null"
                        />
                        <b-form-invalid-feedback id="input-budgetIncreaseAmount-invalid"
                          >Please Provide Amount</b-form-invalid-feedback
                        >
                      </div>
                    </b-form-group>
                    <div class="my-3">
                      <b-form-group id="input-reasonText">
                        <div class="mb-1">
                          <template class="">
                            Reason for Increment
                            <span class="text-danger">*</span>
                          </template>
                        </div>
                        <b-form-textarea
                          :class="{
                            'box-shadow2 bg-light text-dark': !isDarkMode,
                            'bg-dark text-light placeholder-light': isDarkMode
                          }"
                          placeholder="Enter Reason for increment..."
                          rows="4"
                          id="input-reasonTest"
                          :state="$v.reasonText.$dirty ? !$v.reasonText.$error : null"
                          v-model="reasonText"
                        >
                        </b-form-textarea>
                        <b-form-invalid-feedback id="input-reasonText"
                          >Please Provide Reason for Increment</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col class="d-flex justify-content-end align-self-end">
                    <b-button
                      @click="handleRaisedRequest()"
                      class="mx-2 box-shadow2"
                      :variant="isDarkMode ? 'outline-success' : 'success'"
                      >Send Request</b-button
                    >
                    <b-button
                      @click="isRasedRequestModalOpen = false"
                      class="box-shadow2"
                      :variant="isDarkMode ? 'outline-danger' : 'danger'"
                      >Cancel</b-button
                    >
                  </b-col>
                </b-row>
              </b-form-group>
            </div>
          </div>
        </div>

        <!-- List Start -->
        <div
          :class="{
            'my-4 rounded': !isDarkMode,
            ' my-4 bg-dark rounded text-light': isDarkMode
          }"
        >
          <b-row>
            <b-col>
              <h4 class="page-title text-center">Request List</h4>
            </b-col>
          </b-row>
          <div>
            <div
              :class="{
                'box-shadow2 my-3': !isDarkMode,
                ' my-3': isDarkMode
              }"
            >
              <div
                :class="{
                  'table-row table-row-header text-center': !isDarkMode,
                  'table-row-dark text-center dark-div border-top': isDarkMode
                }"
              >
                <div
                  v-for="column in columns"
                  :key="column.id"
                  :class="{ 'table-column bg-light': !isDarkMode, 'table-column-dark': isDarkMode }"
                  :style="columnStyle(column)"
                >
                  {{ column.headerText }}
                </div>
              </div>
              <b-overlay v-if="loading" show :variant="isDarkMode ? 'outline-light' : 'outline-secondary'">
                <div
                  :class="{ 'table-row table-row-empty': !isDarkMode, 'table-row-dark table-row-empty': isDarkMode }"
                >
                  <div
                    :class="{
                      'table-column table-column-full text-center': !isDarkMode,
                      'table-column-dark table-column-full text-center': isDarkMode
                    }"
                  >
                  </div>
                </div>
              </b-overlay>
              <div
                :class="{
                  'table-row text-center ': !isDarkMode,
                  'table-row-dark text-center': isDarkMode
                }"
                v-for="row in rows"
                :key="row.id"
              >
                <div
                  v-for="column in columns"
                  :key="column.id"
                  :class="{ 'table-column': !isDarkMode, 'table-column-dark': isDarkMode }"
                  :style="columnStyle(column)"
                >
                  <slot v-if="column.slotKey" :name="column.slotKey" v-bind:row="row"></slot>
                  <div v-if="column.htmlKey" v-html="row[column.htmlKey]"></div>
                  <div v-if="column.urlKey"
                    ><a :href="row[column.urlKey]" target="_blank">{{ row[column.urlKey] }}</a></div
                  >
                  <span v-if="column.textKey && column.textKey != 'amount'" class="span-text">{{
                    row[column.textKey]
                  }}</span>
                  <span v-if="column.textKey === 'amount'" class="span-text">&#x20B9; {{ row[column.textKey] }}</span>
                  <div v-if="column.type === 'functions'" class="text-center">
                    <b-button-group class="box-shadow2" vertical>
                      <b-button
                        data-v-step="5"
                        v-if="
                          column.functions.edit && !ifSpencerAdmin && (ifAdmin || ifRSM) && row.status === 'PENDING'
                        "
                        :disabled="loading || handleApproveDisable(row)"
                        size="sm"
                        :class="{
                          '': !isDarkMode,
                          'text-white': isDarkMode
                        }"
                        variant="outline-success"
                        @click="handleApprove(row)"
                      >
                        <font-awesome-icon :icon="['fas', 'upload']" class="mr-1" />{{ handleApproveButtonText(row) }}
                      </b-button>
                      <b-button
                        data-v-step="5"
                        :disabled="loading || row.isApproved === true ? true : false"
                        v-if="
                          column.functions.edit &&
                          !ifSpencerAdmin &&
                          ((ifAdmin && !row.isApproved) || (ifRSM && !row.isApprovedByRSM)) &&
                          row.status === 'PENDING'
                        "
                        size="sm"
                        variant="outline-secondary"
                        @click="handleDeny(row)"
                      >
                        <font-awesome-icon :icon="['fas', 'edit']" class="mr-1" />Deny
                      </b-button>
                      <b-button
                        data-v-step="5"
                        disabled
                        v-if="column.functions.edit && !ifSpencerAdmin && row.status !== 'PENDING'"
                        size="sm"
                        @click="handleDeny(row)"
                        :variant="row.status === 'REJECTED' ? 'outline-danger' : 'outline-success'"
                        :class="{
                          '': !isDarkMode,
                          'text-white': isDarkMode
                        }"
                      >
                        <!-- variant="outline-secondary" -->
                        <font-awesome-icon :icon="['fas', 'edit']" class="mr-1" />{{
                          row.status === 'REJECTED' ? 'REJECTED' : 'APPROVED'
                        }}
                      </b-button>
                      <b-button
                        data-v-step="6"
                        :disabled="loading"
                        size="sm"
                        variant="outline-info"
                        :class="{
                          '': !isDarkMode,
                          'text-white': isDarkMode
                        }"
                        @click="clickCheckStatus(row)"
                      >
                        <font-awesome-icon :icon="['fas', 'check']" class="mr-1" />
                        Check Status
                      </b-button>
                    </b-button-group>
                  </div>
                </div>
              </div>
              <div v-if="!loading && rows.length === 0" class="table-row table-row-empty">
                <div class="table-column table-column-full text-center">{{ emptyText }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Pagination -->
    <b-row class="mt-2 mx-2">
      <b-col class="px-2 text-right" v-if="showPagination">
        <b-pagination-nav
          data-v-step="7"
          v-if="rows.length"
          v-model="currentPage"
          :link-gen="linkGen"
          :number-of-pages="totalNumberOfPage"
          use-router
          no-page-detect
          align="right"
          :class="{ '': !isDarkMode, 'dark-nav': isDarkMode }"
        ></b-pagination-nav>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { Months } from '../../helper/pettyCashHelper';
import _ from 'lodash';
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';
import Vue from 'vue';

export default {
  name: 'BudgetIncrease',
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: this.metaDescription
        }
      ]
    };
  },
  props: {
    columns: Array,
    rows: Array,
    showAdd: Boolean,
    emptyText: String,
    showToolbar: Boolean,
    searchItems: Array,
    pagination: Object,
    showPagination: Boolean,
    filterOptions: Array
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('darkMode', ['isDarkMode']),
    ...mapState('pettyCash', ['loading', 'baseUrl', 'increaseBudgetRequestList']),
    ...mapGetters('pettyCash', ['ifAdmin', 'ifAccountant', 'ifRSM', 'ifSpencerAdmin']),
    metaDescription() {
      return 'Budget Increase Box';
    },
    totalNumberOfPage() {
      if (this.pagination) {
        if (this.pagination?.total_rows && this.pagination?.page_size) {
          return Math.ceil(this.pagination?.total_rows / this.pagination?.page_size);
        }
      }
      return 0;
    },
    currentPage() {
      if (this.pagination?.page) {
        return this.pagination?.page;
      }
      return 1;
    }
  },
  watch: {
    filterSelected(newValue, oldValue) {
      if (newValue !== oldValue && this.$route.query.filter !== newValue) {
        if (!newValue) {
          const query = { ...this.$route.query };
          delete query.filter;
          this.$router.push({ query });
        } else {
          this.$router.push({ query: { ...this.$route.query, filter: newValue } });
        }
      }
    },
    searchTerm(newValue) {
      if (newValue === '' && Object.prototype.hasOwnProperty.call(this.$route.query, 'search')) {
        const query = { ...this.$route.query };
        delete query.search;
        this.$router.push({ query });
      }
    }
  },
  data() {
    return {
      filterSelected: null,
      startDateValue: moment().subtract(1, 'month').format('YYYY-MM-DD'),
      endDateValue: moment().format('YYYY-MM-DD'),
      searchTerm: null,
      minDate: moment('2020-01-01').format('YYYY-MM-DD'),
      maxDate: moment().format('YYYY-MM-DD'),
      selectedSearchVariable: this.searchItems && this.searchItems[0],
      isRasedRequestModalOpen: false,
      budgetIncreaseAmount: '',
      reasonText: '',
      selectedYear: new Date().getFullYear(), // Initial selection: current year
      years: [],
      months: Months,
      selectedMonth: moment().format('MMMM').toUpperCase()
    };
  },
  mounted() {
    const currentYear = new Date().getFullYear();
    for (let year = currentYear - 2; year <= currentYear + 2; year++) {
      this.years.push(year);
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'filter')) {
      this.filterSelected = this.$route.query.filter;
    } else {
      this.filterSelected = null;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'search')) {
      this.searchTerm = this.$route.query.search;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'searchkey')) {
      this.selectedSearchVariable = this.$route.query.searchkey;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
      this.startDateValue = this.$route.query.startdate;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'enddate')) {
      this.endDateValue = this.$route.query.enddate;
    }
    this.$nextTick(async () => {
      // this.form.status = Checklist.checklistEnabled.active;
      this.$v.$touch(); // Set initial validation
      this.$v.$reset(); // Reset $dirty
    });
  },
  validations() {
    const budgetIncreaseAmount = { required };
    const reasonText = { required };
    budgetIncreaseAmount.required = required;
    reasonText.required = required;
    return { budgetIncreaseAmount, reasonText };
  },
  methods: {
    handleApproveDisable(row) {
      if (this.ifRSM) {
        if (row.isApprovedByRSM) {
          return true;
        }
        return false;
      } else {
        if (this.ifAdmin) {
          if (row.isApproved) {
            return true;
          } else return false;
        }
      }
    },
    handleApproveButtonText(row) {
      if (this.ifRSM) {
        if (row.isApprovedByRSM) {
          return 'Approved';
        } else return 'Approve';
      } else {
        if (row.isApproved) {
          return 'Approved';
        } else return 'Approve';
      }
    },
    updateSelectedMonth() {
      const inputDate = moment(`${this.selectedMonth} ${this.selectedYear} ${moment().format('YYYY')}`, 'MMMM YYYY');
      this.startDateValue = inputDate.startOf('month').format('YYYY-MM-DD');
      this.endDateValue = inputDate.endOf('month').format('YYYY-MM-DD');
    },
    linkGen(pageNum) {
      return { path: this.$route.fullPath, query: { ...this.$route.query, page: pageNum } };
    },
    clickCheckStatus(row) {
      Vue.swal({
        title: 'Budget Increase Request Status',
        html: this.handleModalHtml(row),
        showCancelButton: false,
        confirmButtonText: 'Ok',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark',
          confirmButton: 'btn btn-primary',
          popup: 'col-md-3 animate__animated animate__fadeInDown',
          input: this.isDarkMode ? 'text-light' : 'text-dark'
        }
      });
    },
    handleModalHtml(row) {
      if (row.status === 'REJECTED') {
        if (row.isApprovedByRSM === false) {
          return `<div><h4>Rejected by : RSM </h4><div><span><b>Reason : </b></span>${row.statusRemarks}</div></div>`;
        }
        return `<div><h4>Rejected by : Admin </h4><div><span><b>Reason : </b></span>${row.statusRemarks}</div></div>`;
      }
      if (row.isApprovedByRSM === false) {
        return `<b>Waiting for RSM Approval</b>`;
      }
      return `<div><div style="margin:10px;"> ${
        row.status === 'APPROVED'
          ? `<b>Approved by Admin and Budget has been increased</b>`
          : `Approved by RSM & Waiting for Admin approval`
      } </div>
       </div>`;
    },
    handleRaisedRequest() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      this.isRasedRequestModalOpen = false;
      const increasedAmount = this.budgetIncreaseAmount;
      const reasonForIncrement = this.reasonText;
      const processDoneBy = 'Web';
      this.$emit('increase', { increasedAmount, reasonForIncrement, processDoneBy });
      this.budgetIncreaseAmount = '';
      this.reasonText = '';
    },
    columnStyle(column) {
      return { width: column.width };
    },
    handleApprove(row) {
      this.$emit('approved', { row });
    },
    async handleDeny(row) {
      await Vue.swal({
        title: 'Action Required',
        text: 'Please provide a Reason for Rejection',
        input: 'textarea',
        inputAttributes: {
          rows: 6,
          cols: 10
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Reject Request',
        denyButtonColor: '#3a4',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        }
      }).then(result => {
        if (result.isDenied) {
          return null;
        }
        if (result.value) {
          if (result.value === '') {
            return null;
          }
          const rejectionReason = result.value;
          this.$emit('denied', { ...row, statusRemarks: rejectionReason });
        }
      });
    },
    search(event) {
      if (event.key === 'Enter') {
        if (this.searchTerm) {
          if (this.$route.query.searchTerm !== this.searchTerm) {
            if (this.selectedSearchVariable) {
              this.$router.push({
                query: { ...this.$route.query, search: this.searchTerm, searchkey: this.selectedSearchVariable }
              });
            } else {
              this.$router.push({ query: { ...this.$route.query, search: this.searchTerm } });
            }
          }
        } else {
          const query = { ...this.$route.query };
          delete query.filter;
          this.$router.push({ query });
        }
      }
    },
    applyDate() {
      if (this.selectedMonth) {
        this.$router.push({
          query: {
            ...this.$route.query,
            startdate: moment(this.startDateValue).format('YYYY-MM-DD'),
            enddate: moment(this.endDateValue).format('YYYY-MM-DD')
          }
        });
      }
    },
    clearDate() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        const query = { ...this.$route.query };
        delete query.startdate;
        delete query.enddate;
        this.$router.push({ query });
      }
      this.startDateValue = null;
      this.endDateValue = null;
    }
  }
};
</script>

<style src="../../assets/css/Table.css"></style>
