<template>
  <div class="page-class page-setting-list">
    <div class="page-title text-center">
      <h2>{{ title }}</h2>
    </div>
    <petty-cash-in-hand-box
      :columns="columnsToShow"
      :columnsForTransaction="columnsForTransaction"
      :transactionRows="cashSnapshotTransactionList"
      :rows="cashSnapshotList"
      :showToolbar="true"
      :showAdd="true"
      :searchItems="searchItems"
      :loading="loading"
      :showPagination="true"
      :pagination="pagination"
      emptyText="No data available"
      :filterOptions="filterOptions"
      :showDownload="true"
      createNewText="Create New Account"
      downloadText="Download List"
      @new="onNewForm"
      @addCash="onAddAmount"
      @account="onSeeAccount"
      @download="onDownload"
    ></petty-cash-in-hand-box>
  </div>
</template>

<script>
import PettyCashInHandBox from '@/components/PettyCash/PettyCashInHandBox.vue';
import router from '@/router';
import { mapState, mapActions, mapGetters } from 'vuex';
import {
  NonAdminCashAccountView,
  AdminCashAccountView,
  NonAdminTransactionView
} from '../../../helper/pettyCashHelper';

export default {
  name: 'PettyCash',
  components: {
    PettyCashInHandBox
  },
  mounted() {
    if (_.isEmpty(this.$route.query)) {
      this.listCashSnapshot({
        query: {}
      });
      if (!this.ifAdmin) {
        this.listCashSnapshot_Transaction({
          query: {
            ...this.$route.query
          }
        });
      }
    } else {
      this.listCashSnapshot({ query: {} });
      if (!this.ifAdmin) {
        this.listCashSnapshot_Transaction({ query: this.$route.query });
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.listCashSnapshot({ query: {} });
    this.listCashSnapshot_Transaction({ query: to.query });
    next();
  },
  methods: {
    ...mapActions('pettyCash', ['listCashSnapshot', 'postAddCash', 'listCashSnapshot_Transaction']),
    onDownload() {
      let csv =
        'Sr. No,Company Name ,Store Id ,Petty Cash Store Gl , Monthly Opening balance,Current Balance ,Last Update\n';
      this.cashSnapshotList.forEach(row => {
        Object.values(row).forEach((val, index) => {
          if ([1, 2, 3, 4, 5, 6, 7].includes(index)) {
            csv += `"${val}"`;
            csv += ',';
          }
        });
        csv += '\n';
      });
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'Accounts Details.csv';
      anchor.click();
    },
    onNewForm() {
      router.push('/petty-cash/account/new');
    },
    onSeeAccount({ row }) {
      router.push(`/petty-cash/account/${row?.storeId}`);
    },
    onAddAmount(list) {
      const addition = {
        storeId: list.storeId,
        pattyCashStoreGl: list.pattyCashStoreGl,
        amount: Number(list.addedAmount),
        processDoneBy: list.processDoneBy
      };
      this.postAddCash({ addition, router, redirectUrl: 'petty-cash' });
    }
  },
  computed: {
    ...mapState('pettyCash', ['loading', 'pagination', 'cashSnapshotList', 'cashSnapshotTransactionList']),
    ...mapState('auth', ['user']),
    ...mapGetters('pettyCash', ['ifAdmin', 'ifSpencerAdmin']),
    columnsToShow() {
      if (!this.ifAdmin && !this.ifSpencerAdmin) {
        return NonAdminCashAccountView;
      }
      return AdminCashAccountView;
    }
  },
  watch: {},
  data() {
    return {
      title: 'Petty Cash - Account',
      searchItems: ['Store Id'],
      filterOptions: [
        { value: null, text: 'Select Type' },
        { value: 'CREDIT', text: 'CREDIT' },
        { value: 'DEBIT', text: 'DEBIT' }
      ],
      columnsForTransaction: NonAdminTransactionView
    };
  }
};
</script>
