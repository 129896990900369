<template>
  <div class="page-class page-setting-list">
    <div class="page-title text-center">
      <h2>{{ title }}</h2>
    </div>
    <div class="page-class">
      <b-row class="table-top-wrapper mb-2 mt-2">
        <b-col lg="3" sm="12">
          <b-form-select
            data-v-step="1"
            v-model="filterSelected"
            :options="filterOptions"
            :class="{
              'box-shadow2 mb-2 ml-2  bg-light text-dark': !isDarkMode,
              'box-shadow2 mb-2 ml-2 bg-dark text-light': isDarkMode
            }"
          ></b-form-select>
        </b-col>
        <b-col sm>
          <b-form data-v-step="2" inline class="float-right">
            <b-input-group class="box-shadow2 ml-2 mb-2">
              <b-input-group-append>
                <b-form-datepicker
                  button-only
                  id="start-datepicker"
                  placeholder="Start Date"
                  :min="minDate"
                  :max="maxDate"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  v-model="startDateValue"
                ></b-form-datepicker>
              </b-input-group-append>
              <b-form-input
                id="example-input"
                v-model="startDateValue"
                type="text"
                placeholder="Start Date"
                autocomplete="off"
                :class="{
                  '': !isDarkMode,
                  'bg-dark text-light placeholder-light': isDarkMode
                }"
                disabled
              ></b-form-input>
            </b-input-group>
            <b-input-group class="box-shadow2 ml-2 mb-2">
              <b-input-group-append>
                <b-form-datepicker
                  button-only
                  id="end-datepicker"
                  placeholder="End Date"
                  :min="minDate"
                  :max="maxDate"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  v-model="endDateValue"
                ></b-form-datepicker>
              </b-input-group-append>
              <b-form-input
                id="example-input"
                v-model="endDateValue"
                type="text"
                placeholder="End Date"
                autocomplete="off"
                :class="{
                  '': !isDarkMode,
                  'bg-dark text-light placeholder-light': isDarkMode
                }"
                disabled
              ></b-form-input>
            </b-input-group>
            <b-button
              :class="{
                'box-shadow2 btn btn-success ml-2 mb-2': !isDarkMode,
                'btn btn-primary text-light ml-2 mb-2': isDarkMode
              }"
              @click="applyDate"
              >Apply</b-button
            >
            <b-button
              :variant="isDarkMode ? 'outline-light' : 'outline-secondary'"
              class="box-shadow2 ml-2 mb-2"
              @click="clearDate"
              >Clear</b-button
            >
          </b-form>
        </b-col>
      </b-row>

      <div>
        <div>
          <!-- Button block -->
          <b-row class="table-bottom-wrapper m-2 mx-0">
            <b-col :cols="12" class="px-0">
              <b-button
                data-v-step="4"
                @click="clickDownload"
                size="sm"
                :class="{
                  'box-shadow2 btn btn-success mr-2': !isDarkMode,
                  'btn btn-primary text-light mr-2': isDarkMode
                }"
              >
                <font-awesome-icon :icon="['fas', 'download']" class="mr-1" />Download List
              </b-button>
            </b-col>
          </b-row>

          <!-- cash transaction list flow -->
          <div
            :class="{
              ' my-4 rounded': !isDarkMode,
              '  my-4 bg-dark rounded text-light': isDarkMode
            }"
          >
            <b-row>
              <b-col>
                <h5 class="page-title text-center">Transactions</h5>
              </b-col>
            </b-row>
            <div>
              <div
                :class="{
                  'box-shadow2 my-3': !isDarkMode,
                  ' my-3': isDarkMode
                }"
              >
                <div
                  :class="{
                    'table-row table-row-header text-center': !isDarkMode,
                    'table-row-dark text-center dark-div border-top': isDarkMode
                  }"
                >
                  <div
                    v-for="column in columns"
                    :key="column.id"
                    :class="{ 'table-column bg-light': !isDarkMode, 'table-column-dark': isDarkMode }"
                    :style="columnStyle(column)"
                  >
                    {{ column.headerText }}
                  </div>
                </div>
                <b-overlay v-if="loading" show :variant="isDarkMode ? 'outline-light' : 'outline-secondary'">
                  <div
                    :class="{ 'table-row table-row-empty': !isDarkMode, 'table-row-dark table-row-empty': isDarkMode }"
                  >
                    <div
                      :class="{
                        'table-column table-column-full text-center': !isDarkMode,
                        'table-column-dark table-column-full text-center': isDarkMode
                      }"
                    >
                    </div>
                  </div>
                </b-overlay>
                <div
                  :class="{
                    'table-row text-center ': !isDarkMode,
                    'table-row-dark text-center': isDarkMode
                  }"
                  v-for="row in rows"
                  :key="row.id"
                >
                  <div
                    v-for="column in columns"
                    :key="column.id"
                    :class="{ 'table-column': !isDarkMode, 'table-column-dark': isDarkMode }"
                    :style="columnStyle(column)"
                  >
                    <slot v-if="column.slotKey" :name="column.slotKey" v-bind:row="row"></slot>
                    <div v-if="column.htmlKey" v-html="row[column.htmlKey]"></div>
                    <div v-if="column.urlKey"
                      ><a :href="row[column.urlKey]" target="_blank">{{ row[column.urlKey] }}</a></div
                    >
                    <span
                      v-if="
                        column.textKey &&
                        column.textKey != 'amount' &&
                        column.textKey !== 'balanceLeft' &&
                        column.textKey !== 'oldBalance'
                      "
                      class="span-text"
                      >{{ row[column.textKey] }}</span
                    >
                    <span
                      v-if="
                        column.textKey === 'amount' ||
                        column.textKey === 'balanceLeft' ||
                        column.textKey === 'oldBalance'
                      "
                      class="span-text"
                      >&#x20B9; {{ row[column.textKey] }}</span
                    >
                  </div>
                </div>
                <div
                  v-if="!loading && rows.length === 0"
                  :class="{ 'table-row table-row-empty': !isDarkMode, 'table-row-dark table-row-empty': isDarkMode }"
                >
                  <div
                    :class="{
                      'table-column table-column-full text-center': !isDarkMode,
                      'table-column-dark table-column-full text-center': isDarkMode
                    }"
                    >{{ emptyText }}</div
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-row>
        <b-col lg="10" sm="12"></b-col>
        <b-col lg="2" sm="12">
          <b-pagination-nav
            data-v-step="7"
            v-if="rows.length"
            v-model="currentPage"
            :link-gen="linkGen"
            :number-of-pages="totalNumberOfPage"
            use-router
            no-page-detect
            :class="{ '': !isDarkMode, 'dark-nav': isDarkMode }"
          ></b-pagination-nav>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import _ from 'lodash';
import Vue from 'vue';
import moment from 'moment';
import { Months, TransactionTableView } from '../../helper/pettyCashHelper';
export default {
  name: 'PettyCashInHandAccount',
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: this.metaDescription
        }
      ]
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('darkMode', ['isDarkMode']),
    ...mapState('pettyCash', [
      'loading',
      'baseUrl',
      'pagination',
      'cashSnapshotList',
      'storeCashTransactionList',
      'storeTransactionList'
    ]),
    metaDescription() {
      return 'Petty cash in hand account';
    },
    currentPage() {
      if (this.pagination.page) {
        return this.pagination.page;
      }
      return 1;
    },
    totalNumberOfPage() {
      if (this.pagination) {
        if (this.pagination.total_rows && this.pagination.page_size) {
          return Math.ceil(this.pagination.total_rows / this.pagination.page_size);
        }
      }
      return 0;
    },
    totalCount() {
      if (this.pagination) {
        return this.pagination.total_rows;
      }
      return 0;
    }
  },
  watch: {
    storeTransactionList(newValue) {
      if (newValue.length > 0) {
        let csv = 'Sr. No ,Store Id ,Type ,Petty Cash Store Gl ,Old Balance ,Amount ,Current Balance ,Last Update\n';
        newValue.forEach(row => {
          Object.values(row).forEach((val, index) => {
            if ([1, 2, 3, 4, 5, 6, 7, 8].includes(index)) {
              if (index === 11 && val === true) {
                csv += `Yes`;
                csv += ',';
              } else if (index === 10 && val === false) {
                csv += `No`;
                csv += ',';
              } else {
                csv += `"${val}"`;
                csv += ',';
              }
            }
          });
          csv += '\n';
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = `${this.$route.params.id}-Account Details.csv`;
        anchor.click();
      }
    },
    storeCashTransactionList(newValue) {
      if (newValue !== null && newValue !== undefined) {
        this.rows = newValue;
      }
    },
    filterSelected(newValue, oldValue) {
      if (newValue !== oldValue && this.$route.query.filter !== newValue) {
        if (!newValue) {
          const query = { ...this.$route.query };
          delete query.filter;
          this.$router.push({ query });
        } else {
          this.$router.push({ query: { ...this.$route.query, filter: newValue } });
        }
      }
    },
    searchTerm(newValue) {
      if (newValue === '' && Object.prototype.hasOwnProperty.call(this.$route.query, 'search')) {
        const query = { ...this.$route.query };
        delete query.search;
        this.$router.push({ query });
      }
    }
  },
  data() {
    return {
      emptyText: 'no data Found',
      filterSelected: null,
      startDateValue: moment().subtract(1, 'month').format('YYYY-MM-DD'),
      endDateValue: moment().format('YYYY-MM-DD'),
      minDate: moment('2020-01-01').format('YYYY-MM-DD'),
      maxDate: moment().format('YYYY-MM-DD'),
      startDateValue: '',
      endDateValue: '',
      searchTerm: null,
      selectedSearchVariable: this.searchItems && this.searchItems[0],
      title: `Account - ${this.$route.params.id}`,
      selectedMonth: moment().format('MMMM').toUpperCase(),
      selectedYear: new Date().getFullYear(), // Initial selection: current year
      selectedSearchVariable: this.searchItems && this.searchItems[0],
      months: Months,
      years: [],
      columns: TransactionTableView,
      rows: [],
      filterOptions: [
        { value: null, text: 'Select Type' },
        { value: 'CREDIT', text: 'CREDIT' },
        { value: 'DEBIT', text: 'DEBIT' }
      ],
      storeID: ''
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.storeWiseCashTransaction({ query: to.query, storeId: this.storeID });
    next();
  },
  mounted() {
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
      this.startDateValue = this.$route.query.startdate;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'enddate')) {
      this.endDateValue = this.$route.query.enddate;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'filter')) {
      this.filterSelected = this.$route.query.filter;
    } else {
      this.filterSelected = null;
    }
    this.storeID = this.$route.params.id;
    if (_.isEmpty(this.$route.query)) {
      this.storeWiseCashTransaction({
        query: {
          ...this.$route.query
        },
        storeId: this.storeID
      });
    } else {
      this.storeWiseCashTransaction({ query: this.$route.query, storeId: this.storeID });
    }
  },
  methods: {
    ...mapActions('cstSubmission', ['getStoreByUser']),
    ...mapActions('pettyCash', [
      'listCashSnapshot',
      'postAddCash',
      'storeWiseCashTransaction',
      'downloadStoreTransactionList'
    ]),
    clickDownload() {
      this.downloadStoreTransactionList({
        query: { ...this.$route.query, page_size: 99999, page: 1 },
        storeId: this.storeID
      });
    },
    async onAddCash(row) {
      await Vue.swal({
        title: 'Enter Amount',
        input: 'number',
        placeholder: 'Enter Amount',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Add Amount',
        denyButtonColor: '#3a4',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        }
      }).then(result => {
        if (result.isDenied) {
          return null;
        }
        if (result.value) {
          const addedAmount = result.value;
          this.$emit('addCash', { ...row, addedAmount });
        }
      });
    },
    columnStyle(column) {
      return { width: column.width };
    },
    clickCreateNew() {
      this.$emit('new');
    },
    clickBudget() {
      this.$emit('budget', {});
    },
    search(event) {
      if (event.key === 'Enter') {
        if (this.searchTerm) {
          if (this.$route.query.searchTerm !== this.searchTerm) {
            if (this.selectedSearchVariable) {
              this.$router.push({
                query: { ...this.$route.query, search: this.searchTerm, searchkey: this.selectedSearchVariable }
              });
            } else {
              this.$router.push({ query: { ...this.$route.query, search: this.searchTerm } });
            }
          }
        } else {
          const query = { ...this.$route.query };
          delete query.filter;
          this.$router.push({ query });
        }
      }
    },
    applyDate() {
      if (this.selectedMonth) {
        this.$router.push({
          query: {
            ...this.$route.query,
            startdate: moment(this.startDateValue).format('YYYY-MM-DD'),
            enddate: moment(this.endDateValue).format('YYYY-MM-DD')
          }
        });
      }
    },
    clearDate() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        const query = { ...this.$route.query };
        delete query.startdate;
        delete query.enddate;
        this.$router.push({ query });
      }
      this.startDateValue = null;
      this.endDateValue = null;
    },
    linkGen(pageNum) {
      return { path: this.$route.fullPath, query: { ...this.$route.query, page: pageNum } };
    }
  }
};
</script>

<style src="../../assets/css/Table.css"></style>
