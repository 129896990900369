<template>
  <div class="page-class page-user-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <feedback-list-box
      :columns="columns"
      :rows="bulkList"
      :baseUrl="baseUrl"
      :loading="loading"
      :showAdd="false"
      :showDownload="true"
      :showToolbar="true"
      :showPagination="true"
      :pagination="pagination"
      :searchItems="searchItems"
      :steps="steps"
      :callbacks="callbacks"
      addText="Create new Bulk"
      uploadText="Upload Bulk List"
      downloadText="Download Bulk List"
      emptyText="No Bulk found. Please add new Bulk."
      :showUpload="true"
      @download="onExportCSV"
      @add="onAdd"
      @edit="onEdit"
      @delete="onDelete"
      @show-upload="onShowUpload"
    >
      <template v-slot:emailAddressFormatted="slotProps">
        <span class="span-text span-block">
          {{ slotProps.row.email }}
        </span>
        <template v-if="slotProps.row.confirmedAtFormatted">
          <span class="text-success span-help-text" :title="slotProps.row.confirmedAt">
            ({{ slotProps.row.confirmedAtFormatted }})
          </span>
        </template>
        <template v-if="slotProps.row.confirmedAtFormatted === null">
          <span class="text-warning span-help-text"> (Not confirmed) </span>
        </template>
      </template>
      <template v-slot:lastLoginAtFormatted="slotProps">
        <template v-if="slotProps.row.lastLoginAtFormatted">
          <span class="text-success">{{ slotProps.row.lastLoginAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.lastLoginAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
      <template v-slot:blockedAtFormatted="slotProps">
        <template v-if="slotProps.row.blockedAtFormatted">
          <span class="text-success">{{ slotProps.row.blockedAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.blockedAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
    </feedback-list-box>
  </div>
</template>

<script>
import moment from 'moment';
import Vue from 'vue';
import csv from 'csvtojson';
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import _ from 'lodash';
import FeedbackListBox from '../../components/FeedbackListBox.vue';

export default {
  name: 'Bulk',
  components: { FeedbackListBox },
  metaInfo() {
    return {
      title: 'Bulk List',
      meta: [
        {
          name: 'description',
          content: `Manage Bulk`
        }
      ]
    };
  },
  mounted() {
    // if (window.localStorage.getItem('hasCompletedBulk') !== 'true') {
    //   this.$tours.myTour.start();
    // }
    if (_.isEmpty(this.$route.query)) {
      this.listBulk({
        query: {
          ...this.$route.query,
          startdate: moment().format('YYYY-MM-DD'),
          enddate: moment().format('YYYY-MM-DD')
        }
      });
    } else {
      this.listBulk({ query: this.$route.query });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.listBulk({ query: to.query });
    next();
  },
  data() {
    return {
      title: 'Bulk List',
      callbacks: {
        onFinish() {
          window.localStorage.setItem('hasCompletedBulk', 'true');
        }
      },
      steps: [
        {
          target: '[data-v-step="0"]', // We're using document.querySelector() under the hood
          content: `Enter search criteria based on Customer name or Store Id to Filter the List of Bulk.`,
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="2"]',
          content: 'Select Date Range to further refine search results and click Apply button.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="4"]',
          content: 'Download Bulk List in CSV format.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="5"]',
          content: 'Upload Bulk List in CSV format.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        }
      ],
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { ' text-center': true },
          textKey: 'rowNum',
          width: '3%'
        },
        {
          type: 'string',
          headerText: 'Date Of Enquiry',
          class: { 'text-center': true },
          textKey: 'dateOfEnquiry',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          class: { 'text-center': true },
          textKey: 'storeid',
          width: '5%'
        },
        {
          type: 'String',
          headerText: 'Store Name',
          class: { 'text-center': true },
          textKey: 'storeName',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Customer Name',
          class: { 'text-center': true },
          textKey: 'customerName',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Mobile',
          class: { 'text-center': true },
          textKey: 'mobile',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Email',
          class: { 'text-center': true },
          textKey: 'email',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Organization',
          class: { 'text-center': true },
          textKey: 'organizationName',
          width: '10%'
        },

        {
          type: 'string',
          headerText: 'Gift Price Range',
          class: { 'text-center': true },
          textKey: 'giftPriceRange',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Approx Quantity',
          class: { 'text-center': true },
          textKey: 'approxQuantity',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Type Of Gift',
          class: { 'text-center': true },
          textKey: 'typeOfGift',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Remarks',
          class: { 'text-center': true },
          textKey: 'remarks',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Submitted By Name',
          class: { 'text-center': true },
          textKey: 'submittedByName',
          width: '7%'
        },

        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '7%',
          functions: {
            edit: false,
            delete: true
          }
        }
      ],
      searchItems: ['Customer Name', 'Store Id']
    };
  },
  computed: {
    ...mapState('bulk', ['loading', 'baseUrl', 'bulkList', 'pagination', 'downloadBulkList']),
    ...mapState('darkMode', ['isDarkMode'])
  },
  watch: {
    downloadBulkList(newValue) {
      if (newValue.length) {
        let csv =
          'Row Number,Date Of Enquiry,Store ID,Store Name,Customer Name,Customer Mobile,Customer Mobile(Alternative), Email ,Organization Name ,Gift Price Range ,Approx Quantity , Type Of Gift,Remarks,Submitted By\n';
        newValue.forEach(row => {
          Object.values(row).forEach((val, index) => {
            if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].includes(index)) {
              csv += `"${val}"`;
              csv += ',';
            }
          });
          csv += '\n';
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Bulk Enquiry.csv';
        anchor.click();
      }
    }
  },
  methods: {
    ...mapActions('bulk', ['listBulk', 'deleteOne', 'upload', 'download']),
    onAdd() {
      router.push('/bulk/new');
    },
    onEdit({ row }) {
      router.push(`/bulk/${row.id}`);
    },
    onDelete({ row }) {
      Vue.swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this.",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        },
        preConfirm: () => {
          this.deleteOne({
            id: row.id
          });
        }
      });
    },

    onExportCSV() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.download({ query: { ...this.$route.query, page_size: 99999, page: 1 } });
      } else {
        this.download({
          query: {
            ...this.$route.query,
            page_size: 9999,
            page: 1,
            startdate: moment().subtract(1, 'months').format('MMM YYYY')
          }
        });
      }
    },
    async onShowUpload() {
      const { value: bulkFile } = await Vue.swal({
        title: 'Upload file for Bulks..',
        input: 'file',
        inputAttributes: {
          'aria-label': 'Upload your Bulk file in csv format'
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        showCancelButton: true,
        cancelButtonText: 'Close',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        },
        footer:
          '<a href="https://spencer-template.s3.ap-south-1.amazonaws.com/Bulk.csv" >Download sample upload file</a>'
      });
      if (bulkFile) {
        const reader = new FileReader();
        reader.onload = () => {
          csv({
            noheader: true,
            output: 'csv'
          })
            .fromString(reader.result)
            .then(csvRow => {
              const inputs = csvRow.slice(1, csvRow.length).map(item => {
                return {
                  dateOfEnquiry: item[0],
                  storeid: item[1].toUpperCase(),
                  storeName: item[2],
                  customerName: item[3],
                  mobile: item[4],
                  alternativeMobile: item[5],
                  email: item[6],
                  organizationName: item[7],
                  giftPriceRange: item[8],
                  approxQuantity: item[9],
                  typeOfGift: item[10],
                  remarks: item[11]
                };
              });
              this.upload({ router, data: inputs });
            });
        };
        reader.readAsBinaryString(bulkFile);
      }
    }
  }
};
</script>
