<template>
  <div class="page-class page-center">
    <div class="page-staff-form">
      <h1 class="page-title text-center">{{ title }}</h1>
      <petty-cash-expense-form-box
        :options="options"
        :budgetType="budgetType"
        @postExpense="onPostExpense"
        @patchExpense="onPatchExpense"
        :formType="formType"
      ></petty-cash-expense-form-box>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import PettyCashExpenseFormBox from '@/components/PettyCash/PettyCashExpenseFormBox.vue';

export default {
  name: 'PettyCashExpenseForm',
  components: {
    PettyCashExpenseFormBox
  },
  async mounted() {
    this.listBudgetType();
    this.listRules();
    if (this.$route.name === 'petty-cash-expense-new') {
      this.formType = 'new';
      this.id = null;
    } else {
      this.formType = 'update';
      this.id = this.$route.params.id;

      await this.getOneExpense({
        id: this.id,
        router
      });
    }
  },
  data() {
    return {
      id: null,
      formType: '',
      options: {
        categoryOptions: [{ value: null, text: 'Select Category' }],
        costCenterOptions: [
          { value: null, text: 'Select Centre' },
          { value: 'SALES', text: 'Sales' }
        ]
      }
    };
  },
  computed: {
    ...mapState('pettyCash', ['budgetType', 'budgetList']),
    title() {
      return this.formType === 'new' ? 'Add New Expense' : 'Update Expense';
    }
  },
  watch: {
    budgetList(newValue) {
      if (newValue !== null && newValue !== undefined) {
        this.pattyCashStoreGl = newValue[0]?.pattyCashStoreGl;
      }
    }
  },
  methods: {
    ...mapActions('pettyCash', ['listBudgetType', 'postExpense', 'getOneExpense', 'patchOneExpense', 'listRules']),

    onPostExpense({ expense }) {
      this.postExpense({ expense: expense, router, redirectUrl: '/petty-cash/expense' });
    },
    onPatchExpense({ expense }) {
      const id = this.id;
      this.patchOneExpense({
        id,
        expense,
        router,
        redirectUrl: '/petty-cash/expense'
      });
    }
  }
};
</script>
