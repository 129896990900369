<template>
  <div>
    <b-form
      @submit.stop.prevent="onSubmit"
      :class="{
        'p-3 box-shadow2 rounded': !isDarkMode,
        'dark-div p-3 box-shadow2 rounded': isDarkMode
      }"
    >
      <div>
        <b-row>
          <b-col sm="12" lg="6">
            <b-form-group id="input-companyName" label-for="input-companyName">
              <div class="mb-1">
                <template class="">
                  Company Name
                  <span class="text-danger">*</span>
                </template>
              </div>
              <b-form-select
                id="input-companyName"
                v-model="form.companyName"
                :state="$v.form.companyName.$dirty ? !$v.form.companyName.$error : null"
                :options="companyType"
                :class="{
                  'box-shadow3 bg-light text-dark': !isDarkMode,
                  'bg-dark text-light placeholder-light': isDarkMode
                }"
              ></b-form-select>
              <!-- :state="$v.form.selectedCompany.$dirty ? !$v.form.selectedCompany.$error : null" -->
              <b-form-invalid-feedback id="input-companyName">Please Select the Company Name</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="group-storeId" label-for="input-storeId">
              <div class="mb-1">
                <template class="">
                  Store Id
                  <span class="text-danger">*</span>
                </template>
              </div>
              <div class="mx-auto" id="group-storeId">
                <multiselect
                  :class="{
                    'mb-2 box-shadow3': !isDarkMode,
                    darkMode: isDarkMode
                  }"
                  id="input-storeId"
                  :options="storeIdOptions"
                  v-model="storeId"
                  placeholder="Search Store"
                >
                </multiselect>
                <b-form-input
                  id="input-storeId"
                  class="d-none box-shadow3"
                  type="text"
                  v-model="storeId"
                  :state="$v.storeId.$dirty ? !$v.storeId.$error : null"
                >
                </b-form-input>
                <b-form-invalid-feedback class="ml-2" id="input-storeId-invalid"
                  >Please Select the Store ID</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-row class="">
          <b-col sm="12" lg="6">
            <b-form-group id="group-pattyCashStoreGl" label-for="input-pattyCashStoreGl">
              <div class="mb-1">
                <template class="">
                  Petty Cash Store Gl
                  <span class="text-danger">*</span>
                </template>
              </div>
              <b-form-input
                type="text"
                placeholder="Enter Petty Cash Store Gl"
                id="input-pattyCashStoreGl"
                :state="$v.form.pattyCashStoreGl.$dirty ? !$v.form.pattyCashStoreGl.$error : null"
                v-model="form.pattyCashStoreGl"
                :class="{
                  'box-shadow3 bg-light text-dark': !isDarkMode,
                  'bg-dark text-light placeholder-light': isDarkMode
                }"
              >
              </b-form-input>
              <b-form-invalid-feedback class="ml-2" id="input-pattyCashStoreGl-invalid"
                >Please Provide PettyCash Store Gl</b-form-invalid-feedback
              >
            </b-form-group>
          </b-col>
          <b-col
            ><b-form-group id="group-costCenter" label-for="input-costCenter">
              <div class="mb-1">
                <template class="">
                  Cost Center
                  <span class="text-danger">*</span>
                </template>
              </div>
              <div class="input-group">
                <b-form-input
                  id="input-costCenter"
                  type="text"
                  min="0"
                  v-model="form.costCenter"
                  :class="{
                    'box-shadow3 bg-light text-dark': !isDarkMode,
                    'bg-dark text-light placeholder-light': isDarkMode
                  }"
                  placeholder="Enter Amount"
                  :state="$v.form.costCenter.$dirty ? !$v.form.costCenter.$error : null"
                />
                <b-form-invalid-feedback class="ml-2" id="input-costCenter-invalid"
                  >Please Provide Cost Center</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-row>
          <b-col>
            <b-form-group id="group-amount" label-for="input-amount">
              <div class="mb-1">
                <template class="">
                  Starting Amount
                  <span class="text-danger">*</span>
                </template>
              </div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text box-shadow3">&#8377;</span>
                </div>
                <b-form-input
                  id="input-amount"
                  type="number"
                  min="0"
                  pattern="[0-9]"
                  onkeyup="if(!Number.isInteger(Number(this.value))) this.value = '';"
                  v-model="form.amount"
                  :class="{
                    'form-control box-shadow3 bg-light text-dark': !isDarkMode,
                    'bg-dark text-light placeholder-light': isDarkMode
                  }"
                  placeholder="Enter Amount"
                  :state="$v.form.amount.$dirty ? !$v.form.amount.$error : null"
                />
                <b-form-invalid-feedback class="ml-2" id="input-amount-invalid"
                  >Please Provide Amount</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </div>

      <b-row class="mt-5">
        <b-col class="text-right">
          <b-button
            class="box-shadow2"
            type="submit"
            size="sm"
            :variant="isDarkMode ? 'outline-success' : 'success'"
            :disabled="loading"
          >
            <span class="spinner spinner-white" v-if="loading"></span>
            <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Save
          </b-button>
          <b-button
            class="ml-2 box-shadow2"
            size="sm"
            :variant="isDarkMode ? 'outline-warning' : 'warning'"
            :to="{ path: `/petty-cash/account` }"
          >
            <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" class="mr-1" />Back to list
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapState, mapActions } from 'vuex';
import _ from 'lodash';

import Multiselect from 'vue-multiselect';

const init = {
  mounted: el => el.init()
};

export default {
  name: 'PettyCashFormBox',
  components: {
    Multiselect
  },
  directives: {
    init
  },
  props: {
    formType: String
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: this.metaDescription
        }
      ]
    };
  },
  data() {
    return {
      loading: false,
      storeId: null,
      form: {
        companyName: null,
        pattyCashStoreGl: '',
        amount: '',
        costCenter: '',
        processDoneBy: 'Web'
      },
      storeIdOptions: [],
      companyType: [
        { value: null, text: 'Select Name' },
        { value: 'SRL', text: 'SRL' },
        { value: 'NBL', text: 'NBL' },
        { value: 'CESC', text: 'CESC' }
      ]
    };
  },
  validations() {
    const formValidation = {
      companyName: {
        required
      },
      pattyCashStoreGl: {
        required
      },
      amount: {
        required
      },
      costCenter: {
        required
      }
    };

    const storeId = {
      required
    };

    if (this.formType === 'new') {
      storeId.required = required;
      formValidation.companyName.required = required;
      formValidation.pattyCashStoreGl.required = required;
      formValidation.amount.required = required;
      formValidation.costCenter.required = required;
    }

    return { form: formValidation, storeId };
  },
  mounted() {
    this.getStoreByUser();
    this.$nextTick(async () => {
      if (this.formType === 'new' || this.formLoaded === false) {
        this.$v.$touch(); // Set initial validation
        this.$v.$reset(); // Reset $dirty
      }
    });
  },
  computed: {
    ...mapState('cstSubmission', ['store']),
    ...mapState('darkMode', ['isDarkMode']),
    metaDescription() {
      return this.formType === 'new' ? 'Add new Expense' : 'Update Expense';
    }
  },
  methods: {
    ...mapActions('cstSubmission', ['getStoreByUser']),
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      const newForm = {
        ...this.form,
        storeId: this.storeId
      };
      this.$emit('post', newForm);
    }
  },
  watch: {
    store(newValue) {
      if (newValue !== null && newValue !== undefined) {
        newValue.forEach(store => {
          this.storeIdOptions.push(store.storeid);
        });
      }
    },
    storeId(newValue) {
      const selectedStore = this.store.filter(item => item.storeid === newValue);
      this.form.pattyCashStoreGl = selectedStore[0].pattyCashStoreGl;
      this.form.costCenter = selectedStore[0].costCenter;
    }
  }
};
</script>

<style></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
