<template>
  <div class="page-class page-setting-list">
    <div class="page-title text-center">
      <h2>{{ title }}</h2>
      <h6 class="">{{ subTittle }}</h6>
    </div>
    <petty-cash-expense-list
      :columnsForExpense="columnsToShow"
      :rowsForExpense="expenseList"
      :rowsForTransaction="transactionList"
      :showAdd="true"
      :showToolbar="true"
      :pagination="pagination"
      :showPagination="true"
      emptyText="No Data Available"
      :filterOptions="filterOptions"
      :companyOptions="companyOptions"
      :searchItems="searchItems"
      :showDownload="true"
      :loading="loading"
      downloadText="Download List"
      addText="Create Expense"
      @add="onAdd"
      @expense="onExpense"
      @editExpense="onEditExpense"
      @approveExpense="onApproveExpense"
      @denyExpense="onDenyExpense"
      @download="onDownload"
      @patchExpense="onPatchExpense"
      @approveAll="onApproveAll"
      @editSomeRequests="onEditSome"
      @downloadSAP="onDownloadSAP"
    >
    </petty-cash-expense-list>
  </div>
</template>

<script>
import PettyCashExpenseList from '@/components/PettyCash/PettyCashExpenseList.vue';
import router from '@/router';
import moment from 'moment';
import Vue from 'vue';
import { NonAdminExpenseView, AdminExpenseView } from '../../../helper/pettyCashHelper';
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'ExpenseList',
  components: {
    PettyCashExpenseList
  },
  mounted() {
    this.getStoreByUser();
    this.listBudget({ query: {} });
    this.listCashSnapshot({
      query: {}
    });
    if (_.isEmpty(this.$route.query)) {
      this.listExpense({
        query: {
          ...this.$route.query
        }
      });
    } else {
      this.listExpense({ query: this.$route.query });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.listExpense({ query: to.query });
    next();
  },
  methods: {
    ...mapActions('pettyCash', [
      'listExpense',
      'listBudget',
      'patchOneExpense',
      'getSummaryForStore',
      'patchMakeAllExpenseLive',
      'patchEditExpenseList',
      'download',
      'pettyCashReport',
      'listCashSnapshot'
    ]),
    ...mapActions('cstSubmission', ['getStoreByUser']),
    onDownloadSAP() {
      if (
        this.$route.query.startdate === moment().format('YYYY-MM-DD') ||
        this.$route.query.enddate === moment().format('YYYY-MM-DD')
      ) {
        Vue.swal({
          title: 'Wrong Date Selected',
          icon: 'error',
          text: 'Please select date other than today',
          showCancelButton: false,
          confirmButtonText: 'Ok',
          background: this.isDarkMode ? '#464d54' : '#ffffff',
          customClass: {
            title: this.isDarkMode ? 'text-light' : 'text-dark',
            content: this.isDarkMode ? 'text-light' : 'text-dark',
            confirmButton: 'btn btn-primary',
            popup: 'col-md-3 animate__animated animate__fadeInDown',
            input: this.isDarkMode ? 'text-light' : 'text-dark'
          }
        });
        return null;
      }

      this.pettyCashReport({
        query: {
          startdate: this.$route.query.startdate,
          enddate: this.$route.query.enddate
        }
      });
    },
    onApproveAll(ids) {
      this.patchMakeAllExpenseLive({ list: ids });
    },
    onEditSome(ids) {
      this.patchEditExpenseList({
        list: ids,
        query: {
          ...this.$route.query
        }
      });
    },
    onPatchExpense({ expense }) {
      this.patchOneExpense({
        id: expense.id,
        expense: expense,
        router,
        redirectUrl: '',
        query: {
          ...this.$route.query
        }
      });
    },
    onDownload() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.download({ query: { ...this.$route.query, page_size: 99999, page: 1 } });
      } else {
        this.download({
          query: {
            ...this.$route.query,
            page_size: 99999,
            page: 1,
            startdate: moment().startOf('month').format('YYYY-MM-DD'),
            enddate: moment().endOf('month').format('YYYY-MM-DD')
          }
        });
      }
    },
    onAdd() {
      router.push('/petty-cash/expense/new');
    },
    onEditExpense({ row }) {
      router.push(`/petty-cash/expense/${row?.id}`);
    },
    onApproveExpense({ row }) {
      const id = row.id;
      let approvalSystem = {};
      if (this.ifAdmin) {
        approvalSystem = {
          isApproved: row.isApproved,
          isApprovedByHO: true,
          status: 'APPROVED',
          approvedBy: row.approvedBy,
          approvedByHO: this.user?.id
        };
      }
      if (this.isSmAsmRsm) {
        const storeCurrentBalance = this.cashSnapshotList.filter(store => store.storeId === row.storeId)[0]
          .currentBalance;
        if (row.amount > storeCurrentBalance) {
          Vue.swal({
            title: 'Validation Error',
            text: `${row.storeId} has low Cash in Hand, Can't approve the request`,
            type: 'error',
            background: this.isDarkMode ? '#464d54' : '#ffffff',
            customClass: {
              title: this.isDarkMode ? 'text-light' : 'text-dark',
              content: this.isDarkMode ? 'text-light' : 'text-dark'
            }
          });
          return null;
        }
        approvalSystem = {
          isApproved: true,
          status: 'PENDING',
          approvedBy: this.user?.id
        };
      }

      let approvedExpense = {
        ...approvalSystem,
        amount: row.amount,
        bulkReciptImage: row.bulkReciptImage,
        storeId: row.storeId,
        glName: row.glName,
        companyCode: row.companyCode,
        recipient: row.recipient,
        costCenter: row.costCenter,
        text: row.text,
        isEdited: row.isEdited,
        companyName: row.companyName,
        pattyCashStoreGl: row.pattyCashStoreGl,
        expenseGlCode: row.expenseGlCode,
        postingDate: row.postingDate,
        billDate: moment(row.billDate, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        referenceNumber: row.referenceNumber,
        createdBy: row.createdBy,
        processDoneBy: 'Web'
      };

      this.patchOneExpense({
        id,
        expense: approvedExpense,
        router,
        redirectUrl: '',
        query: {
          ...this.$route.query
        }
      });
    },
    onDenyExpense({ row }) {
      const id = row.id;
      let rejectSystem = {};
      if (this.ifAdmin) {
        rejectSystem = {
          isApproved: row.isApproved,
          isApprovedByHO: false,
          isRejectedByHO: true,
          status: 'REJECTED',
          approvedBy: row.approvedBy,
          approvedByHO: this.user?.id
        };
      }
      if (this.isSmAsmRsm) {
        rejectSystem = {
          isApproved: false,
          status: 'REJECTED',
          approvedBy: this.user?.id
        };
      }

      let rejectExpense = {
        ...rejectSystem,
        amount: row.amount,
        bulkReciptImage: row.bulkReciptImage,
        statusRemarks: row.statusRejection,
        storeId: row.storeId,
        glName: row.glName,
        isEdited: row.isEdited,
        companyCode: row.companyCode,
        companyName: row.companyName,
        recipient: row.recipient,
        costCenter: row.costCenter,
        pattyCashStoreGl: row.pattyCashStoreGl,
        expenseGlCode: row.expenseGlCode,
        text: row.text,
        billDate: moment(row.billDate, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        referenceNumber: row.referenceNumber,
        createdBy: row.createdBy,
        processDoneBy: 'Web'
      };
      this.patchOneExpense({ id, expense: rejectExpense, router, redirectUrl: '' });
    },
    onExpense() {
      router.push('/petty-cash/expense/new');
    }
  },
  computed: {
    ...mapState('pettyCash', [
      'loading',
      'baseUrl',
      'pagination',
      'expenseList',
      'transactionList',
      'budgetList',
      'downloadExpenseList',
      'pettyCashReportList',
      'cashSnapshotList'
    ]),
    ...mapState('auth', ['user']),
    ...mapState('cstSubmission', ['store']),
    ...mapState('darkMode', ['isDarkMode']),
    ...mapGetters('pettyCash', ['isSmAsmRsm', 'ifAdmin', 'ifAccountant']),
    columnsToShow() {
      if (this.ifAdmin) {
        return AdminExpenseView;
      }
      return NonAdminExpenseView;
    }
  },
  watch: {
    pettyCashReportList(newValue) {
      if (newValue.length > 0) {
        let csv =
          'Serial No ,Company Code ,Document Date ,Posting Date ,Doc Type ,Currency ,Reference No ,Docu Head Text ,Posting Key ,Account No ,Spl Gl Indicator ,Amount ,Due Date ,Section Code ,Business Place ,Assignment Field ,Text ,Profit Center ,Cost Center ,Site,Document Number,Rev Message \n';
        newValue.forEach(row => {
          Object.values(row).forEach((val, index) => {
            if ([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 24, 25].includes(index)) {
              const sanitizedVal = String(val).replace(/[^a-zA-Z0-9-]+/g, ' ');
              csv += `"${sanitizedVal}"`;
              csv += ',';
            }
          });
          csv += '\n';
        });
        const startDate = moment(this.$route.query.startdate).format('DD-MMMM');
        const endDate = moment(this.$route.query.enddate).format('DD-MMMM');
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = `SAP Report ${startDate} to ${endDate}.csv`;
        anchor.click();
      }
    },
    downloadExpenseList(newValue) {
      if (newValue.length > 0) {
        let csv =
          'Sr. No,Store Id , Gl Name ,Cost Center ,Bill Date ,Document Date ,Amount ,Text ,Bill Photo ,Status ,Recipient\n';
        newValue.forEach(row => {
          Object.values(row).forEach((val, index) => {
            if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].includes(index)) {
              csv += `"${val}"`;
              csv += ',';
            }
          });
          csv += '\n';
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Expense Request.csv';
        anchor.click();
      }
    },
    budgetList(newValue) {
      if (newValue !== null && newValue !== undefined) {
        this.pattyCashStoreGl = newValue[0]?.pattyCashStoreGl;
      }
    },
    store(newValue) {
      if (newValue.length > 0) {
        this.storeId = newValue[0]?.storeid;
      }
    },
    storeId(newValue, oldValue) {
      if (newValue !== oldValue && this.ifAccountant) {
        this.getSummaryForStore({ id: newValue });
      }
    }
  },
  data() {
    return {
      pattyCashStoreGl: '',
      searchItems: ['Store Id', 'Gl Name', 'Expense Gl', 'Company Name'],
      // 'Store GL', 'Expense Gl',
      filterOptions: [
        { value: null, text: 'Select Filter' },
        { value: 'CREATED', text: 'CREATED' },
        { value: 'PENDING', text: 'PENDING' },
        { value: 'APPROVED', text: 'APPROVED' },
        { value: 'REJECTED', text: 'REJECTED' },
        { value: 'CANCELLED', text: 'AUTO-CANCELLED' }
      ],
      companyOptions: [
        { value: null, text: 'Select Company' },
        { value: 'NBL', text: 'NBL' },
        { value: 'SRL', text: 'SRL' }
      ],
      title: 'Petty Cash - Expense',
      subTittle: 'Distribute and manage cash to stores',
      storeId: ''
    };
  }
};
</script>
