<template>
  <div class="page-class d-flex flex-column">
    <!-- Search and month filter -->
    <b-row v-if="showToolbar && !ifAdmin && !ifSpencerAdmin" class="table-top-wrapper mb-2 mt-2 mx-0">
      <b-col sm="12" lg="3">
        <b-input-group data-v-step="0" class="box-shadow2 mb-2">
          <b-form-input
            :class="{
              'bg-light text-dark': !isDarkMode,
              'bg-dark text-light placeholder-light': isDarkMode
            }"
            v-model="searchTerm"
            @keydown.native="search"
            placeholder="Search..."
          ></b-form-input>
          <template #append v-if="searchItems">
            <b-dropdown
              :text="selectedSearchVariable"
              :variant="isDarkMode ? 'primary' : 'success'"
              :menu-class="isDarkMode ? 'bg-dark box-shadow2' : 'box-shadow2'"
            >
              <b-dropdown-item
                v-for="item in searchItems"
                :key="item"
                @click="selectedSearchVariable = item"
                :link-class="isDarkMode ? 'text-light bg-dark' : ''"
                >{{ item }}</b-dropdown-item
              >
            </b-dropdown>
          </template>
        </b-input-group>
      </b-col>
      <b-col sm="12" lg="3">
        <b-form-select
          v-if="!ifAdmin"
          data-v-step="1"
          v-model="filterSelected"
          :options="filterOptions"
          :class="{
            'box-shadow2 ml-2 mb-2  bg-light text-dark': !isDarkMode,
            'box-shadow2 ml-2 mb-2 bg-dark text-light': isDarkMode
          }"
        ></b-form-select>
      </b-col>
      <b-col sm class="px-0">
        <b-form data-v-step="2" inline class="float-right">
          <div class="ml-2 mb-2">
            <b-form-select
              :class="{
                'box-shadow2  bg-light text-dark': !isDarkMode,
                'box-shadow2 bg-dark text-light': isDarkMode
              }"
              id="monthPicker"
              :options="months"
              v-model="selectedMonth"
              @change="updateSelectedMonth"
            >
            </b-form-select>
            <b-form-select
              :class="{
                'box-shadow2  bg-light text-dark': !isDarkMode,
                'box-shadow2 bg-dark text-light': isDarkMode
              }"
              id="yearPicker"
              :options="years"
              v-model="selectedYear"
              @change="updateSelectedMonth"
            >
            </b-form-select>
          </div>
          <b-button
            :class="{
              'box-shadow2 btn btn-success ml-2 mb-2': !isDarkMode,
              'btn btn-primary text-light ml-2 mb-2': isDarkMode
            }"
            @click="applyDate"
            >Apply</b-button
          >
          <b-button
            :variant="isDarkMode ? 'outline-light' : 'outline-secondary'"
            class="ml-2 mb-2 box-shadow2"
            @click="clearDate"
            >Clear</b-button
          >
        </b-form>
      </b-col>
    </b-row>

    <div class="page-center">
      <div class="page_custom_1350 px-20">
        <!--loading  -->
        <!-- <div v-if="loading" class="">
          <div class="text-center">
            <span :class="{ 'spinner spinner-black': !isDarkMode, 'spinner spinner-white': isDarkMode }"></span>
            <span class="mx-2"> Processing Please wait ....</span>
          </div>
        </div> -->

        <!-- Filter block -->

        <!-- Button block -->
        <b-row class="table-bottom-wrapper m-2 mx-0">
          <b-col :cols="12">
            <b-button
              data-v-step="3"
              v-if="showAdd && ifAdmin"
              size="sm"
              :class="{
                'box-shadow2 btn btn-success mb-2 mr-2': !isDarkMode,
                'btn btn-primary text-light mb-2 mr-2': isDarkMode
              }"
              @click="clickCreateNew()"
            >
              <font-awesome-icon :icon="['fas', 'plus']" class="mr-1" />{{ createNewText }}
            </b-button>
            <b-button
              v-if="showDownload && (ifAdmin || ifSpencerAdmin)"
              size="sm"
              :class="{
                'box-shadow2 btn btn-success mb-2 mr-2': !isDarkMode,
                'btn btn-primary text-light mb-2 mr-2': isDarkMode
              }"
              @click="clickDownload"
            >
              <font-awesome-icon :icon="['fas', 'download']" class="mr-1" />{{ downloadText }}
            </b-button>
            <b-button
              v-if="showDownload && ifAdmin"
              size="sm"
              :class="{
                'box-shadow2 btn btn-success ml-2 mb-2 mr-2': !isDarkMode,
                'btn btn-primary text-light ml-2 mb-2 mr-2': isDarkMode
              }"
              @click="clickCreateAccounts"
            >
              <font-awesome-icon :icon="['fas', 'download']" class="mr-1" />Create Bulk Accounts
            </b-button>
          </b-col>
        </b-row>

        <!-- Cash Accounts List block -->
        <div
          :class="{
            'p-3 my-1 rounded': !isDarkMode,
            ' p-3 my-1 bg-dark rounded text-light': isDarkMode
          }"
        >
          <b-row class="d-flex justify-content-between my-2 align-items-center">
            <b-col class="text-center">
              <h5>Store Cash Account</h5>
            </b-col>
            <b-col v-if="ifAdmin" class="d-flex justify-content-end">
              <b-button
                @click="clickBulkCashUpload()"
                :class="{
                  'box-shadow2 btn btn-light mx-4': !isDarkMode,
                  'btn btn-dark border mx-4': isDarkMode
                }"
                ><font-awesome-icon :icon="['fas', 'file-excel']" class="mr-1" style="font-size: 20px" />Upload Cash
              </b-button>
            </b-col>
          </b-row>
          <div
            :class="{
              'box-shadow2': !isDarkMode,
              '': isDarkMode
            }"
          >
            <div
              :class="{
                'table-container my-3 ': !isDarkMode,
                'border-top table-container my-3 ': isDarkMode
              }"
            >
              <div
                :class="{
                  'table-row table-row-header text-center': !isDarkMode,
                  'table-row-dark text-center dark-div border-top': isDarkMode
                }"
              >
                <div
                  v-for="column in columns"
                  :key="column.id"
                  :class="{ 'table-column bg-light': !isDarkMode, 'table-column-dark': isDarkMode }"
                  :style="columnStyle(column)"
                >
                  {{ column.headerText }}
                </div>
              </div>
              <b-overlay v-if="loading" show :variant="isDarkMode ? 'outline-light' : 'outline-secondary'">
                <div
                  :class="{ 'table-row table-row-empty': !isDarkMode, 'table-row-dark table-row-empty': isDarkMode }"
                >
                  <div
                    :class="{
                      'table-column table-column-full text-center': !isDarkMode,
                      'table-column-dark table-column-full text-center': isDarkMode
                    }"
                  >
                  </div>
                </div>
              </b-overlay>
              <div
                :class="{
                  'table-row text-center ': !isDarkMode,
                  'table-row-dark text-center': isDarkMode
                }"
                v-for="row in rows"
                :key="row.id"
              >
                <div
                  v-for="column in columns"
                  :key="column.id"
                  :class="{ 'table-column': !isDarkMode, 'table-column-dark': isDarkMode }"
                  :style="columnStyle(column)"
                >
                  <slot v-if="column.slotKey" :name="column.slotKey" v-bind:row="row"></slot>
                  <div v-if="column.htmlKey" v-html="row[column.htmlKey]"></div>
                  <div v-if="column.urlKey"
                    ><a :href="row[column.urlKey]" target="_blank">{{ row[column.urlKey] }}</a></div
                  >
                  <span
                    v-if="
                      column.textKey &&
                      column.textKey != 'startingBalance' &&
                      column.textKey !== 'currentBalance' &&
                      column.textKey !== 'monthlyOpeningBalance'
                    "
                    class="span-text"
                    >{{ row[column.textKey] }}</span
                  >
                  <span
                    v-if="
                      column.textKey === 'startingBalance' ||
                      column.textKey === 'currentBalance' ||
                      column.textKey === 'monthlyOpeningBalance'
                    "
                    class="span-text"
                    >&#x20B9; {{ row[column.textKey] }}</span
                  >

                  <div v-if="column.type === 'functions'" class="text-center">
                    <b-button-group class="box-shadow2" vertical>
                      <b-button
                        data-v-step="5"
                        :disabled="row.isMakeLive === true ? true : false"
                        v-if="column.functions.edit && (ifAdmin || ifSpencerAdmin)"
                        size="sm"
                        :variant="isDarkMode ? 'outline-light' : 'outline-secondary'"
                        @click="onSeeAccount(row)"
                      >
                        <font-awesome-icon :icon="['fas', 'coins']" class="mr-1" /> View Account
                      </b-button>
                      <b-button
                        data-v-step="5"
                        :disabled="loading || row.isMakeLive === true ? true : false"
                        v-if="column.functions.edit && ifAdmin"
                        size="sm"
                        :class="{
                          '': !isDarkMode,
                          'text-white': isDarkMode
                        }"
                        variant="outline-success"
                        @click="onAddCash(row)"
                      >
                        <font-awesome-icon :icon="['fas', 'plus']" class="mr-1" /> Add Cash
                      </b-button>
                    </b-button-group>
                  </div>
                </div>
              </div>
              <div v-if="!loading && rows.length === 0" class="table-row table-row-empty">
                <div class="table-column table-column-full text-center">{{ emptyText }}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- Cash transaction list flow -->
        <div v-if="!ifAdmin && !ifSpencerAdmin" class="custom-shadow p-3 my-4 bg-white rounded">
          <b-row class="d-flex justify-content-between my-2 align-items-center">
            <b-col class="">
              <h5>Transactions</h5>
            </b-col>
          </b-row>
          <div>
            <div class="table-container my-3">
              <div
                :class="{
                  'table-row table-row-header text-center': !isDarkMode,
                  'table-row-dark text-center dark-div': isDarkMode
                }"
              >
                <div
                  v-for="column in columnsForTransaction"
                  :key="column.id"
                  :class="{ 'table-column bg-light': !isDarkMode, 'table-column-dark': isDarkMode }"
                  :style="columnStyle(column)"
                >
                  {{ column.headerText }}
                </div>
              </div>

              <div class="table-row text-center" v-for="row in transactionRows" :key="row.id">
                <div
                  v-for="column in columnsForTransaction"
                  :key="column.id"
                  class="table-column"
                  :style="columnStyle(column)"
                >
                  <slot v-if="column.slotKey" :name="column.slotKey" v-bind:row="row"></slot>
                  <div v-if="column.htmlKey" v-html="row[column.htmlKey]"></div>
                  <div v-if="column.urlKey"
                    ><a :href="row[column.urlKey]" target="_blank">{{ row[column.urlKey] }}</a></div
                  >
                  <span
                    v-if="
                      column.textKey &&
                      column.textKey != 'amount' &&
                      column.textKey !== 'balanceLeft' &&
                      column.textKey !== 'oldBalance'
                    "
                    class="span-text"
                    >{{ row[column.textKey] }}</span
                  >
                  <span
                    v-if="
                      column.textKey === 'balanceLeft' || column.textKey === 'oldBalance' || column.textKey === 'amount'
                    "
                    class="span-text"
                    >&#x20B9; {{ row[column.textKey] }}</span
                  >
                </div>
              </div>
              <div v-if="!loading && transactionRows.length === 0" class="table-row table-row-empty">
                <div class="table-column table-column-full text-center">{{ emptyText }}</div>
              </div>
            </div>
            <b-button v-if="ifAdmin" class="btn btn-light class='box-shadow2'">
              <span style="font-weight: 800">+</span> Add
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <!-- Pagination -->
    <div class="">
      <b-col :cols="4" class="px-0 text-right float-right" v-if="showPagination">
        <b-pagination-nav
          data-v-step="7"
          v-if="transactionRows.length && !ifAdmin && !ifSpencerAdmin"
          v-model="currentPage"
          :link-gen="linkGen"
          :number-of-pages="totalNumberOfPage"
          use-router
          no-page-detect
          align="right"
          :class="{ '': !isDarkMode, 'dark-nav': isDarkMode }"
        ></b-pagination-nav>
      </b-col>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import csv from 'csvtojson';
import router from '@/router';
import _ from 'lodash';
import Vue from 'vue';
import moment from 'moment';
import { Months } from '../../helper/pettyCashHelper';
export default {
  name: 'PettyCashInHand',
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: this.metaDescription
        }
      ]
    };
  },
  props: {
    columns: Array,
    columnsForTransaction: Array,
    rows: Array,
    transactionRows: Array,
    showAdd: Boolean,
    filterOptions: Array,
    showToolbar: Boolean,
    searchItems: Array,
    showDownload: Boolean,
    createNewText: String,
    downloadText: String,
    addText: String,
    loading: Boolean,
    emptyText: String,
    showPagination: Boolean,
    pagination: Object
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('darkMode', ['isDarkMode']),
    ...mapState('pettyCash', ['cashSnapshotList']),
    ...mapGetters('pettyCash', ['ifAdmin', 'ifSpencerAdmin']),
    metaDescription() {
      return 'Petty cash box';
    },
    storeWithAccount() {
      return this.cashSnapshotList.map(item => item.storeId);
    },
    currentPage() {
      if (this.pagination.page) {
        return this.pagination.page;
      }
      return 1;
    },
    totalNumberOfPage() {
      if (this.pagination) {
        if (this.pagination.total_rows && this.pagination.page_size) {
          return Math.ceil(this.pagination.total_rows / this.pagination.page_size);
        }
      }
      return 0;
    },
    totalCount() {
      if (this.pagination) {
        return this.pagination.total_rows;
      }
      return 0;
    }
  },
  watch: {
    filterSelected(newValue, oldValue) {
      if (newValue !== oldValue && this.$route.query.filter !== newValue) {
        if (!newValue) {
          const query = { ...this.$route.query };
          delete query.filter;
          this.$router.push({ query });
        } else {
          this.$router.push({ query: { ...this.$route.query, filter: newValue } });
        }
      }
    },
    searchTerm(newValue) {
      if (newValue === '' && Object.prototype.hasOwnProperty.call(this.$route.query, 'search')) {
        const query = { ...this.$route.query };
        delete query.search;
        this.$router.push({ query });
      }
    }
  },
  data() {
    return {
      filterSelected: null,
      startDateValue: moment().subtract(1, 'month').format('YYYY-MM-DD'),
      endDateValue: moment().format('YYYY-MM-DD'),
      searchTerm: null,
      selectedMonth: moment().format('MMMM').toUpperCase(),
      selectedYear: new Date().getFullYear(), // Initial selection: current year
      selectedSearchVariable: this.searchItems && this.searchItems[0],
      months: Months,
      years: []
    };
  },
  mounted() {
    this.getStoreByUser();
    const currentYear = new Date().getFullYear();
    for (let year = currentYear - 2; year <= currentYear + 2; year++) {
      this.years.push(year);
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'search')) {
      this.searchTerm = this.$route.query.search;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'searchkey')) {
      this.selectedSearchVariable = this.$route.query.searchkey;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
      this.startDateValue = this.$route.query.startdate;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'enddate')) {
      this.endDateValue = this.$route.query.enddate;
    }
  },
  methods: {
    ...mapActions('cstSubmission', ['getStoreByUser']),
    ...mapActions('pettyCash', ['bulkCashUpload', 'bulkCreateAccount']),
    onSeeAccount(row) {
      this.$emit('account', { row });
    },
    updateSelectedMonth() {
      const inputDate = moment(`${this.selectedMonth} ${this.selectedYear} ${moment().format('YYYY')}`, 'MMMM YYYY');
      this.startDateValue = inputDate.startOf('month').format('YYYY-MM-DD');
      this.endDateValue = inputDate.endOf('month').format('YYYY-MM-DD');
    },
    createCsvForCash() {
      const csv = `Store Id,Petty Cash Store GL ,Amount\n`;
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'Cash.csv';
      anchor.click();
    },
    createCsvForAccount() {
      const csv = `Company Name ,Store Id ,Petty Cash Store GL ,Amount\n`;
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'Create Cash Account.csv';
      anchor.click();
    },

    async clickBulkCashUpload() {
      await Vue.swal({
        title: 'Upload Cash',
        input: 'file',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        denyButtonText: 'Download Sample',
        denyButtonColor: '#3a4',
        showCancelButton: true,
        showDenyButton: true,
        cancelButtonText: 'Close',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        }
      }).then(result => {
        if (result.isDenied) {
          this.createCsvForCash();
        }
        if (result.value.name.includes('.csv')) {
          const reader = new FileReader();
          reader.onload = async () => {
            csv({
              noheader: true,
              output: 'csv'
            })
              .fromString(reader.result)
              .then(csvRow => {
                const list = {
                  cashList: csvRow.slice(1, csvRow.length).map(item => {
                    if (item[0] != '') {
                      const uploadingStore = item[0].toUpperCase();
                      const databaseStore = this.storeWithAccount.map(store => store);
                      if (!databaseStore.includes(uploadingStore)) {
                        Vue.swal({
                          title: 'Please Provide correct Store Id',
                          text: `Store id ${uploadingStore} Has no account`,
                          type: 'error',
                          confirmButtonText: 'Ok',
                          background: this.isDarkMode ? '#464d54' : '#ffffff',
                          customClass: {
                            title: this.isDarkMode ? 'text-light' : 'text-dark',
                            content: this.isDarkMode ? 'text-light' : 'text-dark'
                          }
                        });
                        return null;
                      }
                    }
                    if (item[1] === '') {
                      Vue.swal({
                        title: 'Please Provide correct Petty Cash Store Gl ',
                        text: `Petty Cash Store Gl Needed`,
                        type: 'error',
                        confirmButtonText: 'Ok',
                        background: this.isDarkMode ? '#464d54' : '#ffffff',
                        customClass: {
                          title: this.isDarkMode ? 'text-light' : 'text-dark',
                          content: this.isDarkMode ? 'text-light' : 'text-dark'
                        }
                      });
                      return null;
                    }

                    if (isNaN(Number(item[2]))) {
                      Vue.swal({
                        title: 'Wrong Amount entered in Amount Field',
                        icon: 'error',
                        text: `Please provide a valid amount for ${item[2]}`,
                        type: 'error',
                        confirmButtonText: 'Ok',
                        background: this.isDarkMode ? '#464d54' : '#ffffff',
                        customClass: {
                          title: this.isDarkMode ? 'text-light' : 'text-dark',
                          content: this.isDarkMode ? 'text-light' : 'text-dark'
                        }
                      });
                      return null;
                    }

                    return {
                      storeId: item[0].toUpperCase(),
                      pattyCashStoreGl: item[1],
                      amount: Number(item[2])
                    };
                  })
                };
                if (list.cashList.every(item => !isNaN(item?.amount) && item.amount !== null)) {
                  this.bulkCashUpload({
                    list: list,
                    router: router,
                    redirectUrl: '/petty-cash'
                  });
                } else return null;
              });
          };
          reader.readAsBinaryString(result.value);
        } else {
          Vue.swal({
            title: 'Error',
            icon: 'error',
            text: 'Please provide a valid File',
            background: this.isDarkMode ? '#464d54' : '#ffffff',
            customClass: {
              title: this.isDarkMode ? 'text-light' : 'text-dark',
              content: this.isDarkMode ? 'text-light' : 'text-dark'
            }
          });
          return null;
        }
      });
    },
    async onAddCash(row) {
      await Vue.swal({
        title: 'Enter Amount',
        input: 'number',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Add Amount',
        denyButtonColor: '#3a4',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        }
      }).then(result => {
        if (result.isDenied) {
          return null;
        }
        if (result.value) {
          if (Number(result.value) >= 0) {
            const addedAmount = result.value;
            const processDoneBy = 'Web';
            this.$emit('addCash', { ...row, addedAmount, processDoneBy });
          } else {
            Vue.swal({
              title: 'Error',
              icon: 'error',
              text: 'Please provide a valid amount',
              background: this.isDarkMode ? '#464d54' : '#ffffff',
              customClass: {
                title: this.isDarkMode ? 'text-light' : 'text-dark',
                content: this.isDarkMode ? 'text-light' : 'text-dark'
              }
            });
            return null;
          }
        }
      });
    },
    columnStyle(column) {
      return { width: column.width };
    },
    clickCreateNew() {
      this.$emit('new');
    },
    clickDownload() {
      this.$emit('download');
    },
    async clickCreateAccounts() {
      await Vue.swal({
        title: 'Upload Account',
        input: 'file',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        denyButtonText: 'Download Sample',
        denyButtonColor: '#3a4',
        showCancelButton: true,
        showDenyButton: true,
        cancelButtonText: 'Close',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        }
      }).then(result => {
        if (result.isDenied) {
          this.createCsvForAccount();
        }
        if (result.value) {
          const reader = new FileReader();
          reader.onload = async () => {
            csv({
              noheader: true,
              output: 'csv'
            })
              .fromString(reader.result)
              .then(csvRow => {
                const list = {
                  accounts: csvRow.slice(1, csvRow.length).map(item => {
                    return {
                      companyName: item[0],
                      storeId: item[1].toUpperCase(),
                      pattyCashStoreGl: item[2],
                      startingBalance: Number(item[3])
                    };
                  })
                };
                if (list.accounts.every(item => item !== null)) {
                  this.bulkCreateAccount({
                    list: list,
                    router: router,
                    redirectUrl: '/petty-cash'
                  });
                } else return null;
              });
          };
          reader.readAsBinaryString(result.value);
        }
      });
    },
    clickBudget() {
      this.$emit('budget', {});
    },

    search(event) {
      if (event.key === 'Enter') {
        if (this.searchTerm) {
          if (this.$route.query.searchTerm !== this.searchTerm) {
            if (this.selectedSearchVariable) {
              this.$router.push({
                query: { ...this.$route.query, search: this.searchTerm, searchkey: this.selectedSearchVariable }
              });
            } else {
              this.$router.push({ query: { ...this.$route.query, search: this.searchTerm } });
            }
          }
        } else {
          const query = { ...this.$route.query };
          delete query.filter;
          this.$router.push({ query });
        }
      }
    },
    applyDate() {
      if (this.selectedMonth) {
        this.$router.push({
          query: {
            ...this.$route.query,
            startdate: moment(this.startDateValue).format('YYYY-MM-DD'),
            enddate: moment(this.endDateValue).format('YYYY-MM-DD')
          }
        });
      }
    },
    clearDate() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        const query = { ...this.$route.query };
        delete query.startdate;
        delete query.enddate;
        this.$router.push({ query });
      }
      this.startDateValue = null;
      this.endDateValue = null;
    },
    linkGen(pageNum) {
      return { path: this.baseUrl, query: { ...this.$route.query, page: pageNum } };
    }
  }
};
</script>

<style src="../../assets/css/Table.css"></style>
