var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form',{class:{
      'p-3 box-shadow2 rounded': !_vm.isDarkMode,
      'dark-div p-3 box-shadow2 rounded': _vm.isDarkMode
    },on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',[_c('b-row',{staticClass:"mx-3"},[_c('b-col',[_c('b-form-group',{attrs:{"id":"group-storeId","label-for":"input-storeId"}},[_c('div',{staticClass:"mx-2 my-1"},[[_vm._v(" Select Store Id "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]],2),_c('div',{staticClass:"mx-auto",attrs:{"id":"group-storeId"}},[_c('b-form-select',{class:{
                  'box-shadow3 bg-light text-dark': !_vm.isDarkMode,
                  'bg-dark text-light placeholder-light': _vm.isDarkMode
                },attrs:{"id":"input-storeId","disabled":_vm.ifAdmin,"options":_vm.storeOptions,"state":_vm.$v.storeId.$dirty ? !_vm.$v.storeId.$error : null},model:{value:(_vm.storeId),callback:function ($$v) {_vm.storeId=$$v},expression:"storeId"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-storeId-invalid"}},[_vm._v("Please Select the Store ID")])],1)])],1)],1)],1),_c('div',[_c('b-row',{staticClass:"mx-3"},[_c('b-col',{attrs:{"sm":"12","lg":"6"}},[_c('b-form-group',{attrs:{"id":"group-companyName","label-for":"input-companyName"}},[_c('div',{staticClass:"mx-2 my-1"},[[_vm._v(" Company Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]],2),_c('div',{staticClass:"mx-auto",attrs:{"id":"group-companyName"}},[_c('b-form-input',{class:{
                  'box-shadow3 bg-light text-dark': !_vm.isDarkMode,
                  'bg-dark text-light placeholder-light': _vm.isDarkMode
                },attrs:{"id":"input-companyName","disabled":"","placeholder":"Enter Company Name","state":_vm.$v.form.companyName.$dirty ? !_vm.$v.form.companyName.$error : null},model:{value:(_vm.form.companyName),callback:function ($$v) {_vm.$set(_vm.form, "companyName", $$v)},expression:"form.companyName"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-companyName-invalid"}},[_vm._v("Please Enter Company Name")])],1)])],1),_c('b-col',[_c('b-form-group',{attrs:{"id":"group-companyCode","label-for":"input-companyCode"}},[_c('div',{staticClass:"mx-2 my-1"},[[_vm._v(" Company Code "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]],2),_c('b-form-input',{class:{
                'mb-2 box-shadow3 bg-light text-dark': !_vm.isDarkMode,
                'mb-2 bg-dark text-light placeholder-light': _vm.isDarkMode
              },attrs:{"id":"input-companyCode","disabled":"","placeholder":"Enter Company Code","state":_vm.$v.form.companyCode.$dirty ? !_vm.$v.form.companyCode.$error : null},model:{value:(_vm.form.companyCode),callback:function ($$v) {_vm.$set(_vm.form, "companyCode", $$v)},expression:"form.companyCode"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-companyCode-invalid"}},[_vm._v("Please Provide Company Code")])],1)],1)],1)],1),_c('div',[_c('b-row',{staticClass:"mx-3"},[_c('b-col',{attrs:{"sm":"12","lg":"6"}},[_c('b-form-group',{attrs:{"id":"group-costCenter","label-for":"input-costCenter"}},[_c('div',{staticClass:"mx-2 my-1"},[[_vm._v(" Cost Center "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]],2),_c('b-form-input',{class:{
                'mb-2 box-shadow3 bg-light text-dark': !_vm.isDarkMode,
                'mb-2 bg-dark text-light placeholder-light': _vm.isDarkMode
              },attrs:{"id":"input-costCenter","placeholder":"Enter cost center","state":_vm.$v.form.selectedCostCenter.$dirty ? !_vm.$v.form.selectedCostCenter.$error : null},model:{value:(_vm.form.selectedCostCenter),callback:function ($$v) {_vm.$set(_vm.form, "selectedCostCenter", $$v)},expression:"form.selectedCostCenter"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-costCenter-invalid"}},[_vm._v("Please Select Cost Center")])],1)],1),_c('b-col',{},[_c('b-form-group',{attrs:{"id":"group-costCenter","label-for":"input-costCenter"}},[_c('div',{staticClass:"mx-2 my-1"},[[_vm._v(" Petty-Cash Gl "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]],2),_c('b-form-input',{class:{
                'mb-2 box-shadow3 bg-light text-dark': !_vm.isDarkMode,
                'mb-2 bg-dark text-light placeholder-light': _vm.isDarkMode
              },attrs:{"id":"input-costCenter","disabled":"","placeholder":"Enter Petty Cash Store Gl","state":_vm.$v.form.pattyCashStoreGl.$dirty ? !_vm.$v.form.pattyCashStoreGl.$error : null},model:{value:(_vm.form.pattyCashStoreGl),callback:function ($$v) {_vm.$set(_vm.form, "pattyCashStoreGl", $$v)},expression:"form.pattyCashStoreGl"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-costCenter-invalid"}},[_vm._v("Please Select Petty-Cash Gl")])],1)],1)],1)],1),_c('div',[_c('b-row',{staticClass:"mx-3 mt-3"},[_c('b-col',{attrs:{"sm":"12","lg":"6"}},[_c('b-form-group',{attrs:{"id":"group-glName","label-for":"input-glName"}},[[_vm._v(" Gl Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])],_c('multiselect',{class:{
                'mb-2 box-shadow3': !_vm.isDarkMode,
                darkMode: _vm.isDarkMode
              },attrs:{"id":"input-glName","options":_vm.categoryOptions,"allow-empty":false,"placeholder":"Search Gl Name"},model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}}),_c('b-form-input',{staticClass:"d-none",attrs:{"id":"input-glName","state":_vm.$v.selectedCategory.$dirty ? !_vm.$v.selectedCategory.$error : null},model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}}),_c('b-form-invalid-feedback',{staticClass:"ml-2",attrs:{"id":"input-glName-invalid"}},[_vm._v("Please Provide Gl Name")])],2)],1),_c('b-col',[_c('b-form-group',{attrs:{"id":"group-expense","label-for":"input-expense"}},[_c('div',[[_vm._v(" Expense Gl Code "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]],2),_c('multiselect',{class:{
                'mb-2 box-shadow3': !_vm.isDarkMode,
                darkMode: _vm.isDarkMode
              },attrs:{"id":"input-expense","options":_vm.expenseOptions,"allow-empty":false,"placeholder":"Search Expense GL Code"},model:{value:(_vm.expenseGlCode),callback:function ($$v) {_vm.expenseGlCode=$$v},expression:"expenseGlCode"}}),_c('b-form-input',{staticClass:"d-none box-shadow3",attrs:{"id":"input-expense","state":_vm.$v.expenseGlCode.$dirty ? !_vm.$v.expenseGlCode.$error : null},model:{value:(_vm.expenseGlCode),callback:function ($$v) {_vm.expenseGlCode=$$v},expression:"expenseGlCode"}}),_c('b-form-invalid-feedback',{staticClass:"ml-2",attrs:{"id":"input-expense-invalid"}},[_vm._v("Please Select Expense GL Code")])],1)],1)],1)],1),_c('div',[_c('b-row',{staticClass:"mx-3"},[_c('b-col',{attrs:{"sm":"12","lg":"6"}},[_c('b-form-group',{attrs:{"id":"input-recipient","label-for":"input-recipient"}},[_c('div',{staticClass:"mx-2 my-1"},[[_vm._v(" Recipient "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]],2),_c('b-form-input',{class:{
                'mb-2 box-shadow3 bg-light text-dark': !_vm.isDarkMode,
                'mb-2 bg-dark text-light placeholder-light': _vm.isDarkMode
              },attrs:{"id":"input-recipient","disabled":_vm.ifAdmin,"type":"text","placeholder":"Enter Recipient","state":_vm.$v.form.recipient.$dirty ? !_vm.$v.form.recipient.$error : null},model:{value:(_vm.form.recipient),callback:function ($$v) {_vm.$set(_vm.form, "recipient", $$v)},expression:"form.recipient"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-recipient-invalid"}},[_vm._v("Please enter Recipient name")])],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"id":"group-refCode","label-for":"input-refCode"}},[_c('div',{staticClass:"mx-2 my-1"},[[_vm._v(" Reference Number / Bill Number "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]],2),_c('b-form-input',{class:{
                'mb-2 box-shadow3 bg-light text-dark': !_vm.isDarkMode,
                'mb-2 bg-dark text-light placeholder-light': _vm.isDarkMode
              },attrs:{"id":"input-name","type":"text","maxLength":"16","disabled":_vm.ifAdmin,"placeholder":"Enter Ref. Number","state":_vm.$v.form.refCode.$dirty ? !_vm.$v.form.refCode.$error : null},model:{value:(_vm.form.refCode),callback:function ($$v) {_vm.$set(_vm.form, "refCode", $$v)},expression:"form.refCode"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-refCode-invalid"}},[_vm._v("Please Enter Reference Number")])],1)],1)],1)],1),_c('div',[_c('b-row',{staticClass:"mx-3"},[_c('b-col',{attrs:{"sm":"12","lg":"6"}},[(this.formType === 'new')?_c('b-form-group',{attrs:{"label-for":"photoUpload","id":"group-photoUpload"}},[_c('div',{staticClass:"mx-2 my-1"},[[_vm._v(" Upload Voucher / Bill Photo "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]],2),_c('b-form-file',{staticClass:"box-shadow3",attrs:{"id":"input-photoUpload","disabled":_vm.ifAdmin,"type":"file","multiple":"","accept":"image/*","state":this.formType === 'new' && _vm.$v.form.file.$dirty ? !_vm.$v.form.file.$error : null},on:{"change":function($event){return _vm.uploadFile($event)}}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-photoUpload-invalid"}},[_vm._v("Please upload photo")])],1):_c('div',[_vm._v(" Uploaded Images "),_c('div',{directives:[{name:"viewer",rawName:"v-viewer"}]},[_c('div',[_vm._l((this.photoArray),function(image){return _c('div',{key:image,staticClass:"imageView"},[_c('img',{staticClass:"dynamic-image",attrs:{"src":image,"thunbnail":"","fluid":"","alt":"No Image Available"}})])})],2)]),_c('link',{attrs:{"href":"//unpkg.com/viewerjs/dist/viewer.css","rel":"stylesheet"}})])],1),_c('b-col',[_c('b-form-group',{attrs:{"id":"group-bilDate","label-for":"input-bilDate"}},[[_vm._v(" Bill Date "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])],_c('b-input-group',{staticClass:"box-shadow2 mb-2"},[_c('b-input-group-append',[_c('b-form-datepicker',{class:{
                    'mb-2 box-shadow3 bg-light text-dark': !_vm.isDarkMode,
                    'mb-2 bg-dark text-light placeholder-light': _vm.isDarkMode
                  },attrs:{"button-only":"","id":"input-bilDate","disabled":_vm.ifAdmin,"min":_vm.minDate,"max":_vm.maxDate,"date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' },"state":_vm.$v.form.startDateValue.$dirty ? !_vm.$v.form.startDateValue.$error : null},model:{value:(_vm.form.startDateValue),callback:function ($$v) {_vm.$set(_vm.form, "startDateValue", $$v)},expression:"form.startDateValue"}})],1),_c('b-form-input',{class:{
                  '': !_vm.isDarkMode,
                  'bg-dark text-light placeholder-light': _vm.isDarkMode
                },attrs:{"id":"example-input","placeholder":"Bill Date","disabled":""},model:{value:(_vm.form.startDateValue),callback:function ($$v) {_vm.$set(_vm.form, "startDateValue", $$v)},expression:"form.startDateValue"}})],1),_c('b-form-invalid-feedback',{attrs:{"id":"input-bilDate-invalid"}},[_vm._v("Please Provide Bill Date")])],2)],1)],1)],1),_c('div',[_c('b-row',{staticClass:"mx-3"},[_c('b-col',{attrs:{"sm":"12","lg":"6"}},[_c('b-form-group',{attrs:{"id":"group-amount","label-for":"input-amount"}},[_c('div',{staticClass:"mx-2 my-1"},[[_vm._v(" Amount "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]],2),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text box-shadow3"},[_vm._v("₹")])]),_c('b-form-input',{class:{
                  'box-shadow3 bg-light text-dark': !_vm.isDarkMode,
                  'bg-dark text-light placeholder-light': _vm.isDarkMode
                },attrs:{"id":"input-amount","type":"number","min":"1","disabled":_vm.ifAdmin,"placeholder":"Enter Amount","state":_vm.$v.amount.$dirty ? !_vm.$v.amount.$error : null,"max":"9990","onkeyup":"if(this.value > 9990) this.value = ''"},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-amount-invalid"}},[_vm._v("Please enter Amount")])],1),(this.amount !== '')?_c('b-form-text',{attrs:{"id":"input-amount-help"}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'info-circle']}}),_vm._v(" Will be sent to "+_vm._s(_vm.amountApprover)+" for review")],1):_vm._e()],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"id":"group-notes","label-for":"input-notes"}},[_c('div',{staticClass:"mx-2 my-1"},[[_vm._v(" Text ")],_c('span',{staticClass:"text-danger"},[_vm._v("*")])],2),_c('b-form-textarea',{class:{
                'box-shadow3 bg-light text-dark': !_vm.isDarkMode,
                'bg-dark text-light placeholder-light': _vm.isDarkMode
              },attrs:{"id":"input-notes","placeholder":"Enter Text...","rows":"4","state":_vm.$v.form.notes.$dirty ? !_vm.$v.form.notes.$error : null},model:{value:(_vm.form.notes),callback:function ($$v) {_vm.$set(_vm.form, "notes", $$v)},expression:"form.notes"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-notes-invalid"}},[_vm._v("Please enter Text")])],1)],1)],1)],1),_c('b-row',{staticClass:"mt-5"},[_c('b-col',{staticClass:"text-right"},[_c('b-button',{staticClass:"box-shadow2",attrs:{"type":"submit","size":"sm","variant":_vm.isDarkMode ? 'outline-success' : 'success',"disabled":_vm.loading}},[(_vm.loading)?_c('span',{staticClass:"spinner spinner-white"}):_vm._e(),_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'save']}}),_vm._v("Save ")],1),_c('b-button',{staticClass:"ml-2 box-shadow2",attrs:{"size":"sm","variant":_vm.isDarkMode ? 'outline-warning' : 'warning',"to":{ path: "/petty-cash/expense" }}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'long-arrow-alt-left']}}),_vm._v("Back to list ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }