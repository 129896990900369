<template>
  <div class="page-class page-setting-list">
    <div class="page-title text-center">
      <h2>{{ title }}</h2>
      <h6 class="">{{ subTittle }}</h6>
    </div>
    <petty-cash-budget-list
      :columns="columnsToShow"
      :rows="budgetList"
      :showToolbar="true"
      :searchItems="searchItems"
      :filterOptions="filterOptions"
      :increaseBudget="true"
      :showAdd="true"
      :showDownload="true"
      :loading="loading"
      :budgetSummary="true"
      budgetSummaryText="View Budget Summary"
      increaseBudgetTest="Budget Increase Requests"
      downloadText="Download List"
      budgetText="Create New Budget"
      emptyText="No Budget Found"
      @edit="onEdit"
      @budget="onBudget"
      @deleteBudget="onDeleteBudget"
      @allLive="onAllLive"
      @budgetIncrease="onBudgetIncrease"
      @download="onDownload"
      @budgetSummary="onBudgetSummary"
    ></petty-cash-budget-list>
  </div>
</template>

<script>
import PettyCashBudgetList from '@/components/PettyCash/PettyCashBudgetList.vue';
import router from '@/router';
import { mapState, mapActions, mapGetters } from 'vuex';
import { AdminBudgetView, NonAdminBudgetView } from '../../../helper/pettyCashHelper';
import moment from 'moment';

export default {
  name: 'PettyCash',
  components: {
    PettyCashBudgetList
  },
  mounted() {
    // this.listTransaction();
    if (_.isEmpty(this.$route.query)) {
      this.listBudget({
        query: {
          ...this.$route.query,
          page_size: 1
        }
      });
    } else {
      this.listBudget({ query: this.$route.query, page_size: 1 });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.listBudget({ query: to.query });
    next();
  },
  methods: {
    ...mapActions('pettyCash', ['listBudget', 'patchOne', 'deleteOneBudget', 'patchMakeAllBudgetLive']),
    onAllLive(ids) {
      this.patchMakeAllBudgetLive({ list: ids, redirectUrl: '/petty-cash' });
    },
    onEdit({ row }) {
      const budget = {
        storeId: row.storeId,
        budgetAmount: row.budgetAmount,
        isMakeLive: true,
        companyName: row.companyName,
        pattyCashStoreGl: row.pattyCashStoreGl,
        storeType: row.storeType,
        validTo: moment(row.validTo, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        validFrom: moment(row.validFrom, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        processDoneBy: 'Web'
      };

      const id = row.id;
      this.patchOne({ id, budget, router, redirectUrl: '/petty-cash' });
    },
    onDeleteBudget({ row }) {
      const id = row.id;
      this.deleteOneBudget({ id, router, redirectUrl: '/petty-cash' });
    },
    onDownload() {
      let csv = 'Sr. No,Company,Store Type ,Store Id , Store GL ,Cost Center ,Amount ,Month, Created By ,Is Live\n';
      this.budgetList.forEach(row => {
        Object.values(row).forEach((val, index) => {
          if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 10].includes(index)) {
            if (index === 11 && val === true) {
              csv += `Yes`;
              csv += ',';
            } else if (index === 10 && val === false) {
              csv += `No`;
              csv += ',';
            } else {
              csv += `"${val}"`;
              csv += ',';
            }
          }
        });
        csv += '\n';
      });
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'Monthly Budget.csv';
      anchor.click();
    },
    onBudget() {
      router.push('/petty-cash/budget/new');
    },
    onBudgetIncrease() {
      router.push('/petty-cash/budgetIncrease-request');
    },
    onBudgetSummary() {
      router.push('/petty-cash/BudgetSummary');
    }
  },
  computed: {
    ...mapState('pettyCash', ['loading', 'baseUrl', 'budgetList']),
    ...mapGetters('pettyCash', ['ifAdmin']),
    columnsToShow() {
      if (this.ifAdmin) {
        return AdminBudgetView;
      }
      return NonAdminBudgetView;
    }
  },
  data() {
    return {
      title: 'Petty Cash - Budget',
      searchItems: ['Store Id', 'Store Type', 'Company'],
      // 'Store Gl',
      subTittle: 'Distribute and manage cash to stores',
      filterOptions: [
        { value: null, text: 'Select Filter' },
        { value: 'Active-True', text: 'Active-True' },
        { value: 'Active-False', text: 'Active-False' }
      ]
    };
  }
};
</script>
