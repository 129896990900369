<template>
  <div class="page-class page-user-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <feedback-list-box
      :columns="columns"
      :rows="feedbackList"
      :pagination="pagination"
      :baseUrl="baseUrl"
      :loading="loading"
      emptyText="No feedback found. Please add new feedback."
      :showAdd="true"
      :showPagination="true"
      :filterOptions="filterOptions"
      :searchItems="searchItems"
      :steps="steps"
      :callbacks="callbacks"
      :showUpload="true"
      :showDownload="true"
      :showToolbar="true"
      addText="Create new Feedback"
      uploadText="Upload Feedbacks"
      downloadText="Download Feedbacks"
      @add="onAdd"
      @edit="onEdit"
      @delete="onDelete"
      @download="onDownload"
      @show-upload="onShowUpload"
    >
      <template v-slot:emailAddressFormatted="slotProps">
        <span class="span-text span-block">
          {{ slotProps.row.email }}
        </span>
        <template v-if="slotProps.row.confirmedAtFormatted">
          <span class="text-success span-help-text" :title="slotProps.row.confirmedAt">
            ({{ slotProps.row.confirmedAtFormatted }})
          </span>
        </template>
        <template v-if="slotProps.row.confirmedAtFormatted === null">
          <span class="text-warning span-help-text"> (Not confirmed) </span>
        </template>
      </template>
      <template v-slot:lastLoginAtFormatted="slotProps">
        <template v-if="slotProps.row.lastLoginAtFormatted">
          <span class="text-success">{{ slotProps.row.lastLoginAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.lastLoginAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
      <template v-slot:blockedAtFormatted="slotProps">
        <template v-if="slotProps.row.blockedAtFormatted">
          <span class="text-success">{{ slotProps.row.blockedAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.blockedAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
    </feedback-list-box>
  </div>
</template>

<script>
import moment from 'moment';
import Vue from 'vue';
import csv from 'csvtojson';
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import _ from 'lodash';
import FeedbackListBox from '../../components/FeedbackListBox.vue';

export default {
  name: 'Feedback',
  components: { FeedbackListBox },
  metaInfo() {
    return {
      title: 'Feedback',
      meta: [
        {
          name: 'description',
          content: `Manage Feedback`
        }
      ]
    };
  },
  mounted() {
    // if (window.localStorage.getItem('hasCompletedFeedback') !== 'true') {
    //   this.$tours.myTour.start();
    // }
    if (_.isEmpty(this.$route.query)) {
      this.listFeedback({
        query: {
          ...this.$route.query,
          startdate: moment().format('YYYY-MM-DD'),
          enddate: moment().format('YYYY-MM-DD')
        }
      });
    } else {
      this.listFeedback({ query: this.$route.query });
    }
  },
  data() {
    return {
      title: 'Feedback',
      callbacks: {
        onFinish() {
          window.localStorage.setItem('hasCompletedFeedback', 'true');
        }
      },
      steps: [
        {
          target: '[data-v-step="0"]', // We're using document.querySelector() under the hood
          content: `Enter search criteria based on Customer Name, Mobile or Store Id to Filter the List of Feedback.`,
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="2"]',
          content: 'Select Date Range to further refine search results and click Apply button.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="3"]',
          content: 'Click to create new Feedback.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="4"]', // We're using document.querySelector() under the hood
          content: `Click to Download Feedback in CSV format.`,
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="5"]',
          content: 'Click to Upload Feedback in CSV format.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        }
      ],
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { ' text-center': true },
          textKey: 'rowNum',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Customer Name',
          class: { 'text-center': true },
          textKey: 'customerName',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Mobile',
          class: { 'text-center': true },
          textKey: 'mobile',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Date',
          class: { 'text-center': true },
          textKey: 'dateOfCalling',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          class: { 'text-center': true },
          textKey: 'storeid',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Submitted By Name',
          class: { 'text-center': true },
          textKey: 'submittedByName',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Outcome',
          class: { 'text-center': true },
          textKey: 'outcome',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Remarks',
          class: { 'text-center': true },
          textKey: 'remarks',
          width: '20%'
        },

        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '15%',
          functions: {
            edit: false,
            delete: true
          }
        }
      ],
      searchItems: ['Customer Name', 'Mobile', 'Store Id'],
      filterOptions: [
        { value: null, text: 'Select Filter' },
        { value: 'Outcome-Negative', text: 'Outcome-Negative' },
        { value: 'Outcome-Positive', text: 'Outcome-Positive' },
        { value: 'Outcome-Neutral', text: 'Outcome-Neutral' }
      ]
    };
  },
  computed: {
    ...mapState('feedback', ['loading', 'baseUrl', 'feedbackList', 'pagination']),
    ...mapState('darkMode', ['isDarkMode'])
  },
  methods: {
    ...mapActions('feedback', ['listFeedback', 'deleteOne', 'upload']),
    onAdd() {
      router.push('/feedback/new');
    },
    onEdit({ row }) {
      router.push(`/feedback/${row.id}`);
    },
    onDelete({ row }) {
      Vue.swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this.",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        },
        preConfirm: () => {
          this.deleteOne({
            id: row.id
          });
        }
      });
    },
    onDownload() {
      let csv = 'Row Number,Customer Name,Mobile,Date,Store Id,Submitted By,Outcome,Remarks\n';
      this.feedbackList.forEach(row => {
        Object.values(row).forEach((val, index) => {
          if ([1, 2, 3, 4, 5, 6, 7, 8].includes(index)) {
            csv += `"${val}"`;
            csv += ',';
          }
        });
        csv += '\n';
      });
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'Feedbacks.csv';
      anchor.click();
    },
    async onShowUpload() {
      const { value: feedbackFile } = await Vue.swal({
        title: 'Upload file for feedbacks..',
        input: 'file',
        inputAttributes: {
          'aria-label': 'Upload your feedback file in csv format'
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        showCancelButton: true,
        cancelButtonText: 'Close',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        },
        footer:
          '<a href="https://spencer-template.s3.ap-south-1.amazonaws.com/Feedback.csv" >Download sample upload file</a>'
      });
      if (feedbackFile) {
        const reader = new FileReader();
        reader.onload = () => {
          csv({
            noheader: true,
            output: 'csv'
          })
            .fromString(reader.result)
            .then(csvRow => {
              const inputs = csvRow.slice(1, csvRow.length).map(item => {
                return {
                  storeid: item[0],
                  dateOfCalling: item[1],
                  customerName: item[2],
                  mobile: item[3],
                  outcome: item[4].toUpperCase(),
                  remarks: item[5]
                };
              });
              this.upload({ router, data: inputs });
            });
        };
        reader.readAsBinaryString(feedbackFile);
      }
    }
  }
};
</script>
