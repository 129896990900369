<template>
  <div class="page-class page-user-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <checklist-v2-box
      :columns="columns"
      :rows="checklistsV2"
      :baseUrl="baseUrl"
      :loading="loading"
      :showAdd="true"
      :showDownload="true"
      :showToolbar="true"
      :showPagination="true"
      :pagination="pagination"
      :filterOptions="filterOptions"
      :steps="steps"
      :callbacks="callbacks"
      :searchItems="searchItems"
      addText="Create new Checklist"
      downloadText="Download Checklist"
      emptyText="No checklist found. Please add new checklist."
      @add="onAdd"
      @edit="onEdit"
      @download="onDownload"
      @download-mis="onDownloadMis"
      @download-mis-question="onDownloadMisQuestion"
      @download-checklist-data="onDownloadChecklistData"
    >
      <template v-slot:emailAddressFormatted="slotProps">
        <span class="span-text span-block">
          {{ slotProps.row.email }}
        </span>
        <template v-if="slotProps.row.confirmedAtFormatted">
          <span class="text-success span-help-text" :title="slotProps.row.confirmedAt">
            ({{ slotProps.row.confirmedAtFormatted }})
          </span>
        </template>
        <template v-if="slotProps.row.confirmedAtFormatted === null">
          <span class="text-warning span-help-text"> (Not confirmed) </span>
        </template>
      </template>
      <template v-slot:lastLoginAtFormatted="slotProps">
        <template v-if="slotProps.row.lastLoginAtFormatted">
          <span class="text-success">{{ slotProps.row.lastLoginAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.lastLoginAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
      <template v-slot:blockedAtFormatted="slotProps">
        <template v-if="slotProps.row.blockedAtFormatted">
          <span class="text-success">{{ slotProps.row.blockedAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.blockedAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
    </checklist-v2-box>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import ChecklistV2Box from '@/components/ChecklistV2/ChecklistV2Box.vue';
import router from '@/router';
import _ from 'lodash';
import { url } from 'vuelidate/lib/validators';

export default {
  name: 'ChecklistV2',
  components: { ChecklistV2Box },
  metaInfo() {
    return {
      title: 'Food Safety Checklist',
      meta: [
        {
          name: 'description',
          content: `Manage Food Safety`
        }
      ]
    };
  },
  mounted() {
    // if (window.localStorage.getItem('hasCompletedChecklist') !== 'true') {
    //   this.$tours.myTour.start();
    // }
    if (_.isEmpty(this.$route.query)) {
      this.list({
        query: {
          ...this.$route.query
          // startdate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
          // enddate: moment().format('YYYY-MM-DD')
        }
      });
    } else {
      this.list({ query: this.$route.query });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.list({ query: to.query });
    next();
  },
  data() {
    return {
      title: 'Food Safety Checklist',
      callbacks: {
        onFinish() {
          window.localStorage.setItem('hasCompletedChecklistV2', 'true');
        }
      },
      steps: [
        {
          target: '[data-v-step="0"]', // We're using document.querySelector() under the hood
          content: `Enter search criteria based on Name or Store Id to Filter the List of Checklist.`,
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="1"]',
          content: 'Select Filter to further refine search Results.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="2"]',
          content: 'Select Date Range to further refine search results and click Apply button.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="3"]', // We're using document.querySelector() under the hood
          content: `Click to create a new Checklist.`,
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="4"]',
          content: 'Click to download Checklist in CSV format .',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="5"]',
          content: 'Click to View/Edit Checklist.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="6"]',
          content: 'Click to Download Checklist in MIS format.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        }
      ],
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { ' text-center': true },
          textKey: 'index',
          width: '3%'
        },
        {
          type: 'string',
          headerText: 'Name',
          class: { 'text-center': true },
          textKey: 'name',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Active',
          class: { 'text-center': true },
          textKey: 'active',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Role',
          class: { 'text-center': true },
          textKey: 'roles',
          width: '9%'
        },
        {
          type: 'string',
          headerText: 'Start Date',
          class: { 'text-center': true },
          textKey: 'startDate',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'End Date',
          class: { 'text-center': true },
          textKey: 'endDate',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Frequency',
          class: { 'text-center': true },
          textKey: 'frequency',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Region',
          class: { 'text-center': true },
          textKey: 'regions',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Cluster',
          class: { 'text-center': true },
          textKey: 'clusters',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          class: { 'text-center': true },
          textKey: 'storeids',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Formats',
          class: { 'text-center': true },
          textKey: 'formats',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Shared To',
          class: { 'text-center': true },
          textKey: 'shareTo',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Active Till',
          class: { 'text-center': true },
          textKey: 'activeTillTime',
          width: '6%'
        },
        // {
        //   type: 'string',
        //   headerText: 'Permission',
        //   class: { 'text-center': true },
        //   textKey: 'permissions',
        //   width: '10%'
        // },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '14%',
          functions: {
            edit: true,
            mis: true,
            misQuestion: true,
            checklistData: true
          }
        }
      ],
      searchItems: ['Name', 'Store Id'],
      filterOptions: [
        { value: null, text: 'Select Filter' },
        { value: 'Active-True', text: 'Active-True' },
        { value: 'Active-False', text: 'Active-False' }
      ]
    };
  },
  computed: {
    ...mapState('checklistV2', ['loading', 'baseUrl', 'checklistsV2', 'pagination', 'checkpoints']),
    ...mapState('mis', ['misChecklist', 'misByQuestion']),
    ...mapState('auth', ['user'])
  },
  watch: {
    misChecklist(newValue) {
      if (newValue.data.length) {
        const checklistName = newValue.checkpoints[0].checkListName;
        const { checkpoints } = newValue;
        const groupCategory = _.groupBy(checkpoints, check => check.category);
        _.map(groupCategory, (v, k) => {
          groupCategory[k] = _.groupBy(v, value => value.subCategory);
        });

        const flatMapCheckpoint = {};
        _.map(groupCategory, (v, k) => {
          const catMap = [];
          _.forEach(v, (value, key) => {
            const x = value.map(({ _id }) => _id);
            flatMapCheckpoint[key] = x;
            catMap.push(...x);
          });
          flatMapCheckpoint[k] = catMap;
        });
        const { startdate, enddate } = this.$route.query;
        let csv = '';
        csv += `,,,Checklist Name :- ${checklistName},\n`;
        if (startdate && enddate) {
          csv += `,,,Date From ${startdate} to ${enddate},\n`;
        } else {
          csv += `,,,Date From ${moment().format('YYYY-MM-DD')} to ${moment().format('YYYY-MM-DD')},\n`;
        }
        csv += ',,,,,,,,\n';
        csv +=
          'Store,Store Name,Cluster,Region,Format, Expected Count, Submitted Count, Pending Count, Last Report Created Date, Last Submitted By,';
        const catHeader = [];
        _.forEach(groupCategory, (v, k) => {
          catHeader.push(k);
          _.forEach(v, (_, key) => {
            catHeader.push(key);
          });
        });
        csv += catHeader.join(',');
        csv += '\n';
        newValue.data.forEach(row => {
          Object.values(row).forEach((val, index) => {
            if (index < 10) {
              csv += `"${val}"`;
              csv += ',';
            }
          });
          if (!_.isEmpty(row.groupCheckpointSub)) {
            _.forEach(catHeader, header => {
              const lengthOfScore = flatMapCheckpoint[header].length;
              let totalScore = 0;
              flatMapCheckpoint[header].map(id => {
                let score = 0;
                if (row.groupCheckpointSub[id]) {
                  row.groupCheckpointSub[id].forEach(x => {
                    score += x.score;
                  });
                }
                totalScore += score;
                return null;
              });
              csv += `${(totalScore / lengthOfScore).toFixed(2)},`;
            });
          }
          csv += '\n';
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = `${checklistName}_ChecklistMIS.csv`;
        anchor.click();
      }
    },
    misByQuestion(newValue) {
      if (newValue) {
        const checklistName = newValue.map(i => i.checklistName[0]);
        const arr = newValue.map(i => ({
          checklistName: i.checklistName,
          submittedByName: i.submittedByName,
          submittedOn: moment(i.submittedOn).format('DD-MM-YYYY HH:mm'),
          storeid: i.storeid,
          storeName: i.storeName,
          format: i.storeFormat,
          cluster: i.storeCluster,
          region: i.storeRegion,
          location:
            i.location.map(j => j.lat) && i.location.map(j => j.long)
              ? `https://maps.google.com/?q=${i.location.map(j => j.lat)},${i.location.map(j => j.long)}`
              : '',
          image: i.image ? i.image.join(';') : 'NA',
          category: i.category,
          subCategory: i.subCategory,
          question: i.questionText,
          actualAverage: i.actual ? i.actual : 'NA',
          averageScore: i.score === -1 ? '-' : i.score,
          averageTarget: i.target ? i.target : 'NA',
          checkpointType: i.checkpointType,
          remarks: i.remarks === '' ? '-' : i.remarks === null ? '-' : i.remarks,
          // score: i.score === -1 ? '-' : i.score * 100 + '%'
          score:
            i.score === -1 ? '-' : i.checkpointType === 'TARGET' ? i.score * 100 + '%' : i.score > 0 ? '100%' : '0%'
        }));
        const { startdate, enddate } = this.$route.query;
        let csv = '';
        csv += `,,,,,Checklist Name :- ${checklistName[0]},\n`;
        if (startdate && enddate) {
          csv += `,,,,,Date From ${startdate} to ${enddate},\n`;
        } else {
          csv += `,,,,,Date From ${moment().format('YYYY-MM-DD')} to ${moment().format('YYYY-MM-DD')},\n`;
        }
        csv += ',,,,,,,,\n';
        csv +=
          'Checklist Name, Submitted By, Submitted Date, Store Id , Store Name, Format , Cluster, Region, Location, Image, Category, Sub Category, Question, Actual Average, Average Score, Average Target, Checkpoint Type,Remarks, Score%\n';
        arr.forEach(row => {
          Object.values(row).forEach(val => {
            csv += `"${val}"`;
            csv += ',';
          });
          csv += '\n';
        });

        // const Arrayquestion = newValue.map(i => i._id.map(x => x));
        // const question = Arrayquestion.flat();
        // let csv = 'Question,Total,Average Target,Average Actual,Average Score,Score Percentage,Question Type\n';
        // newValue.forEach(row => {
        //   Object.values(row).forEach(val => {
        //     question.forEach(x => {
        //       if (row._id.includes(x)) {
        //         csv += `"${val}"`;
        //         csv += ',';
        //       }
        //     });
        //   });
        //   csv += '\n';
        // });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = `${checklistName[0]}_QuestionMis.csv`;
        anchor.click();
      }
    },
    checkpoints(val) {
      if (val) {
        const checklistName = val.map(i => i.checkListName);
        const { startdate, enddate } = this.$route.query;
        let csv = '';
        csv += `,,Checklist Name :- ${checklistName[0]},\n`;
        if (startdate && enddate) {
          csv += `,,Date From ${startdate} to ${enddate},\n`;
        } else {
          csv += `,,Date From ${moment().format('YYYY-MM-DD')} to ${moment().format('YYYY-MM-DD')},\n`;
        }
        csv += ',,,,,,,,\n';
        csv += 'Category,Sub Category,Question,Weightage,Question Type,Is Critical\n';

        const data = val.map(i => ({
          category: i.category,
          subCategory: i.subCategory,
          question: i.questionText,
          weightage: i.weightage,
          questionType: i.checkpointType,
          isCritical: i.isCritical
        }));

        data.forEach(row => {
          Object.values(row).forEach(val => {
            csv += `"${val}"`;
            csv += ',';
          });
          csv += '\n';
        });

        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = `${checklistName[0]}_Checkpoints.csv`;
        anchor.click();
      }
    }
  },
  methods: {
    ...mapActions('checklistV2', ['list', 'downloadCheckpoints']),
    ...mapActions('mis', ['downloadMisChecklist', 'downloadMisByQuestion']),
    ifAdmin() {
      const isAdmin = this.user.roles.filter(role => role.name === 'ADMIN' || role.name === 'SUB_ADMIN');
      if (isAdmin.length) {
        return true;
      }
      return false;
    },
    onAdd() {
      router.push('/checklistV2/new');
    },
    onEdit({ row }) {
      router.push(`/checklistV2/${row.id}`);
    },
    onDownload() {
      let csv = 'Row Number,Name,Active,Start Date,End Date,Regions,Clusters,Formats,Store Ids,Role,Frequency\n';
      this.checklistsV2.forEach(row => {
        Object.values(row).forEach((val, index) => {
          if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 12, 13].includes(index)) {
            csv += `"${val}"`;
            csv += ',';
          }
        });
        csv += '\n';
      });
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'Checklist.csv';
      anchor.click();
    },
    onDownloadMis({ row }) {
      if (_.isEmpty(this.$route.query)) {
        this.downloadMisChecklist({
          query: {
            checklistId: row.id,
            startdate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
            enddate: moment().format('YYYY-MM-DD')
          }
        });
      } else {
        this.downloadMisChecklist({ query: { checklistId: row.id, ...this.$route.query } });
      }
    },
    onDownloadMisQuestion({ row }) {
      if (_.isEmpty(this.$route.query)) {
        this.downloadMisByQuestion({
          query: {
            checklistId: row.id,
            startdate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
            enddate: moment().format('YYYY-MM-DD')
          }
        });
      } else {
        this.downloadMisByQuestion({ query: { checklistId: row.id, ...this.$route.query } });
      }
    },
    onDownloadChecklistData({ row }) {
      this.downloadCheckpoints({ id: row.id });
    }
  }
};
</script>
