<template>
  <div class="page-class page-center">
    <div class="page-staff-form">
      <h1 class="page-title text-center">{{ title }}</h1>
      <petty-cash-in-hand-form-box @post="onPost"> </petty-cash-in-hand-form-box>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import PettyCashInHandFormBox from '../../../components/PettyCash/PettyCashInHandFormBox.vue';

export default {
  name: 'PettyCashInHandForm',
  components: { PettyCashInHandFormBox },
  async mounted() {
    if (this.$route.name === 'petty-cash-in-hand-new') {
      this.formType = 'new';
      this.id = null;
    } else {
      this.formType = 'update';
      this.id = this.$route.params.id;
    }
  },
  data() {
    return {
      pattyCashStoreGl: '',
      formType: ''
    };
  },
  computed: {
    title() {
      return this.formType === 'new' ? 'Add New Cash' : 'Add Account';
    }
  },
  watch: {},
  methods: {
    ...mapActions('pettyCash', ['postCashSnapshot']),
    onPost(form) {
      const request = {
        storeId: form.storeId,
        companyName: form.companyName,
        pattyCashStoreGl: form.pattyCashStoreGl,
        startingBalance: Number(form.amount),
        processDoneBy: form.processDoneBy
      };
      this.postCashSnapshot({ request: request, router, redirectUrl: '/petty-cash/account' });
    }
  }
};
</script>
