<template>
  <div>
    <b-form
      @submit.stop.prevent="onSubmit"
      :class="{
        'p-3 box-shadow2 rounded': !isDarkMode,
        'dark-div p-3 box-shadow2 rounded': isDarkMode
      }"
    >
      <div>
        <b-row class="mx-3">
          <b-col>
            <b-form-group id="group-storeId" label-for="input-storeId">
              <div class="mx-2 my-1">
                <template class="">
                  Select Store Id
                  <span class="text-danger">*</span>
                </template>
              </div>
              <div class="mx-auto" id="group-storeId">
                <b-form-select
                  id="input-storeId"
                  v-model="storeId"
                  :disabled="ifAdmin"
                  :options="storeOptions"
                  :class="{
                    'box-shadow3 bg-light text-dark': !isDarkMode,
                    'bg-dark text-light placeholder-light': isDarkMode
                  }"
                  :state="$v.storeId.$dirty ? !$v.storeId.$error : null"
                ></b-form-select>
                <b-form-invalid-feedback id="input-storeId-invalid">Please Select the Store ID</b-form-invalid-feedback>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-row class="mx-3">
          <b-col sm="12" lg="6">
            <b-form-group id="group-companyName" label-for="input-companyName">
              <div class="mx-2 my-1">
                <template class="">
                  Company Name
                  <span class="text-danger">*</span>
                </template>
              </div>
              <div class="mx-auto" id="group-companyName">
                <b-form-input
                  id="input-companyName"
                  v-model="form.companyName"
                  disabled
                  placeholder="Enter Company Name"
                  :class="{
                    'box-shadow3 bg-light text-dark': !isDarkMode,
                    'bg-dark text-light placeholder-light': isDarkMode
                  }"
                  :state="$v.form.companyName.$dirty ? !$v.form.companyName.$error : null"
                ></b-form-input>
                <b-form-invalid-feedback id="input-companyName-invalid"
                  >Please Enter Company Name</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="group-companyCode" label-for="input-companyCode">
              <div class="mx-2 my-1">
                <template class="">
                  Company Code
                  <span class="text-danger">*</span>
                </template>
              </div>
              <b-form-input
                id="input-companyCode"
                :class="{
                  'mb-2 box-shadow3 bg-light text-dark': !isDarkMode,
                  'mb-2 bg-dark text-light placeholder-light': isDarkMode
                }"
                disabled
                v-model="form.companyCode"
                placeholder="Enter Company Code"
                :state="$v.form.companyCode.$dirty ? !$v.form.companyCode.$error : null"
              >
              </b-form-input>
              <b-form-invalid-feedback id="input-companyCode-invalid"
                >Please Provide Company Code</b-form-invalid-feedback
              >
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-row class="mx-3">
          <b-col sm="12" lg="6">
            <b-form-group id="group-costCenter" label-for="input-costCenter">
              <div class="mx-2 my-1">
                <template class="">
                  Cost Center
                  <span class="text-danger">*</span>
                </template>
              </div>
              <b-form-input
                id="input-costCenter"
                :class="{
                  'mb-2 box-shadow3 bg-light text-dark': !isDarkMode,
                  'mb-2 bg-dark text-light placeholder-light': isDarkMode
                }"
                v-model="form.selectedCostCenter"
                placeholder="Enter cost center"
                :state="$v.form.selectedCostCenter.$dirty ? !$v.form.selectedCostCenter.$error : null"
              >
              </b-form-input>
              <b-form-invalid-feedback id="input-costCenter-invalid">Please Select Cost Center</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col class="">
            <b-form-group id="group-costCenter" label-for="input-costCenter">
              <div class="mx-2 my-1">
                <template class="">
                  Petty-Cash Gl
                  <span class="text-danger">*</span>
                </template>
              </div>
              <b-form-input
                id="input-costCenter"
                :class="{
                  'mb-2 box-shadow3 bg-light text-dark': !isDarkMode,
                  'mb-2 bg-dark text-light placeholder-light': isDarkMode
                }"
                disabled
                v-model="form.pattyCashStoreGl"
                placeholder="Enter Petty Cash Store Gl"
                :state="$v.form.pattyCashStoreGl.$dirty ? !$v.form.pattyCashStoreGl.$error : null"
              >
              </b-form-input>
              <b-form-invalid-feedback id="input-costCenter-invalid"
                >Please Select Petty-Cash Gl</b-form-invalid-feedback
              >
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-row class="mx-3 mt-3">
          <b-col sm="12" lg="6">
            <b-form-group id="group-glName" label-for="input-glName">
              <template class="">
                Gl Name
                <span class="text-danger">*</span>
              </template>
              <multiselect
                :class="{
                  'mb-2 box-shadow3': !isDarkMode,
                  darkMode: isDarkMode
                }"
                id="input-glName"
                :options="categoryOptions"
                v-model="selectedCategory"
                :allow-empty="false"
                placeholder="Search Gl Name"
              >
              </multiselect>
              <b-form-input
                class="d-none"
                id="input-glName"
                :state="$v.selectedCategory.$dirty ? !$v.selectedCategory.$error : null"
                v-model="selectedCategory"
              ></b-form-input>
              <b-form-invalid-feedback class="ml-2" id="input-glName-invalid"
                >Please Provide Gl Name</b-form-invalid-feedback
              >
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="group-expense" label-for="input-expense">
              <div>
                <template class="">
                  Expense Gl Code
                  <span class="text-danger">*</span>
                </template>
              </div>
              <multiselect
                :class="{
                  'mb-2 box-shadow3': !isDarkMode,
                  darkMode: isDarkMode
                }"
                id="input-expense"
                :options="expenseOptions"
                v-model="expenseGlCode"
                :allow-empty="false"
                placeholder="Search Expense GL Code"
              >
              </multiselect>
              <b-form-input
                class="d-none box-shadow3"
                id="input-expense"
                :state="$v.expenseGlCode.$dirty ? !$v.expenseGlCode.$error : null"
                v-model="expenseGlCode"
              ></b-form-input>
              <b-form-invalid-feedback class="ml-2" id="input-expense-invalid"
                >Please Select Expense GL Code</b-form-invalid-feedback
              >
            </b-form-group>
          </b-col>
        </b-row>
      </div>

      <div>
        <b-row class="mx-3">
          <b-col sm="12" lg="6">
            <b-form-group id="input-recipient" label-for="input-recipient">
              <div class="mx-2 my-1">
                <template class="">
                  Recipient
                  <span class="text-danger">*</span>
                </template>
              </div>
              <b-form-input
                :class="{
                  'mb-2 box-shadow3 bg-light text-dark': !isDarkMode,
                  'mb-2 bg-dark text-light placeholder-light': isDarkMode
                }"
                id="input-recipient"
                :disabled="ifAdmin"
                type="text"
                v-model="form.recipient"
                placeholder="Enter Recipient"
                :state="$v.form.recipient.$dirty ? !$v.form.recipient.$error : null"
              ></b-form-input>

              <b-form-invalid-feedback id="input-recipient-invalid"
                >Please enter Recipient name</b-form-invalid-feedback
              >
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="group-refCode" label-for="input-refCode">
              <div class="mx-2 my-1">
                <template class="">
                  Reference Number / Bill Number
                  <span class="text-danger">*</span>
                </template>
              </div>
              <b-form-input
                :class="{
                  'mb-2 box-shadow3 bg-light text-dark': !isDarkMode,
                  'mb-2 bg-dark text-light placeholder-light': isDarkMode
                }"
                id="input-name"
                type="text"
                maxLength="16"
                :disabled="ifAdmin"
                v-model="form.refCode"
                placeholder="Enter Ref. Number"
                :state="$v.form.refCode.$dirty ? !$v.form.refCode.$error : null"
              ></b-form-input>
              <b-form-invalid-feedback id="input-refCode-invalid"
                >Please Enter Reference Number</b-form-invalid-feedback
              >
            </b-form-group>
          </b-col>
        </b-row>
      </div>

      <div>
        <b-row class="mx-3">
          <b-col sm="12" lg="6">
            <b-form-group v-if="this.formType === 'new'" label-for="photoUpload" id="group-photoUpload">
              <div class="mx-2 my-1">
                <template class="">
                  Upload Voucher / Bill Photo
                  <span class="text-danger">*</span>
                </template>
              </div>
              <b-form-file
                class="box-shadow3"
                id="input-photoUpload"
                :disabled="ifAdmin"
                type="file"
                multiple
                @change="uploadFile($event)"
                accept="image/*"
                :state="this.formType === 'new' && $v.form.file.$dirty ? !$v.form.file.$error : null"
              />
              <b-form-invalid-feedback id="input-photoUpload-invalid">Please upload photo</b-form-invalid-feedback>
            </b-form-group>
            <div v-else>
              Uploaded Images
              <div v-viewer>
                <div>
                  <template>
                    <div v-for="image in this.photoArray" :key="image" class="imageView">
                      <img :src="image" class="dynamic-image" thunbnail fluid alt="No Image Available" />
                    </div>
                  </template>
                </div>
              </div>
              <link href="//unpkg.com/viewerjs/dist/viewer.css" rel="stylesheet" />
            </div>
          </b-col>
          <b-col>
            <b-form-group id="group-bilDate" label-for="input-bilDate">
              <template class="">
                Bill Date
                <span class="text-danger">*</span>
              </template>
              <b-input-group class="box-shadow2 mb-2">
                <b-input-group-append>
                  <b-form-datepicker
                    button-only
                    id="input-bilDate"
                    :disabled="ifAdmin"
                    :min="minDate"
                    :max="maxDate"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    v-model="form.startDateValue"
                    :class="{
                      'mb-2 box-shadow3 bg-light text-dark': !isDarkMode,
                      'mb-2 bg-dark text-light placeholder-light': isDarkMode
                    }"
                    :state="$v.form.startDateValue.$dirty ? !$v.form.startDateValue.$error : null"
                  ></b-form-datepicker>
                </b-input-group-append>
                <b-form-input
                  id="example-input"
                  v-model="form.startDateValue"
                  placeholder="Bill Date"
                  :class="{
                    '': !isDarkMode,
                    'bg-dark text-light placeholder-light': isDarkMode
                  }"
                  disabled
                ></b-form-input>
              </b-input-group>
              <b-form-invalid-feedback id="input-bilDate-invalid">Please Provide Bill Date</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-row class="mx-3">
          <b-col sm="12" lg="6">
            <b-form-group id="group-amount" label-for="input-amount">
              <div class="mx-2 my-1">
                <template class="">
                  Amount
                  <span class="text-danger">*</span>
                </template>
              </div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text box-shadow3">&#8377;</span>
                </div>
                <b-form-input
                  id="input-amount"
                  type="number"
                  min="1"
                  :disabled="ifAdmin"
                  v-model="amount"
                  :class="{
                    'box-shadow3 bg-light text-dark': !isDarkMode,
                    'bg-dark text-light placeholder-light': isDarkMode
                  }"
                  placeholder="Enter Amount"
                  :state="$v.amount.$dirty ? !$v.amount.$error : null"
                  max="9990"
                  onkeyup="if(this.value > 9990) this.value = ''"
                />

                <b-form-invalid-feedback id="input-amount-invalid">Please enter Amount</b-form-invalid-feedback>
              </div>
              <b-form-text v-if="this.amount !== ''" id="input-amount-help">
                <font-awesome-icon :icon="['fas', 'info-circle']" class="mr-1" /> Will be sent to
                {{ amountApprover }} for review</b-form-text
              >
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="group-notes" label-for="input-notes">
              <div class="mx-2 my-1">
                <template class=""> Text </template>
                <span class="text-danger">*</span>
              </div>
              <b-form-textarea
                id="input-notes"
                v-model="form.notes"
                placeholder="Enter Text..."
                rows="4"
                :state="$v.form.notes.$dirty ? !$v.form.notes.$error : null"
                :class="{
                  'box-shadow3 bg-light text-dark': !isDarkMode,
                  'bg-dark text-light placeholder-light': isDarkMode
                }"
              ></b-form-textarea>
              <b-form-invalid-feedback id="input-notes-invalid">Please enter Text</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
      </div>

      <b-row class="mt-5">
        <b-col class="text-right">
          <b-button
            class="box-shadow2"
            type="submit"
            size="sm"
            :variant="isDarkMode ? 'outline-success' : 'success'"
            :disabled="loading"
          >
            <span class="spinner spinner-white" v-if="loading"></span>
            <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Save
          </b-button>
          <b-button
            class="ml-2 box-shadow2"
            size="sm"
            :variant="isDarkMode ? 'outline-warning' : 'warning'"
            :to="{ path: `/petty-cash/expense` }"
          >
            <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" class="mr-1" />Back to list
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';
import { mapGetters, mapState, mapActions } from 'vuex';
import _ from 'lodash';
import Vue from 'vue';
import VueViewer from 'v-viewer';

import Multiselect from 'vue-multiselect';
Vue.use(VueViewer);
const init = {
  mounted: el => el.init()
};

export default {
  name: 'PettyCashFormBox',
  components: {
    Multiselect
  },
  directives: {
    init
  },
  props: {
    options: Object,
    formType: String
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: this.metaDescription
        }
      ]
    };
  },
  data() {
    return {
      loading: false,
      gLCode: '',
      formLoaded: false,
      selectedCategory: null,
      expenseGlCode: null,
      storeOptions: [{ value: null, text: 'Select Store' }],
      categoryOptions: [],
      expenseOptions: [],
      amountApprover: 'Store Sales Manager',
      budgetLeft: 0,
      cashInHandLeft: 0,
      amount: '',
      storeId: null,
      form: {
        pattyCashStoreGl: '',
        companyName: '',
        refCode: '',
        notes: '',
        startDateValue: '',
        selectedFiles: [],
        selectedCostCenter: '',
        file: [],
        status: 'CREATED',
        fileName: [],
        attachments: [],
        recipient: '',
        companyCode: ''
      },
      uploadedPhotos: [],
      photoArray: 0,
      minDate: moment('2020-01-01').format('YYYY-MM-DD'),
      maxDate: moment().format('YYYY-MM-DD'),
      oldForm: [],
      isSubmitDisabled: false
    };
  },
  validations() {
    const formValidation = {
      recipient: {
        required
      },
      companyName: {
        required
      },
      companyCode: {
        required
      },
      pattyCashStoreGl: {
        required
      },
      refCode: {
        required
      },
      startDateValue: {
        required
      },
      notes: {
        required
      },
      selectedCostCenter: {
        required
      },
      file: {
        required
      }
    };
    const selectedCategory = {
      required
    };
    const expenseGlCode = {
      required
    };
    const amount = { required };
    const storeId = { required };
    // };
    if (this.formType === 'new') {
      storeId.required = required;
      formValidation.recipient.required = required;
      formValidation.companyCode.required = required;
      formValidation.companyName.required = required;
      formValidation.pattyCashStoreGl.required = required;
      formValidation.refCode.required = required;
      amount.required = required;
      formValidation.startDateValue.required = required;
      formValidation.notes.required = required;
      formValidation.selectedCostCenter.required = required;
      formValidation.file.required = required;
      expenseGlCode.required = required;
      selectedCategory.required = required;
    }

    return { form: formValidation, selectedCategory, expenseGlCode, amount, storeId };
  },
  mounted() {
    this.getStoreByUser();
    this.$nextTick(async () => {
      if (this.formType === 'new' || this.formLoaded === false) {
        this.$v.$touch(); // Set initial validation
        this.$v.$reset(); // Reset $dirty
      }
    });
  },
  computed: {
    ...mapState('pettyCash', ['baseUrl', 'expense', 'rulesList', 'budgetType', 'summary', 'bulkImages']),
    ...mapState('auth', ['user']),
    ...mapState('darkMode', ['isDarkMode']),
    ...mapState('cstSubmission', ['store']),
    ...mapGetters('pettyCash', ['ifAdmin']),
    metaDescription() {
      return this.formType === 'new' ? 'Add new Expense' : 'Update Expense';
    },
    ...mapGetters('alert', ['errorMessages'])
  },
  methods: {
    ...mapActions('pettyCash', ['uploadImage', 'getSummaryForStore']),
    ...mapActions('cstSubmission', ['getStoreByUser']),
    uploadFile(event) {
      let allAreImages = true;

      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        if (!file.type.includes('image')) {
          allAreImages = false;
          Vue.swal({
            title: 'Wrong file Format',
            icon: 'error',
            text: 'Please upload only image files',
            showCancelButton: false,
            confirmButtonText: 'Ok',
            background: this.isDarkMode ? '#464d54' : '#ffffff',
            customClass: {
              title: this.isDarkMode ? 'text-light' : 'text-dark',
              content: this.isDarkMode ? 'text-light' : 'text-dark',
              confirmButton: 'btn btn-primary',
              popup: 'col-md-3 animate__animated animate__fadeInDown',
              input: this.isDarkMode ? 'text-light' : 'text-dark'
            }
          });
          event.target.value = '';
          break;
        }
      }

      if (allAreImages) {
        for (let i = 0; i < event.target.files.length; i++) {
          this.form.file = event.target.files[i];
          this.fileName = `${this.form.file.name}-${new Date()}`;
          this.uploadImage({ file: this.form.file });
        }
      }
    },
    onSubmit() {
      if (!this.ifAdmin) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return false;
        }
      }
      if (this.formType === 'new' && this.isSubmitDisabled) {
        return;
      }
      this.isSubmitDisabled = true;
      setTimeout(() => {
        this.isSubmitDisabled = false;
      }, 8000);

      if (this.formType === 'new') {
        if (this.budgetLeft < this.amount || this.cashInHandLeft < this.amount) {
          Vue.swal({
            title: "Expense Can't be created",
            icon: 'error',
            text: 'Your Balance is low',
            showCancelButton: false,
            confirmButtonText: 'Ok',
            background: this.isDarkMode ? '#464d54' : '#ffffff',
            customClass: {
              title: this.isDarkMode ? 'text-light' : 'text-dark',
              content: this.isDarkMode ? 'text-light' : 'text-dark',
              confirmButton: 'btn btn-primary',
              popup: 'col-md-3 animate__animated animate__fadeInDown',
              input: this.isDarkMode ? 'text-light' : 'text-dark'
            }
          });
          return null;
        }
      }

      const expenseCreated = {
        storeId: this.storeId,
        expenseGlCode: this.expenseGlCode,
        glName: this.selectedCategory,
        recipient: this.form.recipient,
        costCenter: this.form.selectedCostCenter,
        companyCode: this.form.companyCode,
        text: this.form.notes,
        status: this.form.status,
        companyName: this.form.companyName,
        billDate: this.form.startDateValue,
        amount: Number(this.amount),
        referenceNumber: this.form.refCode,
        pattyCashStoreGl: this.form.pattyCashStoreGl,
        bulkReciptImage: this.uploadedPhotos,
        processDoneBy: 'Web'
      };
      if (this.formType === 'new') {
        this.$emit('postExpense', { expense: expenseCreated });
      } else {
        if (
          this.form.notes === '' ||
          this.expenseGlCode === '' ||
          this.selectedCategory === '' ||
          this.form.selectedCostCenter === ''
        ) {
          Vue.swal({
            title: 'Please Provide all Information',
            icon: 'error',
            text: 'One filed is Empty',
            showCancelButton: false,
            confirmButtonText: 'Ok',
            background: this.isDarkMode ? '#464d54' : '#ffffff',
            customClass: {
              title: this.isDarkMode ? 'text-light' : 'text-dark',
              content: this.isDarkMode ? 'text-light' : 'text-dark',
              confirmButton: 'btn btn-primary',
              popup: 'col-md-3 animate__animated animate__fadeInDown',
              input: this.isDarkMode ? 'text-light' : 'text-dark'
            }
          });
          return null;
        }
        const oldValues = this.oldForm;
        const updatedValues = {
          id: this.oldForm._id,
          glName: this.selectedCategory,
          expenseGlCode: this.expenseGlCode,
          text: this.form.notes,
          costCenter: this.form.selectedCostCenter,
          isEdited: true
        };
        this.$emit('patchExpense', { expense: { ...oldValues, ...updatedValues } });
      }
    }
  },
  watch: {
    summary(newValue) {
      if (newValue !== undefined) {
        this.budgetLeft = newValue.totalBudgetBalance;
        this.cashInHandLeft = newValue.totalPattyCashBalance;
      }
    },
    store(newValue, oldValue) {
      if (newValue !== undefined && newValue.length > 0 && newValue[0]?.storeid !== oldValue[0]?.storeid) {
        newValue.forEach(store => {
          this.storeOptions.push({ value: store.storeid, text: store.storeid });
        });
      }
    },
    amount(newValue) {
      if (newValue !== '') {
        const convertedAmount = Number(newValue);

        if (convertedAmount > 0 && convertedAmount <= 2000) {
          this.amountApprover = 'Store Sales Manager';
        } else if (convertedAmount >= 2001 && convertedAmount <= 3000) {
          this.amountApprover = 'ASM';
        } else if (convertedAmount >= 3001 && convertedAmount <= 9990) {
          this.amountApprover = 'RSM';
        }
      }
    },
    storeId(newValue) {
      if (newValue !== null && this.formType === 'new') {
        this.form.selectedCostCenter = this.store[0]?.costCenter;
        this.form.pattyCashStoreGl = this.store[0]?.pattyCashStoreGl;
        this.form.companyCode = this.store[0]?.companyCode;
        this.form.companyName = this.store[0]?.pettyCashCompanyName;
        this.getSummaryForStore({ id: newValue });
      }
    },
    bulkImages(newValue) {
      if (newValue !== null && newValue.length > 0) {
        this.uploadedPhotos = newValue;
      }
    },
    budgetType(newValue) {
      if (newValue !== undefined) {
        newValue.forEach(budget => {
          this.categoryOptions.push(budget.glName);
          this.expenseOptions.push(budget.expenseGlCode);
        });
      }
    },
    selectedCategory(newValue) {
      if (newValue !== undefined || newValue !== '') {
        this.expenseGlCode = this.budgetType.filter(item => item.glName === newValue)[0]?.expenseGlCode;
      }
    },
    expenseGlCode(newValue) {
      if (newValue !== undefined || newValue !== '') {
        this.selectedCategory = this.budgetType.filter(item => item.expenseGlCode === newValue)[0]?.glName;
      }
    },
    expense(newValue) {
      this.formLoaded = true;
      this.oldForm = newValue;
      this.selectedCategory = newValue?.glName;
      this.storeId = newValue?.storeId;
      this.amount = newValue?.amount;
      this.form.recipient = newValue?.recipient;
      this.form.selectedCostCenter = newValue?.costCenter;
      this.form.notes = newValue?.text;
      this.form.startDateValue = newValue?.billDate;
      this.form.refCode = newValue?.referenceNumber;
      this.form.expenseGlCode = newValue?.expenseGlCode;
      this.form.status = newValue?.status;
      this.form.companyCode = newValue?.companyCode;
      this.form.pattyCashStoreGl = newValue?.pattyCashStoreGl;
      this.form.companyName = newValue?.companyName;
      this.photoArray = newValue?.bulkReciptImage;
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.imageView {
  display: inline-flex;
  margin: 2px;
}

img {
  width: 80px !important;
  height: 80px !important;
  transition: transform 0.2s;
}
</style>
