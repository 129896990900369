<template>
  <div>
    <b-form
      @submit.stop.prevent="onSubmit"
      :class="{
        'p-3 box-shadow2 rounded': !isDarkMode,
        'dark-div p-3 box-shadow2 rounded': isDarkMode
      }"
    >
      <b-row class="mt-4">
        <b-col sm="12" lg="6">
          <b-form-group id="input-companyName" label-for="input-companyName">
            <div class="mb-1">
              <template class="">
                Company Name
                <span class="text-danger">*</span>
              </template>
            </div>
            <b-form-select
              :class="{
                'box-shadow3 bg-light text-dark': !isDarkMode,
                'bg-dark text-light placeholder-light': isDarkMode
              }"
              id="input-companyName"
              v-model="form.selectedCompany"
              :options="companyType"
              :state="$v.form.selectedCompany.$dirty ? !$v.form.selectedCompany.$error : null"
            ></b-form-select>
            <b-form-invalid-feedback id="input-companyName">Please Select the Company Name</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col sm="12" lg="6">
          <b-form-group id="input-storeType" label-for="input-storeType">
            <div class="mb-1">
              <template class="">
                Store Type
                <span class="text-danger">*</span>
              </template>
            </div>
            <b-form-input
              :class="{
                'box-shadow3 bg-light text-dark': !isDarkMode,
                'bg-dark text-light placeholder-light': isDarkMode
              }"
              type="text"
              id="input-storeType"
              placeholder="Enter Store Type"
              v-model="form.selectedStoreType"
              :state="$v.form.selectedStoreType.$dirty ? !$v.form.selectedStoreType.$error : null"
            />
            <b-form-invalid-feedback id="input-storeType">Please Provide Store Type</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col sm="12" lg="6">
          <b-form-group id="input-storeId">
            <div class="mx-auto">
              <div class="mb-1">
                <template class="">
                  Store Id
                  <span class="text-danger">*</span>
                </template>
              </div>
              <multiselect
                :class="{
                  'rounded box-shadow3': !isDarkMode,
                  darkMode: isDarkMode
                }"
                id="storeID"
                :options="storeIdOptions"
                v-model="selectedStoreID"
                placeholder="Search Store Id"
              >
              </multiselect>
              <b-form-input
                class="d-none box-shadow3"
                id="input-storeId"
                :state="$v.selectedStoreID.$dirty ? !$v.selectedStoreID.$error : null"
                v-model="selectedStoreID"
              ></b-form-input>
              <b-form-invalid-feedback id="input-storeId">Please Provide Store Id</b-form-invalid-feedback>
            </div>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group id="group-pettyCashGl" label-for="input-pettyCashGl">
            <div class="mb-1">
              <template class="">
                Petty Cash Store GL
                <span class="text-danger">*</span>
              </template>
            </div>
            <div class="input-pettyCashGl">
              <b-form-input
                id="input-pettyCashGl"
                type="number"
                v-model="form.pattyCashStoreGl"
                :class="{
                  'form-control box-shadow3 bg-light text-dark': !isDarkMode,
                  'bg-dark text-light placeholder-light': isDarkMode
                }"
                placeholder="Enter store GL"
                :state="$v.form.pattyCashStoreGl.$dirty ? !$v.form.pattyCashStoreGl.$error : null"
              />
              <b-form-invalid-feedback class="ml-2" id="input-pettyCashGl-invalid"
                >Please Provide Petty Cash Store Gl</b-form-invalid-feedback
              >
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <div> </div>
      <div class="my-2">
        <b-row>
          <b-col sm="12" lg="6">
            <b-form-group class="group-validFrom" label-for="input-validFrom">
              <div class="mb-1">
                <template class="">
                  Select Month
                  <span class="text-danger">*</span>
                </template>
              </div>
              <div class="mb-2">
                <b-form-select
                  :class="{
                    'box-shadow3 bg-light text-dark': !isDarkMode,
                    'bg-dark text-light placeholder-light': isDarkMode
                  }"
                  id="input-selectedMonth"
                  :options="months"
                  v-model="form.selectedMonth"
                  @change="updateSelectedMonth"
                  :state="$v.form.selectedMonth.$dirty ? !$v.form.selectedMonth.$error : null"
                >
                </b-form-select>
                <b-form-invalid-feedback class="ml-2" id="input-selectedMonth-invalid"
                  >Please Select a Month</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
          <b-col>
            <div class="mb-1">
              <template class="">
                Select Year
                <span class="text-danger">*</span>
              </template>
            </div>
            <b-form-select
              :class="{
                'box-shadow3 bg-light text-dark': !isDarkMode,
                'bg-dark text-light placeholder-light': isDarkMode
              }"
              id="input-selectedYear"
              :state="$v.form.selectedYear.$dirty ? !$v.form.selectedYear.$error : null"
              :options="years"
              v-model="form.selectedYear"
              @change="updateSelectedMonth"
            >
            </b-form-select>
            <b-form-invalid-feedback class="ml-2" id="input-selectedYear-invalid"
              >Please Provide Year</b-form-invalid-feedback
            >
          </b-col>
        </b-row>
      </div>
      <div
        ><b-row>
          <b-col sm="12" lg="6">
            <b-form-group id="group-costCenter" label-for="input-costCenter">
              <div class="mb-1">
                <template class="">
                  Cost Center
                  <span class="text-danger">*</span>
                </template>
              </div>
              <!-- <b-form-select :options="costCenter" v-model="selectedCostCenter" class="w-35"></b-form-select> -->
              <b-form-input
                :class="{
                  'box-shadow3 bg-light text-dark': !isDarkMode,
                  'bg-dark text-light placeholder-light': isDarkMode
                }"
                type="text"
                id="input-costCenter"
                placeholder="Enter Cost Center"
                v-model="form.selectedCostCenter"
                :state="$v.form.selectedCostCenter.$dirty ? !$v.form.selectedCostCenter.$error : null"
              />
              <b-form-invalid-feedback id="input-costCenter">Please Provide Cost Center</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="group-amount" label-for="input-amount">
              <div class="mb-1">
                <template class="">
                  Amount
                  <span class="text-danger">*</span>
                </template>
              </div>
              <div class="input-group">
                <div class="input-group-prepend box-shadow3">
                  <span class="input-group-text">&#8377;</span>
                </div>
                <b-form-input
                  id="input-amount"
                  type="number"
                  min="0"
                  pattern="[0-9]"
                  onkeyup="if(!Number.isInteger(Number(this.value))) this.value = '';"
                  v-model="form.amount"
                  :class="{
                    'form-control box-shadow3 bg-light text-dark': !isDarkMode,
                    'bg-dark text-light placeholder-light': isDarkMode
                  }"
                  placeholder="Enter Amount"
                  :state="$v.form.amount.$dirty ? !$v.form.amount.$error : null"
                />
                <b-form-invalid-feedback class="ml-2" id="input-amount-invalid"
                  >Please Provide Amount</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </div>

      <b-row class="mt-5">
        <b-col class="text-right">
          <b-button
            class="box-shadow2"
            type="submit"
            size="sm"
            :variant="isDarkMode ? 'outline-success' : 'success'"
            :disabled="loading"
          >
            <span class="spinner spinner-white" v-if="loading"></span>
            <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Save
          </b-button>
          <b-button
            class="ml-2 box-shadow2"
            size="sm"
            :variant="isDarkMode ? 'outline-warning' : 'warning'"
            :to="{ path: `/petty-cash/budget` }"
          >
            <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" class="mr-1" />Back to list
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import { required } from 'vuelidate/lib/validators';
import Multiselect from 'vue-multiselect';
import moment from 'moment';
import { Months } from '../../helper/pettyCashHelper';

const init = {
  mounted: el => el.init()
};

export default {
  name: 'PettyCashBudgetFormBox',
  components: {
    Multiselect
  },
  directives: {
    init
  },
  props: {
    formType: String
  },
  validations() {
    const formValidation = {
      selectedCostCenter: {
        required
      },
      selectedStoreType: {
        required
      },
      selectedMonth: {
        required
      },
      pattyCashStoreGl: {
        required
      },
      selectedYear: {
        required
      },
      amount: {
        required
      },
      selectedCompany: {
        required
      }
    };
    const selectedStoreID = { required };
    if (this.formType === 'new') {
      formValidation.amount.required = required;
      formValidation.selectedMonth.required = required;
      formValidation.pattyCashStoreGl.required = required;
      formValidation.selectedYear.required = required;
      formValidation.selectedCostCenter.required = required;
      formValidation.selectedCompany.required = required;
      selectedStoreID.required = required;
    }
    return { form: formValidation, selectedStoreID };
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: this.metaDescription
        }
      ]
    };
  },
  data() {
    return {
      formLoaded: true,
      selectedStoreID: null,
      form: {
        selectedCompany: null,
        selectedStoreType: '',
        selectedCostCenter: '',
        amount: '',
        pattyCashStoreGl: '',
        validTo: '',
        validFrom: '',
        selectedMonth: moment().format('MMMM').toUpperCase(),
        selectedYear: new Date().getFullYear() // Initial selection: current year
      },
      storeIdOptions: [],
      companyType: [
        { value: null, text: 'Select Name' },
        { value: 'SRL', text: 'SRL' },
        { value: 'NBL', text: 'NBL' },
        { value: 'CESC', text: 'CESC' }
      ],
      years: [],
      months: Months
    };
  },

  mounted() {
    const currentYear = new Date().getFullYear();
    this.getStoreByUser();
    for (let year = currentYear; year <= currentYear + 1; year++) {
      this.years.push(year);
    }
    this.$nextTick(async () => {
      if (this.formType === 'new') {
        // this.form.status = Checklist.checklistEnabled.active;
        this.formLoaded = false;
        this.$v.$touch(); // Set initial validation
        this.$v.$reset(); // Reset $dirty
      }
    });
  },
  computed: {
    ...mapState('pettyCash', ['loading', 'baseUrl', 'budget', 'budgetType']),
    ...mapState('cstSubmission', ['store']),
    ...mapState('darkMode', ['isDarkMode']),
    metaDescription() {
      return this.formType === 'new' ? 'Add New Budget' : 'Update Budget';
    }
  },
  methods: {
    ...mapActions('cstSubmission', ['getStoreByUser']),
    updateSelectedMonth() {
      const inputDate = moment(
        `${this.form.selectedMonth} ${this.form.selectedYear} ${moment().format('YYYY')}`,
        'MMMM YYYY'
      );
      this.form.validFrom = inputDate.startOf('month').format('YYYY-MM-DD');
      this.form.validTo = inputDate.endOf('month').format('YYYY-MM-DD');
    },
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      if (this.form.validFrom === '' || this.form.validTo === '') {
        const inputDate = moment(
          `${this.form.selectedMonth} ${this.form.selectedYear} ${moment().format('YYYY')}`,
          'MMMM YYYY'
        );
        this.form.validFrom = inputDate.startOf('month').format('YYYY-MM-DD');
        this.form.validTo = inputDate.endOf('month').format('YYYY-MM-DD');
      }

      const budget = {
        storeId: this.selectedStoreID,
        budgetAmount: Number(this.form.amount),
        companyName: this.form.selectedCompany,
        pattyCashStoreGl: this.form.pattyCashStoreGl,
        storeType: this.form.selectedStoreType.toUpperCase(),
        validTo: this.form.validTo,
        validFrom: this.form.validFrom,
        costCenter: this.form.selectedCostCenter.toUpperCase()
      };
      if (this.formType === 'new') {
        this.$emit('postBudget', { budget });
      } else {
        this.$emit('patchBudget', { budget });
      }
    }
  },
  watch: {
    store(newValue) {
      if (newValue !== undefined) {
        newValue.forEach(store => {
          this.storeIdOptions.push(store.storeid);
        });
      }
    },
    selectedStoreID(newValue) {
      if (newValue !== '') {
        const selectedStore = this.store.filter(item => item.storeid === newValue);
        this.form.pattyCashStoreGl = selectedStore[0].pattyCashStoreGl;
        this.form.selectedCostCenter = selectedStore[0].costCenter;
      }
    }
  }
};
</script>

<style scoped></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
